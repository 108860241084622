import React, { useEffect, useState } from "react";
import "../Style/FalconsList.css";
import pin from "/webapps/tippytap/src/Assets/pencil (1) 1.png";
import axios from "axios";
import defaultFalconImage from '/webapps/tippytap/src/Assets/Group 2101.png';
import { Link } from 'react-router-dom';
import deleteicon from '/webapps/tippytap/src/Assets/4812459.png';
import Modal from "react-modal";
import AddFalconModal from "./AddFalcon";
import viewicon from '/webapps/tippytap/src/Assets/view-icon.png';
import FalconImage from "./UploadFalconImage";
import { useToken } from './TokenContext';

Modal.setAppElement('#root'); // Ensure the app element is set for accessibility

export default function SingleFalcon() {
  const [data, setData] = useState([]);
  const [isEditingIndex, setIsEditingIndex] = useState(null);
  const [editedData, setEditedData] = useState([]);
  const [file, setFile] = useState(null);
  // const { token } = useToken();
  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [falconIdError, setFalconIdError] = useState("");
  const fieldTranslations = {
    specie: 'الفصيلة',
    production: 'الإنتاج',
    weight: 'الوزن',
    height: 'الارتفاع',
    width: 'العرض',
    falcon_id: 'الرقم التعريفي',
  };

  // const contactID = token ? atob(token) : null; // Decode the token to get contactID


  useEffect(() => {
    fetchFalcons();
  }, []);

  const fetchFalcons = () => {
    axios
      .get(`https://apis.tippytap.ae/get_by_contact_id/adihex_contacts_falcons/1`)
      .then((res) => {
        const fetchedData = res.data.data;
        console.log(fetchedData)
        setData(fetchedData);
        setEditedData(fetchedData.map(falcon => ({
          id: falcon.id,
          encrypted: falcon.encrypted,
          specie: falcon.specie,
          production: falcon.production,
          weight: falcon.weight,
          height: falcon.height,
          width: falcon.width,
          falcon_id: falcon.falcon_id || "", // Ensure falcon_id is mapped correctly
          image: falcon.image || defaultFalconImage,
        })));
      })
      .catch((err) => console.error('Error fetching falcon data:', err));
  };

  const handleAddFalconSuccess = () => {
    fetchFalcons(); // Reload the falcon data
    setIsAddModalOpen(false); // Close the modal
  };

  const handleEditClick = (index) => {

    setIsEditingIndex(prevIndex => prevIndex === index ? null : index);
    if (isEditingIndex === index) {
      if (!validateFalconId(editedData[index].falcon_id)) {
        setFalconIdError("Falcon ID must be a 16-character ");
        return;
      }
      const objectId = data[index].id;

      const payload = {
        object_id: objectId,
        table_name: "adihex.contacts.falcons",
        data: {
          falcon_id: editedData[index].falcon_id,
          specie: editedData[index].specie,
          production: editedData[index].production,
          weight: editedData[index].weight,
          height: editedData[index].height,
          width: editedData[index].width,
        }
      };

      axios
        .post("https://apis.tippytap.ae/Update_record", payload)
        .then((response) => {
          const updatedData = [...data];
          updatedData[index] = { ...updatedData[index], ...editedData[index] };
          setData(updatedData);
          setIsEditingIndex(null);
          setFalconIdError(""); // Clear the error message

          if (file) {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("object_id", editedData[index].id);

            axios
              .post("https://apis.tippytap.ae/upload_image_flacons", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((response) => {
                const imageUrl = response.data.url;
                const updatedDataWithImage = [...data];
                updatedDataWithImage[index].image = imageUrl;
                setData(updatedDataWithImage);
                console.log(response.data)

                const updatedEditedData = [...editedData];
                updatedEditedData[index].image = imageUrl;
                setEditedData(updatedEditedData);
                setFile(null);
                // fetchFalcons();

              })
              .catch((error) => console.error('Error uploading image:', error));
          }
        })
        .catch((error) => console.error('Error updating record:', error));
    } else {
      setIsEditingIndex(index);
    }
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    if (name === 'falcon_id') {
      const cleanedValue = value.replace(/-/g, ''); // Remove existing dashes
      if (cleanedValue.length > 16) {
        return; // Do nothing if the length exceeds 16
      }
      const formattedValue = formatFalconId(cleanedValue);
      setEditedData(prevEditedData => {
        const updatedData = [...prevEditedData];
        updatedData[index][name] = formattedValue;
        return updatedData;
      });

      if (!validateFalconId(formattedValue)) {
        setFalconIdError("Falcon ID must be a 16-character ");
      } else {
        setFalconIdError("");
      }
    } else {
      setEditedData(prevEditedData => {
        const updatedData = [...prevEditedData];
        updatedData[index][name] = value;
        return updatedData;
      });
    }
  };

  const validateFalconId = (falconId) => {
    const cleanId = falconId.replace(/-/g, ''); // Remove dashes for validation
    return cleanId.length <= 16 && /^[0-9a-zA-Z]*$/.test(cleanId);
  };

  const formatFalconId = (value) => {
    const parts = [];
    for (let i = 0; i < value.length; i += 4) {
      parts.push(value.slice(i, i + 4));
    }
    return parts.join('-');
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleDeleteClick = (index) => {
    setDeleteId(data[index].id);
    setIsOpen(true);
  };

  const handleDelete = (id) => {
    axios
      .get(`https://apis.tippytap.ae/delete_record/adihex.contacts.falcons/${id}`)
      .then(() => {
        const updatedData = data.filter(falcon => falcon.id !== id);
        // setData(updatedData);
        fetchFalcons(); // Reload the falcon data
        setIsOpen(false);
      })
      .catch((error) => console.error('Error deleting record:', error));
  };

  const closeModal = () => {
    setIsOpen(false);
    setDeleteId(null);
  };

  const handleAddFalconClick = () => {
    setIsAddModalOpen(true);
  };

  const handleAddFalconModalClose = () => {
    setIsAddModalOpen(false);
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };


  return (
    <div>
      <div className="falcon-header-container">
        <h6>My Falcons</h6>
        <div className="floating-btn">
          <button onClick={handleAddFalconClick}>Add Falcon</button>
        </div>
      </div>
      {data.map((falcon, index) => (
        <div key={falcon.id} className="single-falcon-detail">
          <div className="single-falcon-detail-titles">
            <Link style={{ textDecoration: "none" }} to={`/adihex/falcon_profile/${falcon.encrypted}`}>
              <h6>{falcon.name}</h6>
            </Link>
            <div className="edit-btn">
              {/* View Button */}
              {isEditingIndex !== index && (
                <Link style={{ textDecoration: "none" }} to={`/adihex/falcon_profile/${falcon.encrypted}`}>
                  <button className="view-btn">
                    <span>View</span>
                  </button>
                </Link>
              )}
              {/* Edit Button */}
              <button
                className={`edit-btn-001 ${isEditingIndex === index ? 'save-btn' : ''}`}
                onClick={() => handleEditClick(index)}
              >                {isEditingIndex === index ? (
                <span>Save</span>
              ) : (
                <>
                  <img src={pin} className="pin-img" alt="Edit" />
                  <span className={`edit-text `}>
                    Edit
                  </span>
                </>
              )}
              </button>
              {/* Delete Button */}
              {isEditingIndex !== index && (
                <button className="delete-btn-001" onClick={() => handleDeleteClick(index)}>
                  Delete
                </button>
              )}
            </div>
          </div>

          <div className="single-falcon-data-layout">
            <FalconImage
              isEditing={isEditingIndex === index}
              image={editedData[index]?.image}
              onFileChange={handleFileChange}
            />

            <div className="single-falcon-column-data-container">
              {['specie', 'production', 'weight', 'height', 'width', 'falcon_id'].map(field => (
                <div key={field} className={`falcon-data-1 ${isEditingIndex === index ? 'edit-mode' : ''}`}>
                  <span>
                    {capitalizeFirstLetter(field)} / {fieldTranslations[field]}{field === 'weight' ? ' (g)' :
                      field === 'height' || field === 'width' ? ' (cm)' :
                        ''}
                    :
                  </span>
                  {isEditingIndex === index ? (
                    <>
                      <input
                        type="text"
                        name={field}
                        value={editedData[index][field] || ""}
                        onChange={(e) => handleInputChange(e, index)}
                        className="form-control"
                      />
                      {field === 'falcon_id' && falconIdError && (
                        <p className="error-message-falcon">{falconIdError}</p>
                      )}
                    </>
                  ) : (
                    <p>{falcon[field]}</p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
      {/* Confirmation Modal */}
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Confirm Deletion"
        className="Modal"
        overlayClassName="Overlay"
      >
        <h2>Are you sure you want to delete this falcon?</h2>
        <div className="model-btns-sec">
          <button className="delete-modal-btn" onClick={() => handleDelete(deleteId)}>Confirm</button>
          <button className="cancel-modal-btn" onClick={closeModal}>Cancel</button>
        </div>
      </Modal>
      {/* Add Falcon Modal */}
      <AddFalconModal
        isOpen={isAddModalOpen}
        onClose={handleAddFalconModalClose}
        contactId='1'
        onSuccess={handleAddFalconSuccess} // Pass the success callback
      />
    </div>
  );
}
