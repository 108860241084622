import React from "react";
import img01 from "/webapps/tippytap/src/Assets/Group 89@2x 1.png";
import bluetrue from "/webapps/tippytap/src/Assets/Group 1840 (2).png";
import "/webapps/tippytap/src/Style/About.css";
import img001 from "/webapps/tippytap/src/Assets/35@2x.png";
import img002 from "/webapps/tippytap/src/Assets/Group 282@2x.png";
import img003 from "/webapps/tippytap/src/Assets/task time@2x.png";
export default function About() {
  return (
    <div className="about-wrap">
    <div className="flex-about container">
      <div className="title-about">
        <h6>We Care About Your</h6>
        <h4>Account Security</h4>
      </div>

      <div className="care-about-cards-grid">
        <div className="care-about-single-card">
          <div className="care-about-single-card-img-container">
          <img src={img001} />
          </div>

          <div className="care-about-single-card-header">
            <h4>A definite and practical biometric <br /> login.</h4>
          </div>
        </div>
        <div className="care-about-single-card">
          <div className="care-about-single-card-img-container">
          <img src={img002} />
          </div>

          <div className="care-about-single-card-header">
            <h4>
              Two-factor authentication for <br /> access and transaction security.
            </h4>
          </div>
        </div>

        <div className="care-about-single-card">
          <div className="care-about-single-card-img-container">
            <img src={img003} />
          </div>
          <div className="care-about-single-card-header">
            <h4>
              Manage, update and schedule <br /> content with our quick, easy editor.
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}
