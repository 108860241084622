// src/components/LoginPrompt.js
import React, { useEffect, useState } from 'react';
import "../Style/Brown_profile_falcon.css";
import jackfalcon from '/webapps/tippytap/src/Assets/jack-seeds-lV5Y2116NZg-unsplash.jpg'
import { Navigate, useNavigate, useParams } from 'react-router';
import axios from 'axios';
import adihex from '/webapps/tippytap/src/Assets/e43bc4819fa138de4c2578a7c554e497.png'
import adihex2 from '/webapps/tippytap/src/Assets/Group 2357.png'
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';



export default function NewBrownProfile() {

    const { id } = useParams();
    const [data, setData] = useState({});
    const [imageBase64, setImageBase64] = useState(null);
    const navigate = useNavigate();

    const captureScreenshot = () => {
        const element = document.getElementById('capture');
        html2canvas(element, { allowTaint: true, useCORS: true }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = imgData;
            link.download = 'Falcon_Auction_Card.png';
            link.click();
        });
    };
    // const CapturePdf = () => {
    //     const element = document.getElementById('capture');
    //     html2canvas(element, { allowTaint: true, useCORS: true }).then((canvas) => {
    //         const imgData = canvas.toDataURL('image/png');
    //         const pdf = new jsPDF({
    //             orientation: 'p',
    //             unit: 'mm',
    //             format: 'a4'
    //         });

    //         const pdfWidth = pdf.internal.pageSize.getWidth();
    //         const pdfHeight = pdf.internal.pageSize.getHeight();
    //         const imgWidth = canvas.width * 0.75; // Adjust scale to fit content
    //         const imgHeight = canvas.height * 0.75; // Adjust scale to fit content

    //         // Add the image to the PDF
    //         pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

    //         // Save the PDF
    //         pdf.save('Falcon_Auction_Card.pdf');
    //     });
    // };

    useEffect(() => {
        axios
            .get(`https://apis.tippytap.ae/get_url_view?value=${id}`)
            .then((res) => {
                const fetchedData = res.data.data[0];
                // console.log(res.data)
                setData(fetchedData)
                console.log(fetchedData.image)
                console.log(res.data.data[0]['falcon_id'])
                const Fal = res.data.data[0]['falcon_id']


                if (Fal === null) {
                    navigate(`/adihex/updatefalcondata/${id}`);
                    console.log('Redirecting to update form...');
                }

                else{
                    return
                }

            })


            .catch((err) => console.log(err));
    }, []);


    // useEffect(() => {
    //     console.log('ID:', id); // Check if ID is properly being passed
    //     axios
    //         .get(`https://apis.tippytap.ae/check_data_url/${id}`)
    //         .then((res) => {
    //             console.log(res); // Log full response
    //             if (res.data.status === 0) {
    //                 navigate(`/adihex/updatefalcondata/${id}`);
    //                 console.log('Redirecting to update form...');
    //             }

    //             else{
    //                 return
    //             }
    //         })
    //         .catch((err) => console.log(err));
    // }, [id]);
    



    return (
        // <div className='capture-whole'>
        //     <div className="New-Brown-Profile-container" id="capture">
        //         <div className='brown-titles'>
        //             <h4>بطاقة الصقر</h4>
        //             <hr className='hr-border' />
        //             <img src={adihex} />


        //         </div>
        //         <div className="img-falcon-brown-container">

        //             <img src={data.image} alt="falcon" crossOrigin="anonymous" />
        //         </div>

        //         <div className='flex-brown-data container'>
        //             <div className='signle-brown-data'>
        //                 <p>{data.specie}</p>
        //                 <h4>نوع الصقر :</h4>
        //             </div>
        //             <div className='signle-brown-data'>
        //                 <p>{data.falcon_age}</p>
        //                 <h4> عمر الصقر :</h4>
        //             </div>
        //             <div className='signle-brown-data'>
        //                 <p>{data.height}</p>
        //                 <h4> طول الصقر (سم):</h4>
        //             </div>
        //             <div className='signle-brown-data'>
        //                 <p>{data.width}</p>
        //                 <h4>عرض الصقر (سم):</h4>
        //             </div>
        //             <div className='signle-brown-data'>
        //                 <p>{data.weight}</p>
        //                 <h4>وزن الصقر (جم):</h4>
        //             </div>
        //             <div className='signle-brown-data'>
        //                 <p>{data.falcon_id}</p>
        //                 <h4> رقم الحجل : </h4>
        //             </div>



        //             <div className='signle-brown-data'>
        //                 <p>{data.production}</p>
        //                 <h4>المزرعة :</h4>
        //             </div>
        //             <div className='signle-brown-data'>
        //                 <p>{data.country_of_origin}</p>
        //                 <h4> بلد المنشأ:</h4>

        //             </div>
        //         </div>
        //     </div>

        //     <div className='Download-card-container container'>
        // <button className="Download-Card-btn " onClick={captureScreenshot} >
        //     Download Falcon Bio-Card
        // </button>

        //     </div>

        // </div>



        <div className='black-wrap'  id="capture">
            <div className="Logos-grid">
                <div className='logo-adihex-1'><img src={adihex} /></div>
                <div className='logo-adihex-2'><img src={adihex2} /></div>

            </div>

            <div className='flex-center'>
                <div className='aution-card-container-2'>

                    <div className='img-and-title-auction-card'>
                        <div className='images-auction-card2'>
                            <img src={data.image} />

                        </div>
                        <div className='titles-auction-card2'>

                            <h6>بطاقة الصقر</h6>
                            <h5>Falcon Card</h5>
                        </div>

                    </div>

                    <div className='flex-brown-data container'>
                    <div className='signle-brown-data2'>
                            <p>{data.falcon_id}</p>
                            <h4>رقم الحجل / Ring : </h4>
                        </div>
                        <div className='signle-brown-data2'>
                            <p>{data.specie}</p>
                            <h4>نوع الصقر / Type : </h4>
                        </div>
                       
                        <div className='signle-brown-data2'>
                            <p>{data.falcon_age }</p>
                            <h4> عمر الصقر / Age : </h4>
                        </div>
                        <div className='signle-brown-data2'>
                            <p>{'"'+data.height }</p>
                            <h4> طول الصقر / Length : </h4>
                        </div>
                        <div className='signle-brown-data2'>
                            <p>{'"'+data.width}</p>
                            <h4>عرض الصقر / Width : </h4>
                        </div>
                        <div className='signle-brown-data2'>
                            <p>{data.weight +' غرام'}</p>
                            <h4>وزن الصقر / Weight : </h4>
                        </div>
                      
                        <div className='signle-brown-data2'>
                            <p>{data.production}</p>
                            <h4>المزرعة / Farm : </h4>
                        </div>
                        <div className='signle-brown-data2'>
                            <p>{data.country_of_origin}</p>
                            <h4> بلد المنشأ / Country of Origin : </h4>

                        </div>
                    </div>

                    <div className='Download-card-container container'>
         <button className="Download-Card-btn " onClick={captureScreenshot} >
             Download Falcon Bio-Card
         </button>

             </div>

                </div>
            </div>
        </div>
    );
}
