import React, { useEffect, useState ,useRef  } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { CiCirclePlus, CiCircleRemove } from 'react-icons/ci';
import "/webapps/tippytap/src/Style/FillBusinessCardData.css";
import axios from 'axios';
import { CiCircleMinus } from "react-icons/ci";
import UploadProfileImage from './UploadProfileImage';
import Attachment from './Attachment';
import pin from "/webapps/tippytap/src/Assets/pencil (1) 1.png";
import Modal from "react-modal";
import { useLocation, useNavigate, useParams } from 'react-router';

export default function PersonalDataSection() {
    const [isOpen, setIsOpen] = useState({ Personal: true });
    const [isAdding, setIsAdding] = useState({ Personal: false });
    const [isEditing, setIsEditing] = useState(false);
    const [newRow, setNewRow] = useState({ label: '', category: '', value: '', id: '' });
    const [rows, setRows] = useState([]);
    const [staticFields, setStaticFields] = useState({
        org_name: '',
        title: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        bio: ''
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null); // Initialize deleteId
    const navigate = useNavigate(); // Replace useHistory with useNavigate
    const [EncryptedID, setEncryptedID] = useState(null); // Initialize deleteId
    const textareaRef = useRef(null);
    const { order_id } = useParams();
    const location = useLocation();
    const { state } = location;
    const [CARDID, setCARDID] = useState('');
    const [Quantity, setQuantity] = useState('');

    useEffect(() => {
        // Fetch the main card ID and quantity using the order_id
        axios.get(`https://apis.tippytap.ae/get_main_cards_ids/${order_id}/${1}`)
            .then(response => {
                const data = response.data.result[0];
                const quantity = data.quantity;
                const Maincard_id = data.id;
    
                // Update the state with the fetched values
                setQuantity(quantity);
                setCARDID(Maincard_id);
    
                // Log the values after setting the state
                console.log('Product Quantity:', quantity);
                console.log('Main card ID:', Maincard_id);
                console.log('order ID:', order_id);
            })
            .catch(error => {
                console.error('Error fetching card data:', error);
            });
    }, [order_id]);


    

    const fetchData = async () => {

        try {
            const response = await axios.get(`https://apis.tippytap.ae/view_single_card/${CARDID}`);
            const data = response.data.result;
            console.log('data',data)
            const encryptedID = response.data.result['encrypted'];
            console.log('encryptediddddd',encryptedID)
            setEncryptedID(encryptedID)
            // Static fields
            setStaticFields({
                org_name: data.org_name,
                title: data.title,
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
                phone: data.phone,
                bio: data.bio
            });

            // Dynamic items
            const dynamicRows = data.items.map(item => ({
                label: item.label,
                category: item.category, // Assuming _type corresponds to the type
                value: item.value,
                id: item.id
            }));

            // Combine static and dynamic rows
            setRows(dynamicRows);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (CARDID) {
            fetchData();  // Only call fetchData if CARDID has been set
        }
    }, [CARDID]);

    // console.log(MaincardID)
    // console.log(parentId)
    // console.log(order_id)
    // console.log(productUomQty)

    const toggleCollapse = (section) => {
        setIsOpen(prevState => ({ ...prevState, [section]: !prevState[section] }));
    };

    const handleAddRow = () => {
        if (newRow.label && newRow.category && newRow.value) {
            // Add new row to the existing rows
            setRows(prevRows => [...prevRows, newRow]);
            handleSaveNew();
        }
    };

    const handleNewRowChange = (e) => {
        const { name, value } = e.target;
        setNewRow(prevRow => ({ ...prevRow, [name]: value }));
    };

    const handleRowChange = (index, e) => {
        const { name, value } = e.target;
        const updatedRows = [...rows];
        updatedRows[index] = { ...updatedRows[index], [name]: value };
        setRows(updatedRows);
    };

    const hadnletextareachange = (event) => {
        const textarea = event.target;
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
        handleStaticFieldChange(event); // Call your existing change handler
    };

    // const handleRemoveRow = (index) => {
    //     const rowToRemove = rows[index];
    //     setDeleteId(rowToRemove.id); // Set deleteId before opening modal
    //     setIsModalOpen(true);
    // };

    const handleSaveNew = async () => {
        try {
            const newCardInfo = {
                items: [
                    {
                        label: newRow.label,
                        category: newRow.category,
                        value: newRow.value
                    }
                ],
                org_name: '',
                title: '',
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                bio: '',
            };

            const data = {
                order_id: order_id,
                product_id: 1,
                main_card_id:CARDID,
                cards_info: [newCardInfo]
            };

            const response = await axios.post('https://apis.tippytap.ae/set_new_card_item/', data);
            console.log('Response:', response.data);
            setIsAdding({ ...isAdding, Personal: false });
            setNewRow({ label: '', category: '', value: '' });
            fetchData();
            // Optionally refetch data or update rows here
        } catch (error) {
            console.error('Error saving new data:', error);
        }
    };


    const handleSave = async () => {
        try {
            const cardInfo = {
                items: [...rows],
                org_name: staticFields.org_name,
                title: staticFields.title,
                first_name: staticFields.first_name,
                last_name: staticFields.last_name,
                email: staticFields.email,
                phone: staticFields.phone,
                bio: staticFields.bio,
            };

            const data = {
                order_id: order_id,
                product_id: 1,
                main_card_id:CARDID,
                cards_info: [cardInfo]
            };

            const response = await axios.post('https://apis.tippytap.ae/set_card_data/', data);
            console.log('Response:', response.data);
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    const handleStaticFieldChange = (e) => {
        const { name, value } = e.target;
        setStaticFields(prevFields => ({ ...prevFields, [name]: value }));
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleDeleteRow = async () => {
        try {
            const res = await axios.get(`https://apis.tippytap.ae/inactive_item/${deleteId}`); // Use deleteId for the API call
            console.log(res)
            setRows(prevRows => prevRows.filter(row => row.id !== deleteId)); // Filter out the deleted row
            closeModal(); // Close the modal after deletion
        } catch (error) {
            console.error('Error deleting row:', error);
        }
    };

    const handleNavigate = (index, e) => {
        console.log("id encrypted",EncryptedID)
        navigate(`/cardprofile/${EncryptedID}`); // Navigate to /signin when the modal closes


    };

    const handleCancelAdd = () => {
        setIsAdding(false);
        setNewRow({ label: '', value: null });
    };


    const adjustTextareaHeight = () => {
        const textarea = textareaRef.current;
        if (textarea) {
          textarea.style.height = 'auto'; // Reset the height
          textarea.style.height = `${textarea.scrollHeight}px`; // Set height based on content
        }
      };
    
      // Run the function when the textarea content changes
      useEffect(() => {
        adjustTextareaHeight();
      }, [staticFields.bio]);

    return (
        <>
            <div className="Business-content">
                <div className="BussinessCard-container-float">
                    <h6>Card Data</h6>
                </div>

                <div className="BussinessCard-container">
                    <div id="accordion">
                        {/* Personal Section */}
                        <div className="card">
                            <div
                                className="card-header"
                                id="headingPersonal"
                                onClick={() => toggleCollapse('Personal')}
                                style={{ backgroundColor: '#F0F0F0', cursor: 'pointer' }}
                            >
                                <h5 className="mb-0 d-flex justify-content-between align-items-center">
                                    <button
                                        className="title-btn-collapse"
                                        type="button"
                                        aria-expanded={isOpen.Personal}
                                        aria-controls="collapsePersonal"
                                        style={{ background: 'none', border: 'none', color: 'inherit' }}
                                    >
                                        Personal Data
                                    </button>
                                    <span>
                                        {isOpen.Personal ? <FaChevronUp size={15} /> : <FaChevronDown size={15} />}
                                    </span>
                                </h5>
                            </div>
                            <div
                                id="collapsePersonal"
                                className={`collapse ${isOpen.Personal ? 'show' : ''}`}
                                aria-labelledby="headingPersonal"
                                data-bs-parent="#accordion"
                            >
                                <div className="card-body">

                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <h4 className="Card-header-img mb-0">Personal Data</h4>
                                        <div className="ms-auto btns-view-save">
                                            {!isEditing && (
                                                <button className="view-btn-card" onClick={handleNavigate}>
                                                    View
                                                </button>
                                            )}
                                            <button
                                                className={`edit-btn-2 ${isEditing ? 'save-btn' : ''}`}
                                                onClick={() => {
                                                    if (isEditing) {
                                                        handleSave();
                                                    }
                                                    setIsEditing(!isEditing);
                                                }}
                                                style={{ marginTop: '10px' }}
                                            >
                                                {isEditing ? 'Save' : 'Edit'}
                                            </button>

                                        </div>
                                    </div>


                                    <UploadProfileImage
                                        productUomQty={Quantity}
                                        order_id={order_id}
                                        parentId={1}
                                        MaincardID={CARDID}
                                    />
                                    <div className="form-group mt-4">
                                        <label className='label-data-1'>Organization Name :</label>
                                        <input
                                            type="text"
                                            className={`form-control ${isEditing ? 'editable-input' : ''}`}
                                            name="org_name"
                                            value={staticFields.org_name}
                                            onChange={handleStaticFieldChange}
                                            readOnly={!isEditing}
                                            placeholder='Organization Name'
                                            required

                                        />
                                    </div>
                                    <div className="form-group mt-2">
                                        <label className='label-data-1'>Title :</label>
                                        <input
                                            type="text"
                                            className={`form-control ${isEditing ? 'editable-input' : ''}`}
                                            name="title"
                                            value={staticFields.title}
                                            onChange={handleStaticFieldChange}
                                            readOnly={!isEditing}
                                            placeholder='Title'
                                            required

                                        />
                                    </div>
                                    <div className="form-group mt-2">
                                        <label className='label-data-1'>First Name :</label>
                                        <input
                                            type="text"
                                            className={`form-control ${isEditing ? 'editable-input' : ''}`}
                                            name="first_name"
                                            value={staticFields.first_name}
                                            onChange={handleStaticFieldChange}
                                            readOnly={!isEditing}
                                            placeholder='First Name'
                                            required

                                        />
                                    </div>
                                    <div className="form-group mt-2">
                                        <label className='label-data-1'>Last Name :</label>
                                        <input
                                            type="text"
                                            className={`form-control ${isEditing ? 'editable-input' : ''}`}
                                            name="last_name"
                                            value={staticFields.last_name}
                                            onChange={handleStaticFieldChange}
                                            readOnly={!isEditing}
                                            placeholder='Last Name'
                                            required

                                        />
                                    </div>
                                    <div className="form-group mt-2">
                                        <label className='label-data-1'>Email :</label>
                                        <input
                                            type="email"
                                            className={`form-control ${isEditing ? 'editable-input' : ''}`}
                                            name="email"
                                            value={staticFields.email}
                                            onChange={handleStaticFieldChange}
                                            readOnly={!isEditing}
                                            placeholder='Email'
                                            required

                                        />
                                    </div>
                                    <div className="form-group mt-2">
                                        <label className='label-data-1'>Phone :</label>
                                        <input
                                            type="tel"
                                            className={`form-control ${isEditing ? 'editable-input' : ''}`}
                                            name="phone"
                                            value={staticFields.phone}
                                            onChange={handleStaticFieldChange}
                                            readOnly={!isEditing}
                                            placeholder='Phone'
                                            required

                                        />
                                    </div>
                                    <div className="form-group-text mb-2">
                                        <label className='label-data-1'>Bio :</label>
                                        <textarea
                                            ref={textareaRef}
                                            className={`form-control ${isEditing ? 'editable-input' : ''}`}
                                            name="bio"
                                            value={staticFields.bio}
                                            onChange={(e) => {hadnletextareachange(e); adjustTextareaHeight()}}
                                            readOnly={!isEditing}
                                            style={{ overflow: "hidden", height: "auto" , whiteSpace: "pre-wrap" }}
                                            placeholder='Bio'
                                            required

                                        />
                                    </div>
                                    {/* <table className="table Personal-table">
                                    <tbody>
                                        {rows.map((row, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <label className='label-style'>{row.label}</label>
                                                </td>
                                                <td>
                                                    <select
                                                        className="custom-dropdown1"
                                                        name="dropdown"
                                                        value={row.category}
                                                        onChange={(e) => handleRowChange(index, e)}
                                                        disabled
                                                    >
                                                        <option value="">Select Type</option>
                                                        <option value="Text">Text</option>
                                                        <option value="Email">Email</option>
                                                        <option value="Phone">Phone</option>
                                                        <option value="URL">URL</option>
                                                        <option value="Note">Note</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="value"
                                                        value={row.value}
                                                        onChange={(e) => handleRowChange(index, e)}
                                                        readOnly={!isEditing}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                        
                                        {isAdding.Personal && (
                                            <tr>

                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="label"
                                                        value={newRow.label}
                                                        onChange={handleNewRowChange}
                                                        placeholder="Label"
                                                    />
                                                </td>
                                                <td>
                                                    <select
                                                        className="custom-dropdown1"
                                                        name="category"
                                                        value={newRow.category}
                                                        onChange={handleNewRowChange}
                                                    >
                                                        <option value="">Select Type</option>
                                                        <option value="Email">Email</option>
                                                        <option value="Phone">Phone</option>
                                                        <option value="URL">URL</option>
                                                        <option value="Note">Note</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="value"
                                                        value={newRow.value}
                                                        onChange={handleNewRowChange}
                                                        placeholder="Description"
                                                    />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table> */}

                                    <table className="table Personal-table mt-4">
                                        {/* <h4 className='Card-header-img'>Card Data :</h4> */}
                                        <thead>
                                            <tr>
                                                <th>Label</th>
                                                <th>Category</th>
                                                <th>Value</th> {/* Added Action column */}

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rows.map((row, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <div className='input-and-remove-flex'>
                                                            <button
                                                                type="button"
                                                                // className="btn btn-danger btn-circle"
                                                                onClick={() => {
                                                                    setDeleteId(row.id);
                                                                    console.log('data', row)
                                                                    setIsModalOpen(true);
                                                                }}
                                                                style={{ background: 'none', border: 'none', color: '#FF0000', fontWeight: "700" }}

                                                            >
                                                                <CiCircleRemove size={25} style={{ fontWeight: 'bold' }} />
                                                            </button>
                                                            <input
                                                                type="text"
                                                                className={`form-control ${isEditing ? 'editable-input' : ''}`}
                                                                name="label"
                                                                value={row.label}
                                                                onChange={(e) => handleRowChange(index, e)}
                                                                readOnly={!isEditing}
                                                                placeholder='Label'

                                                            />


                                                        </div>

                                                    </td>
                                                    <td>
                                                        <select
                                                            className="form-control"
                                                            name="category"
                                                            value={row.category}
                                                            onChange={(e) => handleRowChange(index, e)}
                                                            disabled={!isEditing}
                                                        >
                                                            <option value="">Select Type</option>
                                                            <option value="Email">Email</option>
                                                            <option value="Phone">Phone</option>
                                                            <option value="URL">URL</option>
                                                            <option value="Note">Note</option>
                                                        </select>

                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className={`form-control ${isEditing ? 'editable-input' : ''}`}
                                                            name="value"
                                                            value={row.value}
                                                            onChange={(e) => handleRowChange(index, e)}
                                                            readOnly={!isEditing}
                                                            placeholder='Value'

                                                        />
                                                    </td>
                                                    {/* <td>
                                                        <button
                                                            type="button"
                                                            // className="btn btn-danger btn-circle"
                                                            onClick={() => handleRemoveRow(index)}
                                                            style={{ background: 'none', border: 'none', color: '#FF0000', fontWeight: "700" }}

                                                        >
                                                        <CiCircleRemove size={25} style={{ fontWeight: 'bold' }} />
                                                        </button>
                                                    </td> */}
                                                </tr>
                                            ))}

                                            {isAdding.Personal && (
                                                <tr>

                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="label"
                                                            value={newRow.label}
                                                            onChange={handleNewRowChange}
                                                            placeholder="Label"
                                                        />
                                                    </td>
                                                    <td>
                                                        <select
                                                            className="custom-dropdown1"
                                                            name="category"
                                                            value={newRow.category}
                                                            onChange={handleNewRowChange}
                                                        >
                                                            <option value="">Select Type</option>
                                                            <option value="Email">Email</option>
                                                            <option value="Phone">Phone</option>
                                                            <option value="URL">URL</option>
                                                            <option value="Note">Note</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="value"
                                                            value={newRow.value}
                                                            onChange={handleNewRowChange}
                                                            placeholder="Value"
                                                        />
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>



                                    <div className="d-flex ">
                                        {isAdding.Personal ? (
                                            <>

                                                <button
                                                    className="btn btn-success"
                                                    onClick={handleAddRow}
                                                    style={{ marginTop: '10px' }}
                                                >
                                                    Save
                                                </button>

                                                <button
                                                    className="btn btn-secondary"
                                                    onClick={handleCancelAdd}
                                                    style={{ marginTop: '10px', marginLeft: '10px' }}
                                                >
                                                    Cancel
                                                </button>

                                            </>

                                        ) : (
                                            <button
                                                className="Add-new-table-row"
                                                onClick={() => setIsAdding({ ...isAdding, Personal: true })}
                                            >
                                                <CiCirclePlus style={{ color: '#57BF39', fontSize: '22px', marginRight: '5px' }} />
                                                Add New
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Attachment
                        productUomQty={Quantity}
                        order_id={order_id}
                        parentId={1}
                        MaincardID={ CARDID}
                    />
                </div>

                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    contentLabel="Confirm Deletion"
                    className="Modal"
                    overlayClassName="Overlay"
                >
                    <h2>Are you sure you want to delete this Attachment?</h2>
                    <div className="model-btns-sec">
                        <button className="delete-modal-btn" onClick={handleDeleteRow}>Confirm</button>
                        <button className="cancel-modal-btn" onClick={closeModal}>Cancel</button>
                    </div>
                </Modal>
            </div>
        </>

    );
}
