import React from 'react';
import Modal from 'react-modal';
import "../Style/FalconsList.css";

Modal.setAppElement('#root'); // Ensure accessibility for modal

export default function ComingSoon({ isOpen, closeModal }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="Modal"
      overlayClassName="Overlay"
    >
            <button
 onClick={() => {
  console.log('Close button clicked');
  closeModal();
}}        className="modal-close-btn"
        aria-label="Close"
      >
            x
      </button>
      <h2>Coming Soon</h2>
      {/* <button onClick={closeModal}>Close</button> */}
    </Modal>
  );
}
