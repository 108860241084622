import React, { useState, useRef, useEffect } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import defaultFalconImage from '/webapps/tippytap/src/Assets/Group 2101.png';
import Modal from 'react-modal';
import "../Style/FalconImage.css";
import axios from 'axios';
import { useToken } from './TokenContext';

const FalconImage = ({ isEditing, image, onFileChange }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const cropperRef = useRef(null);
  const [originalFilename, setOriginalFilename] = useState('');
  const [data, setData] = useState([]);
  const { token } = useToken();
  const contactID = token ? atob(token) : null; // Decode the token to get contactID

  useEffect(() => {
    fetchFalcons();
  }, []);

  const fetchFalcons = () => {
    axios
        .get(`https://apis.tippytap.ae/get_by_contact_id/adihex_contacts_falcons/${contactID}`)
        .then((res) => {
            setData(res.data.data);
        })
        .catch((err) => console.error('Error fetching falcon data:', err));
  };

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => {
    setFile(null);
    setCroppedImage(null);
    setModalIsOpen(false);
    setUploading(false);
    setError(null);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setOriginalFilename(selectedFile.name);
      setCroppedImage(null);
    }
  };

  const handleSave = () => {
    if (cropperRef.current) {
      const cropper = cropperRef.current.cropper;
      const croppedCanvas = cropper.getCroppedCanvas();
      const croppedImageURL = croppedCanvas.toDataURL();

      setUploading(true);
      setError(null);

      fetch(croppedImageURL)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], originalFilename, { type: 'image/png' });
          onFileChange({ target: { files: [file] } });
          closeModal();
        })
        .catch(error => {
          setError('Error saving image: ' + error.message);
          console.error('Error saving image:', error);
        })
        .finally(() => setUploading(false));
    } else {
      console.warn('No cropped image to save');
    }
  };

  return (
    <>
      <div className={`single-falcon-img-container ${isEditing ? 'editing-img-container-when-press' : ''}`}>
        {isEditing ? (
          <button className="upload-button-btn" onClick={openModal}>
            Upload Image
          </button>
        ) : (
          <img
            src={image || defaultFalconImage}
            className="falcon-image-1-3"
            alt="Falcon"
            key={image} 
          />
        )}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="Modal"
        overlayClassName="Overlay"
      >
        <h2>Upload and Crop Image</h2>
        {error && <p className="error-message">{error}</p>}
        {uploading ? (
          <div className="uploading-message">
            <div className="spinner"></div>
            <p>Uploading image, please wait...</p>
          </div>
        ) : (
          <>
            {file && (
              <>
                <Cropper
                  src={URL.createObjectURL(file)}
                  style={{ height: 400, width: '100%' }}
                  initialAspectRatio={1 / 3}
                  aspectRatio={2 / 3}
                  guides={false}
                  ref={cropperRef}
                />
                <div className="modal-buttons-save-cancel">
                  <button onClick={handleSave} disabled={uploading}>Save</button>
                  <button onClick={closeModal}>Cancel</button>
                </div>
              </>
            )}
            {!file && (
              <input
                type="file"
                onChange={handleFileChange}
                className="form-control"
              />
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default FalconImage;
