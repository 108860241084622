import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "../Style/ForgetPassword.css";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Bluenav from "./Bluenav";
import Modal from "react-modal";
import greenverify from "/webapps/tippytap/src/Assets/verified.png";

export default function ChangePassword() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [values, setValues] = useState({
        contact_password: "",
        confirm_password: ""
    });
    const [error, setError] = useState("");
    const [showPassword, setShowPassword] = useState({
        contact_password: false,
        confirm_password: false
    }); // Updated state for both passwords
    const [isOpen, setIsOpen] = useState(false);
    const [contactID, setContactID] = useState(); // State to store ContactID




    useEffect(() => {
        axios.get(`https://apis.tippytap.ae/get_by_encrypted/${id}`)
            .then(response => {
                // console.log(response.data.data[0]);
                const User_id = response.data.data[0]['id']
                setContactID(User_id)
                console.log(contactID)
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }, []);




    const handleConfirm = async (event) => {
        event.preventDefault(); // Prevent default form submission

        if (values.contact_password !== values.confirm_password) {
            setError("Passwords Didn't Match. Please Try Again");
            return;
        }

        const requestData = {
            object_id: contactID,
            table_name: "adihex.contacts",
            data: { contact_password: values.contact_password }
        };

        try {
            const response = await axios.post("https://apis.tippytap.ae/Update_record", requestData);
            console.log(response.data);

            // Check if the status in the response is 1
            if (response.data.status === 1) {
                // Navigate to /adihex/signin
                setIsOpen(true)
            } else {
                // Handle other statuses or display the message
                setError(response.data.message);
            }
        } catch (err) {
            setError("An error occurred while resetting the password.");
        }
    };

    const handleShowPasswordToggle = (field) => {
        setShowPassword((prev) => ({
            ...prev,
            [field]: !prev[field]
        }));
    };

    const closeModal = () => {
        setIsOpen(false);
        navigate("/adihex/signin"); // Navigate to /signin when the modal closes
    };

    useEffect(() => {
        let timer;
        if (isOpen) {
            timer = setTimeout(() => {
                closeModal(); // Close the modal and navigate to /signin after 3 seconds
            }, 3000); // Time in milliseconds (e.g., 3000ms = 3 seconds)
        }
        return () => clearTimeout(timer); // Cleanup timer on component unmount
    }, [isOpen, navigate]);



    return (
        <div id="root">
            <Bluenav />
            <Navbar />
            <div>
                <form className="container forgetform" onSubmit={handleConfirm}>
                    <div className="form-title2">
                        <h6>Reset Password</h6>
                    </div>

                    <div className="password-container mb-3">
                        <label htmlFor="newPassword" className="form-label">
                            New Password:
                        </label>
                        <input
                            type={showPassword.contact_password ? "text" : "password"} // Toggle input type
                            className="form-control"
                            placeholder="New Password"
                            id="newPassword"
                            onChange={(e) => setValues({ ...values, contact_password: e.target.value })}
                            required
                        />
                        <i
                            className={`fas ${showPassword.contact_password ? 'fa-eye-slash' : 'fa-eye'} show-password-icon`} // Toggle icon
                            onClick={() => handleShowPasswordToggle('contact_password')} // Toggle password visibility
                        ></i>
                    </div>
                    <div className="password-container mb-3">
                        <label htmlFor="confirmPassword" className="form-label">
                            Confirm New Password:
                        </label>
                        <input
                            type={showPassword.confirm_password ? "text" : "password"} // Toggle input type
                            className="form-control"
                            placeholder="Confirm New Password"
                            id="confirmPassword"
                            onChange={(e) => setValues({ ...values, confirm_password: e.target.value })}
                            required
                        />
                        <i
                            className={`fas ${showPassword.confirm_password ? 'fa-eye-slash' : 'fa-eye'} show-password-icon`} // Toggle icon
                            onClick={() => handleShowPasswordToggle('confirm_password')} // Toggle password visibility
                        ></i>
                    </div>

                    {error && <div className="error-message">{error}</div>}

                    <div className="form-btns-sec pt-4">
                        <button className="signin-btn" type="submit">
                            Reset Password
                        </button>
                    </div>
                </form>
            </div>
            <Footer />

            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                contentLabel="Registration Confirmation"
                className="Modal"
                overlayClassName="Overlay"
            >
                <div className="icon-header-popup-model">
                    <img src={greenverify} />


                </div>
                <h2>Your Password Has Been Successfully Changed!</h2>
                <div className="model-btns-sec">
                    {/* Additional buttons or content can be added here */}
                </div>
            </Modal>
        </div>
    );
}
