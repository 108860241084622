import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "../Style/FalconsList.css";
import pin from "/webapps/tippytap/src/Assets/pencil (1) 1.png";
import profileimg from "/webapps/tippytap/src/Assets/Group 2096 (2).png";
import axios from "axios";
import SingleFalcon from "./SingleFalcon";
import { useNavigate, useParams } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import Bluenav from "./Bluenav";
import { useToken } from './TokenContext';
import Modal from "react-modal";

export default function FalconsList() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { id } = useParams();
  const [file, setFile] = useState(null);
  const [countryCode, setCountryCode] = useState("");
  const [phone, setPhone] = useState("");
  const [data, setData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [imageFile, setImageFile] = useState(null);
  const [allphone , Setallphone]=useState("")
  // const { token ,setToken } = useToken();
  const [formattedPhone, setFormattedPhone] = useState("");
  // const contactID = token ? atob(token) : null; // Decode the token to get contactID
  const [Encrypted ,Setencrypted] = useState(null)


  

  const fetchData = () => {
    axios
      .get(`https://apis.tippytap.ae/get_by_id/adihex_contacts/1`)
      .then((res) => {
        const fetchedData = res.data.data[0];
        const encryptedID =res.data.data[0]['encrypted'];
        Setencrypted(encryptedID)
        //  console.log(contactID)



        setData(fetchedData);
        setEditedData({
          contact_name: fetchedData.contact_name,
          falcons_num: fetchedData.falcons_num,
          contact_image: fetchedData.contact_image
        });
        Setallphone(fetchedData.custome_phone)
        setCountryCode(fetchedData.country_code);
        setPhone(fetchedData.contact_phone || "");
        // console.log("Fetched phone:", fetchedData.contact_phone);

      })
      .catch((err) => console.log(err));
  };

  // useEffect(() => {
  //   if (contactID) {
  //     fetchData();
  //   }
  // }, [contactID, id]);
  

  useEffect(() => {
    fetchData();
  
}, []);


  const handleEditClick = () => {
    if (isEditing) {
      const formattedPhone = phone.startsWith(countryCode)
        ? phone.slice(countryCode.length)
        : phone;
        console.log("Formatted Phone:", formattedPhone); // Log formatted phone
        console.log(" Phone:", phone); // Log formatted phone


      console.log('Country Code:', countryCode);
      const payload = {
        object_id: 1,
        table_name: "adihex.contacts",
        data: {
          ...editedData,
          contact_phone: formattedPhone,
          country_code: countryCode,
          contact_email: data.contact_email,
        },
      };

      axios
        .post("https://apis.tippytap.ae/Update_record", payload)
        .then((response) => {
          console.log(response.data);
          setData({
            ...editedData,
            contact_phone: phone, // Concatenate country code and phone number
            country_code: countryCode,
            contact_email: data.contact_email,
          });

          fetchData();

          if (file) {
            uploadImage();
          }
        })
        .catch((error) => console.log(error));
    }
    setIsEditing(!isEditing);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData({ ...editedData, [name]: value });
  };

  const handlePhoneChange = (value, country) => {
    console.log("Phone Value:", phone); // Log phone value
    console.log("Country Code:", country?.dialCode); // Log country code
    // setPhone(value); // Update phone number with full value including country code
    setCountryCode(country?.dialCode || ""); // Update country code
  };




  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const uploadImage = () => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('contact_id', 1);

    axios
      .post("https://apis.tippytap.ae/upload_image_contact", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        console.log('Image uploaded successfully', response.data);
        fetchData();
      })
      .catch((error) => {
        console.error('Error uploading image:', error);
      });
  };
  const handleDeleteAccount = () => {
    axios
      .get(`https://apis.tippytap.ae/delete_record/adihex.contacts/1`)
      .then((response) => {
        if (response.data.status === 1) {
          console.log('Account deleted successfully', response.data);
          // Redirect to login page or home page
          // history.push('/login');
          sessionStorage.removeItem('loggedInEmail');
          sessionStorage.removeItem('contactID');
          sessionStorage.removeItem('loginTime');
          navigate("/adihex/signin"); // Navigate to /signin when the modal closes
        } else {
          console.error('Unexpected response data:', response.data.message);
          // Handle unexpected response data if needed
        }

      })
      .catch((error) => {
        console.error('Error deleting account:', error);
      });
  };


  const closeModal = () => {
    setIsOpen(false);
  };
  const openModal = () => {
    setIsOpen(true);

  };

  const handleChangePassword = () => {
    // Clear session storage
    // setToken(null)
    sessionStorage.removeItem('loggedInEmail');
    sessionStorage.removeItem('contactID');
    sessionStorage.removeItem('loginTime');

    // Optionally clear token from context
    
    navigate(`/adihex/resetpassword/${Encrypted}`); // Navigate to the reset password page with the encrypted value


  };

  // const formatPhoneNumber = (number, code) => {
  //   if (number.startsWith(code)) {
  //     return number.slice(code.length);
  //   }
  //   return number;
  // };


  return (
    <div id="root">
      <Bluenav />
      <Navbar />
      <div className="falcons-container container">
        <h6>My Profile</h6>
        <div className="single-falcon-detail">
          <div className="single-falcon-detail-titles">
            <h6></h6>
            <div className="edit-btn">
              <button
                className="edit-btn-002"
                onClick={handleEditClick}
                style={{
                  backgroundColor: isEditing ? '#32de84' : '', // Green for Save, default for Edit
                  color: isEditing ? 'white' : '', // Text color for Save
                }}
              >
                {!isEditing && <img src={pin} className="mx-1" alt="Edit" />}
                {isEditing ? "Save" : "Edit"}
              </button>
            </div>

          </div>

          <div className="single-falcon-data-layout">
          <div className={`single-Profile-img-container ${isEditing ? 'editing-img-container-when-press' : ''}`}>
              {isEditing ? (
                <div>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    className="form-control"
                  />
                  {imageFile && (
                    <img src={imageFile} className="profile-img-001" alt="Selected" />
                  )}
                </div>
              ) : (
                <img src={data.contact_image || profileimg} className="profile-img-001" alt="Profile" />
              )}
            </div>

            <div className="single-falcon-column-data-container">
              <div className={`falcon-data-1 ${isEditing ? 'edit-mode' : ''}`}>
                <span>Name :</span>
                {isEditing ? (
                  <div>
                    <input
                      type="text"
                      name="contact_name"
                      value={editedData.contact_name}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                  </div>
                ) : (
                  <p>{data.contact_name}</p>
                )}
              </div>
              <div className={`falcon-data-1 ${isEditing ? 'edit-mode' : ''}`}>
                <span>Phone :</span>
                {isEditing ? (
                  <div>
                    <PhoneInput
                      country={countryCode}
                      value={allphone}
                      onChange={handlePhoneChange}
                      inputStyle={{ width: '100%' }}
                      placeholder="Enter phone number"
                    />

                  </div>
                ) : (
                  <p>+{`${countryCode}${phone}`}</p>
                )}
              </div>
              {isEditing ? null : (
                <>
                  <div className="falcon-data-1">
                    <span>Email Address :</span>
                    <p>{data.contact_email}</p>
                  </div>
                  {/* <div className="falcon-data-1">
                    <span>Password</span>
                    <p className="password-disc">{data.contact_password}</p>
                  </div> */}
                </>
              )}

            </div>

          </div>
          {isEditing && (
            <div className="btns-profile">
              <button className="delete-btn-profile" onClick={openModal}>
                Delete Account
              </button>
              <button className="edit-btn-profle" onClick={handleChangePassword}>
                Change Password
              </button>
            </div>
          )}
        </div>
        <SingleFalcon />
      </div>
      <Footer />

      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Confirm Deletion"
        className="Modal"
        overlayClassName="Overlay"
      >
        <h2>Are you sure you want to delete Account ?</h2>
        <div className="model-btns-sec">
          <button className="delete-modal-btn" onClick={() => handleDeleteAccount()}>Confirm</button>
          <button className="cancel-modal-btn" onClick={closeModal}>Cancel</button>
        </div>
      </Modal>
    </div>
  );
}
