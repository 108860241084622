import React, { useEffect, useState } from "react";
import "/webapps/tippytap/src/Style/BusinessCardContent.css";
import NewCardDetails from "./New-card-details";
import axios from "axios";
import { useToken } from '../TokenContext';
// import FillBusinessCardData from "./PersonalDataSection";
// import BusinessCardData from "./BusinessCardData";
import { useNavigate, useParams } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { scroller } from 'react-scroll';

const BusinessCardContent = ({ id }) => {
  // const [showNewComponent, setShowNewComponent] = useState(null);
  const [orders, setOrders] = useState([]);
  const { token } = useToken();
  const contactID = token ? atob(token) : null; // Decode the token to get contactID
  const navigate = useNavigate();
  // const { id } = useParams();
  const [CARDID, setCARDID] = useState('');
  
  useEffect(() => {
    axios.get(`https://apis.tippytap.ae/orders_history/${contactID}/1`)
      .then(response => {
        setOrders(Array.isArray(response.data.result) ? response.data.result : []);
        console.log(response.data.result); // Log response to check its structure
        console.log("id", contactID)
      })
      .catch(error => {
        console.error('Error fetching orders history:', error);
      });
  }, [id, contactID]);

  const handleNewButtonClick = (order) => {
    if (order.view === "Design" && order.state !== "draft") {
      navigate(`/profile/businesscard/carddesign/${order.order_id}`, {
        state: { productUomQty: order.product_uom_qty ,
          MaincardID: CARDID,
        },
      });
    } else if (order.view === "Data" && order.state !== "draft") {
      axios.get(`https://apis.tippytap.ae/get_main_cards_ids/${order.order_id}/${1}`)
        .then(response => {
          console.log("WE are going to data page")
          const data = response.data.result[0];
          const Maincard_id = data.id
          setCARDID(Maincard_id)
          navigate(`/profile/businesscard/carddata/${order.order_id}`, {
            state: {
              productUomQty: order.product_uom_qty,
              MaincardID: Maincard_id,
            },
          });
        })
        .catch(error => {
          console.error('Error fetching MaincardID:', error);
        });
    } else {
      navigate(`/checkout/${order.order_id}`);
    }
  };



  const handleScrollToPricing = () => {
    // Navigate to the landing page and then scroll to the section
    navigate('/', { state: { scrollToPricing: true } });
  };

  return (
    <div className="Business-content">
      
        <div className="BussinessCard-container">
          <div className="BussinessCard-container-float">
            <h6>Business Card</h6>
            <div className="order-btn-container">
            
                <button className="order-now-btn" onClick={handleScrollToPricing}>Order Now</button>
            </div>
          </div>

          {orders.map((order) => (
            <div
              key={order.id}
              className="blue-container"
              onClick={() => handleNewButtonClick(order)}
            >
              <div className="blue-container-data-flexing">
                <span className="order-number-001">Order Number : </span>
                <p>{order.origin || 'N/A'}</p>
              </div>
              <div className="blue-container-data-flexing">
                <span>Date : </span>
                <p>{order.invoice_date || 'N/A'}</p>
              </div>
              <div className="blue-container-data-flexing">
                <span>Quantity : </span>
                <p>{order.product_uom_qty || 'N/A'}</p>
              </div>
              <div className="btn-container-003">
                <button
                  className="new-btn"
                >
                  {order.state}
                </button>
              </div>
            </div>
          ))}
        </div>
    
    </div>
  );
};

export default BusinessCardContent;
