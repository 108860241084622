import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './Components/Home/Home';
import Signin from './Components/Signin';
import Registration from './Components/Registration';
import FalconsList from './Components/FalconsList';
import ForgetPassowrd from './Components/ForgetPassword';
import Falcon_profile from './Components/Falcon_profile';
import { TokenProvider } from './Components/TokenContext';
import ProtectedRoute from '/webapps/tippytap/src/Components/ProtectedRoute.js';
import Confirm from './Components/ChangePassword';
import ForgetPasswordMain from './Components/ForgetPasswordMain';
import ConfirmPasswordMain from './Components/ConfirmPasswordMain';
import Privacy from './Components/Privacy';
import ContactUS from './Components/Contactus';
import ChangePassword from './Components/ChangePassword';
import FillDataForm from './Components/FillDataForm';
import ScrollToTop from './Components/AdditionalComp/ScrollToTop';
import Tippy_register from '/webapps/tippytap/src/Components/TippyORG/Tippy_register.jsx';
import Tippy_signin from '/webapps/tippytap/src/Components/TippyORG/Tippy_signin.jsx';
import ForgetPasswordTAP from './Components/TippyORG/ForgetPasswordTAP';
import Verify from './Components/TippyORG/Verify';
import ProfileLayout from './Components/TippyORG/MainLayout';
import Checkout from './Components/TippyORG/Checkout';
import Payment from './Components/TippyORG/Payment_blank';
import QRCard from './Components/TippyORG/QR_card';
// import { AuthProvider } from './Components/AuthContext';
function App() {
  return (
    <div>
      <TokenProvider>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={ <Home />}> </Route>
        <Route path="/adihex/signin" element={ <Signin />}> </Route>
        <Route path="/adihex" element={ <Registration />}> </Route>
        {/* <Route path="/adihex/verify/:id" element={ <Verify />}> </Route> */}
        <Route path="/adihex/forgetpassword" element={ <ForgetPassowrd />}> </Route>
        <Route path="/adihex/profile/:id"element={ <FalconsList />}></Route>
        <Route path="/adihex/falcon_profile/:id" element={<Falcon_profile />} />
        <Route path="/adihex/resetpassword/:id" element={ <ChangePassword />}> </Route>
        {/* <Route path="/Register" element={ <Tippy_register />}> </Route> */}
        {/* <Route path="/signin" element={ <Tippy_signin />}> </Route>  */}
        {/* <Route path="/forgetpassword" element={ <ForgetPasswordMain />}> </Route> */}
        {/* <Route path="/resetpassword/:id" element={ <ConfirmPasswordMain />}> </Route>Privacy */}
        <Route path="/Privacy" element={ <Privacy />}> </Route>
        <Route path="/Contactus" element={ <ContactUS />}> </Route>
        {/* <Route path="/profile" element={ <MainLayout />}> </Route> */}
        <Route path="/adihex/updatefalcondata/:id" element={ <FillDataForm />}> </Route>
        <Route path="/Register" element={ <Tippy_register />}> </Route>
        <Route path="/signin" element={ <Tippy_signin />}> </Route>
        <Route path="/forgetpassword" element={ <ForgetPasswordTAP />}> </Route>
        <Route path="/verify/:id" element={ <Verify/>}> </Route>
        <Route path="/profile/*" element={<ProtectedRoute element={<ProfileLayout />} />} /> 
        <Route path="/checkout/:id" element={ <Checkout />}> </Route>
        <Route path="/payment/:order_id/:session_id" element={<Payment />} />
        <Route path="/cardprofile/:id" element={ <QRCard/>}> </Route>




      </Routes>
      </TokenProvider>
</div>
  );
}

export default App;