import React, { useEffect, useState } from "react";
import "/webapps/tippytap/src/Style/QR_card.css";
import second from "/webapps/tippytap/src/Assets/Group 111@2x.png";
import qrcardimg from "/webapps/tippytap/src/Assets/Mask Group 14@2x.png";
import contactimg1 from "/webapps/tippytap/src/Assets/Group 106@2x.png";
import contactimg2 from "/webapps/tippytap/src/Assets/Group 109@2x.png";
import leftimg from "/webapps/tippytap/src/Assets/Image 31@2x.png";
import linkedin from "/webapps/tippytap/src/Assets/Group 119@2x.png";
import youtube from "/webapps/tippytap/src/Assets/Group 121@2x.png";
import whatever from "/webapps/tippytap/src/Assets/Group 129@2x.png";
import facebook from "/webapps/tippytap/src/Assets/Group 131@2x.png";
import x from "/webapps/tippytap/src/Assets/Group 133@2x.png";
import phone from "/webapps/tippytap/src/Assets/Group 103@2x.png";
import TEXTERT from "/webapps/tippytap/src/Assets/ic_date@2x.png";
import LOC from "/webapps/tippytap/src/Assets/ic_Place@2x.png"
import xls from "/webapps/tippytap/src/Assets/Group 142@2x.png"
import pdf from "/webapps/tippytap/src/Assets/Image 32@2x.png"
import ps from "/webapps/tippytap/src/Assets/Group 150@2x.png"
import docx from "/webapps/tippytap/src/Assets/doc (1).png"
import CSV from "/webapps/tippytap/src/Assets/CSV.png"
import xml from "/webapps/tippytap/src/Assets/xml.png"
import txt from "/webapps/tippytap/src/Assets/txt.png"
import image from "/webapps/tippytap/src/Assets/image (2).png"
import defaulttt from "/webapps/tippytap/src/Assets/default.png"

import axios from "axios";
import defaultsociallink from "/webapps/tippytap/src/Assets/Group 2359.png"

import { useNavigate, useParams } from 'react-router';
import { Link } from "react-router-dom";

export default function QRCard() {

  const [Data, SetData] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [CardID, SetCardID] = useState([]);
  const [Attachments, SetAttachments] = useState([])
  const [Items, SetItems] = useState([])
  const [Items2, SetItems2] = useState([])




  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`https://apis.tippytap.ae/get_card_by_encrypted/${id}`);
        const userData = response.data.data[0];
        const userId = userData['id'];
        // const isVerifyValue = userData['is_verify'];
        console.log(userData);
        SetCardID(userId);
        // setIsVerify(isVerifyValue);
        console.log('Fetched is_verify value:', userId);
      } catch (error) {
        console.error('There was an error!', error);
      }
    };

    fetchUserData();
  }, [id]);

  const renderSocialIcons = () => {
    return Items
      .filter(item => item.category === 'URL' && item.is_active)
      .map(item => {
        const label = item.label.toLowerCase();
        const value = item.value.toLowerCase();

        let src;
        let altText;

        if (label.includes('facebook') || value.includes('facebook')) {
          src = facebook;
          altText = "Facebook";
        } else if (label.includes('linkedin') || value.includes('linkedin')) {
          src = linkedin;
          altText = "LinkedIn";
        } else if (label.includes('youtube') || value.includes('youtube')) {
          src = youtube;
          altText = "YouTube";
        } else if (label.includes('x') || value.includes('x')) {
          src = x;
          altText = "X";
        } else if (label.includes('whatever') || value.includes('whatever')) {
          src = whatever;
          altText = "Whatever";
        } else {
          src = defaultsociallink;
          altText = "Default Social Link";
        }

        const absoluteUrl = value.startsWith('http://') || value.startsWith('https://')
          ? item.value
          : `http://${item.value}`;

        return (
          <a key={item.id} href={absoluteUrl} target="_blank" rel="noopener noreferrer">
            <img className="social-icon-img-000" src={src} alt={altText} />
          </a>
        );
      });
  };


  const AddContact = () => {

    window.location.href = Data.vcf;


  }



  useEffect(() => {
    const fetchCardData = async () => {
      if (CardID) {
        try {
          const response = await axios.get(`https://apis.tippytap.ae/view_single_card/${CardID}`);
          const data = response.data.result;
          SetData(data);
          const URLS = data['items']
          console.log('utls', URLS)
          SetItems(URLS)
          const cardAttachment = data['card_attachment']; // Extract card_attachment array
          console.log('cards', cardAttachment);
          SetAttachments(cardAttachment);

          const carditems = data['items']
          console.log('items ',carditems)
          SetItems2(carditems)
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };

    fetchCardData();
  }, [CardID]);


  const getAttachmentIcon = (url) => {
    if (typeof url !== 'string') {
      console.error('Invalid URL:', url);
      return x; // Default image if URL is not a string
    }

    // Remove query string from URL
    const cleanFileName = url.split('?')[0];

    // Extract file extension from the cleaned URL
    const extension = cleanFileName.split('.').pop().toLowerCase();
    console.log('File extension:', extension); // Log file extension for debugging

    switch (extension) {
      case 'xls':
        return xls;
      case 'pdf':
        return pdf;
      case 'docx':
        return docx;
      case 'ps':
        return ps;
      case 'xml':
        return xml;
      case 'txt':
        return txt;
      case 'CSV':
        return CSV;
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
        return image;
      default:
        console.warn('Unknown file extension:', extension); // Warn if extension is not matched
        return defaulttt; // Default image if no match
    }
  };


  const handleOrderNowClick = () => {
    navigate('/'); // Navigate to the /landing route
  };

  return (
    <>
      <div className="purple-nav-section">
        <div className="BussinessCard-container-float container">
          <Link to='/'> <img className="sec-nav" src={second} /></Link>

          <div className="order-btn-container">
            <button className="order-now-btn" onClick={handleOrderNowClick}>Order Now</button>
          </div>
        </div>
        <div className="qr-card-center">
          <div className="qrcard">
            <div className="qr-card-body">
              <div className="qr-card-img">
                <img className="qr-person-img" src={Data.image_path} />
              </div>
              <div className="qr-card-titles">
                <h6>{Data.first_name}</h6>
                <h6>{Data.last_name}</h6>
                <span>{Data.title}</span>
                <div className="contacts-imgs">
                  <div class="contact-img-1-container">
                  <a href={`mailto:${Data.Email}`} title="Send an email">
                    <img className="contact-img-1"  src={contactimg1} /></a>
                  </div>
                  <div class="contact-img-1-container">
                  <a href={`mailto:${Data.phone}`} title="phone">
                    <img className="contact-img-1" src={contactimg2} /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button className="addtocontact-btn" onClick={AddContact}>+ Add to Contact</button>
        </div>

        <div className="profile-bio container">
          <h6>Bio</h6>
          <p>
            {Data.bio ? (
              Data.bio.split('\n').map((line, index) => (
                <span key={index}>
                  {line}
                  <br />
                </span>
              ))
            ) : (
              "No bio available"
            )}
          </p>
        </div>
        <div className="grid-personal-social-data container">
  {Items2.map((item) => {
    if (item.category === 'Phone') {
      return (
        <div key={item.id} className="single-card-phone-data">
          <div className="single-img-card-phone">
            <img className="phone-icon-1" src={phone} alt="Phone" />
          </div>
          <div className="single-card-phone-titles">
            <span>Phone</span>
            <p>{item.value}</p>
          </div>
        </div>
      );
    }
    if (item.category === 'Email') {
      return (
        <div key={item.id} className="single-card-phone-data">
          <div className="single-img-card-phone">
            <img className="phone-icon-1" src={TEXTERT} alt="Email" />
          </div>
          <div className="single-card-phone-titles">
            <span>Email</span>
            <p>{item.value}</p>
          </div>
        </div>
      );
    }
    if (item.category === 'Note') {
      return (
        <div key={item.id} className="single-card-phone-data">
          <div className="single-img-card-phone">
            <img className="phone-icon-1" src={TEXTERT} alt="Note" />
          </div>
          <div className="single-card-phone-titles">
            <span>Note</span>
            <p>{item.value}</p>
          </div>
        </div>
      );
    }
    return null; // Return null for items that don't match any category
  })}
</div>

        {/* <div className="attachments-section container">
          <h6>Attachment</h6>
          <div className="List-of-attachment">
            <div className="single-attachment-container">
              <img className="attachment-img" src={xls} />

            </div>
            <div className="single-attachment-container">
              <img className="attachment-img" src={pdf} />

            </div>
            <div className="single-attachment-container">
              <img className="attachment-img" src={ps} />

            </div>

          </div>
        </div> */}


        <div className="attachments-section container">
          <h6>Attachment</h6>
          <div className="List-of-attachment">
            {Attachments.map((attachment, index) => (
              <a
                key={index}
                href={attachment.value}
                target="_blank" // Opens the link in a new tab
                rel="noopener noreferrer" // For security reasons
                className="attachment-link"
              >
                <div className="single-attachment-container" >
                  <img className="attachment-img" src={getAttachmentIcon(attachment.value)} alt={attachment.label}  />
                  <span className="attachment-label" >{attachment.label}</span>
                </div>
              </a>
            ))}
          </div>
        </div>

      </div>
    </>
  );
}
