import React from "react";
import partner1 from "/webapps/tippytap/src/Assets/Image 16@2x.png";
import partner2 from "/webapps/tippytap/src/Assets/Image 17@2x.png";
import partner3 from "/webapps/tippytap/src/Assets/Image 18@2x.png";
import partner4 from "/webapps/tippytap/src/Assets/Image 15@2x.png";
import partner5 from "/webapps/tippytap/src/Assets/Image 14@2x.png";
import partner1con from "/webapps/tippytap/src/Assets/Group 248@2x.png";
import partner2con from "/webapps/tippytap/src/Assets/Group 246@2x.png";
import partner3con from "/webapps/tippytap/src/Assets/Group 244@2x.png";
import partner4con from "/webapps/tippytap/src/Assets/Group 242@2x.png";
import partner5con from "/webapps/tippytap/src/Assets/Group 245@2x.png";
import partner6con from "/webapps/tippytap/src/Assets/Group 2351.png";
import "/webapps/tippytap/src/Style/Partner.css";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import bottomleft from "/webapps/tippytap/src/Assets/Group 229@2x.png";
import middleright from "/webapps/tippytap/src/Assets/Group 231@2x.png";

export default function Patner() {
  return (
    // <div className="Partner-Sec" id="Partner">
    //   <div className="Partner-titles pb-3">
    //     <h6>Partner</h6>
    //     <h5>Our Trusted Partners</h5>
    //   </div>

    //   <Swiper
    //     modules={[Pagination, A11y, Autoplay]}
    //     spaceBetween={50}
    //     slidesPerView={6}
    //     autoplay={{
    //       delay: 1500, // Delay between slides in milliseconds
    //       disableOnInteraction: false, // Continue autoplay after user interaction
    //     }}
    //     breakpoints={{
    //       // When the viewport width is >= 320px
    //       320: {
    //         slidesPerView: 2.5,
    //         spaceBetween: 10,
    //       },
    //       // When the viewport width is >= 480px
    //       480: {
    //         slidesPerView: 2,
    //         spaceBetween: 20,
    //       },
    //       // When the viewport width is >= 768px
    //       768: {
    //         slidesPerView: 4,
    //         spaceBetween: 30,
    //       },
    //       // When the viewport width is >= 1024px
    //       1024: {
    //         slidesPerView: 6,
    //         spaceBetween: 50,
    //       },
    //     }}
    //     onSwiper={(swiper) => console.log(swiper)}
    //     onSlideChange={() => console.log("slide change")}
    //   >
    //     <SwiperSlide className="swiper-slide-auto">
    //       <div className="image-container">
    //         <img src={partner1} alt="Partner 1" />
    //       </div>
    //     </SwiperSlide>
    //     <SwiperSlide className="swiper-slide-auto">
    //       <div className="image-container">
    //         <img src={partner2} alt="Partner 2" />
    //       </div>
    //     </SwiperSlide>
    //     <SwiperSlide className="swiper-slide-auto">
    //       <div className="image-container">
    //         <img src={partner3} alt="Partner 3" />
    //       </div>
    //     </SwiperSlide>
    //     <SwiperSlide className="swiper-slide-auto">
    //       <div className="image-container">
    //         <img src={partner4} alt="Partner 4" />
    //       </div>
    //     </SwiperSlide>
    //     <SwiperSlide className="swiper-slide-auto">
    //       <div className="image-container">
    //         <img src={partner5} alt="Partner 5" />
    //       </div>
    //     </SwiperSlide>
    //     <SwiperSlide className="swiper-slide-auto">
    //       <div className="image-container">
    //         <img src={partner6} alt="Partner 6" />
    //       </div>
    //     </SwiperSlide>
    //     <SwiperSlide className="swiper-slide-auto">
    //       <div className="image-container">
    //         <img src={partner6} alt="Partner 6" />
    //       </div>
    //     </SwiperSlide>
    //   </Swiper>
    // </div>
    <div className="white-background-color">
    <div className="Partner-Sec container" id="Partner">
      <div className="Partner-titles pb-3">
        <h4>Our Trusted <br /> Partners</h4>
        {/* <p>Giving Creators More Control Over Their Tiktok Presence Right In Their Own <br /> Dummy Text Tippytap.</p> */}
      </div>

      <div className="flex-partner-layout-list">

        <div className="partner-item-container">

          <img src={partner1con} />

        </div>

        <div className="partner-item-container">

          <img src={partner2con} />

        </div>
        <div className="partner-item-container">

          <img src={partner6con} />

        </div>
        <div className="partner-item-container">

          <img src={partner3con} />

        </div>

        <div className="partner-item-container">

          <img src={partner4con} />

        </div>
        <div className="partner-item-container">

          <img src={partner5con} />

        </div>



      </div>

      </div>




    </div>
  );
}
