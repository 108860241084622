// import React, { useEffect, useState } from 'react';
// import "/webapps/tippytap/src/Style/Pricing.css";
// import Pringtag from '/webapps/tippytap/src/Assets/Group 90.png';
// import BluePringtag from '/webapps/tippytap/src/Assets/Group 90 (1).png';
// import Vector from '/webapps/tippytap/src/Assets/Vector 1.png';
// import axios from 'axios';
// import ComingSoon from '../ComingSoon'; // Import the ComingSoon component

// export default function Pricing() {
//     const [activeCards, setActiveCards] = useState([]);
//     const [counts, setCounts] = useState([1, 1, 1]);
//     const [data, setData] = useState({});
//     const [modalIsOpen, setIsModalOpen] = useState(false);

//     const handleOrderNowClick = (index) => {
//         setActiveCards(prevState =>
//             prevState.includes(index) ? prevState.filter(i => i !== index) : [...prevState, index]
//         );
//     };


//     const openModal = () => {
//         setIsModalOpen(true);
//     };

//     const closeModal = () => {
//         setIsModalOpen(false);
//     };


//     const handleIncrement = (index) => {
//         setCounts(counts.map((count, i) => (i === index ? count + 1 : count)));
//     };

//     const handleDecrement = (index) => {
//         setCounts(prevCounts => {
//             const newCounts = prevCounts.map((count, i) => {
//                 if (i === index) {
//                     if (count > 1) return count - 1;
//                     if (activeCards.includes(index)) {
//                         setActiveCards(activeCards.filter(activeIndex => activeIndex !== index));
//                     }
//                     return 0;
//                 }
//                 return count;
//             });
//             return newCounts;
//         });
//     };

//     const handleCheckoutClick = () => {
//         alert('Proceeding to checkout');
//     };



//     useEffect(() => {
//         axios
//             .get("https://apis.tippytap.ae/get_packages/")
//             .then((res) => {
//                 const fetchedData = res.data.data;
//                 setData(fetchedData)
//                 console.log(fetchedData)
//             })
//             .catch((err) => console.log(err));
//     }, []);

//     const firstItemPrice = data[0]?.list_price;
//     const SecondItemPrice = data[1]?.list_price;
//     const ThirdItemPrice = data[2]?.list_price;


//     const firstItemName = data[0]?.name;
//     const SecondItemName = data[1]?.name;
//     const ThirdItemName = data[2]?.name;
//     const features1 = data[0]?.features || [];
//     const features2 = data[1]?.features || [];
//     const features3 = data[2]?.features || [];


//     return (
//         <div className='Pricing-wrap' >
//             <div className='pricing-titles'>
//                 <h6>Pricing</h6>
//                 <p>Try Oval free for 30 days to start connected with all your teams. Cancel any time. No credit card required.</p>
//             </div>

//             <div className='pricing-cards-grid container'>
//                 <div className="card20">
//                     <div className="header-card-flex">
//                         <div className='header-titles'>
//                             <h6>TippyTap</h6>
//                             <span>{firstItemName}</span>
//                         </div>
//                         <div className="price-tag-float-right">
//                             <img src={Pringtag} className="pricetag-img" alt="pricetag-img" />
//                             <span className='price-tag-text'>$</span>
//                             {/* {firstItemPrice} */}
//                         </div>
//                     </div>
//                     <hr className="styled-line" />
//                     <div className="card-body20">
//                         {features1.map((feature, i) => (
//                             <div key={i} className="card-features">
//                                 <img src={Vector} alt="feature-icon" />
//                                 <span className='card-features-text'>
//                                     {feature}
//                                 </span>
//                             </div>
//                         ))}
//                     </div>
//                     {activeCards.includes(0) ? (
//                         <div className='counter-container'>
//                             <button className='decrement-btn' onClick={() => handleDecrement(0)}>-</button>
//                             <span className='counter-value'>{counts[0]}</span>
//                             <button className='INcrement-btn' onClick={() => handleIncrement(0)}>+</button>
//                         </div>
//                     ) : (
//                         <div className='order-now-btn-container'>
//                             <button className='order-now-btn1' onClick={() => openModal()}>
//                                 Order Now
//                             </button>
//                         </div>
//                     )}
//                 </div>

//                 <div className="card20">
//                     <div className="header-card-flex">
//                         <div className='header-titles'>
//                             <h6>TippyTap</h6>
//                             <span>{SecondItemName}</span>
//                         </div>
//                         <div className="price-tag-float-right">
//                             <img src={BluePringtag} className="pricetag-img" alt="pricetag-img" />
//                             <span className='price-tag-text' style={{ color: "white" }}>$</span>
//                             {/* {SecondItemPrice} */}
//                         </div>
//                     </div>
//                     <hr className="styled-line" />
//                     <div className="card-body20">
//                         {features2.map((feature, i) => (
//                             <div key={i} className="card-features">
//                                 <img src={Vector} alt="feature-icon" />
//                                 <span className='card-features-text'>
//                                     {feature}
//                                 </span>
//                             </div>
//                         ))}
//                     </div>
//                     {activeCards.includes(1) ? (
//                         <div className='counter-container'>
//                             <button className='decrement-btn' onClick={() => handleDecrement(1)}>-</button>
//                             <span className='counter-value'>{counts[1]}</span>
//                             <button className='INcrement-btn' onClick={() => handleIncrement(1)}>+</button>
//                         </div>

//                     ) : (
//                         <div className='order-now-btn-container'>
//                             <button className='order-now-btn-BLUE' onClick={() => openModal()}>
//                                 Order Now
//                             </button>
//                         </div>
//                     )}
//                 </div>

//                 <div className="card20">
//                     <div className="header-card-flex">
//                         <div className='header-titles'>
//                             <h6>TippyTap</h6>
//                             <span>{ThirdItemName}</span>
//                         </div>
//                         <div className="price-tag-float-right">
//                             <img src={Pringtag} className="pricetag-img" alt="pricetag-img" />
//                             <span className='price-tag-text'>$</span>
//                             {/* {ThirdItemPrice} */}
//                         </div>
//                     </div>
//                     <hr className="styled-line" />
//                     <div className="card-body20">
//                         {features3.map((feature, i) => (
//                             <div key={i} className="card-features">
//                                 <img src={Vector} alt="feature-icon" />
//                                 <span className='card-features-text'>
//                                     {feature}
//                                 </span>
//                             </div>
//                         ))}
//                     </div>
//                     {activeCards.includes(2) ? (
//                         <div className='counter-container'>
//                             <button className='decrement-btn' onClick={() => handleDecrement(2)}>-</button>
//                             <span className='counter-value'>{counts[2]}</span>
//                             <button className='INcrement-btn' onClick={() => handleIncrement(2)}>+</button>
//                         </div>
//                     ) : (
//                         <div className='order-now-btn-container'>
//                             <button className='order-now-btn1' onClick={() => openModal()}>
//                                 {/* <button className='order-now-btn1' onClick={() => handleOrderNowClick(2)}> */}

//                                 Order Now
//                             </button>
//                         </div>
//                     )}
//                 </div>
//             </div>

//             {activeCards.length > 0 && (
//                 <div className='buy-btn-container'>
//                     <button onClick={handleCheckoutClick} className='Buy-now-btn'>
//                         Buy Now
//                     </button>
//                 </div>
//             )}

//             <div className='pricing-titles2'>
//                 <h6>All Support plans come with the Lite versions of Chat, Talk, and Guide</h6>
//                 <p>Prices shown are billed annually. For prices billed month-to-month, click here. Support Professional and above subscriptions include Explore Lite reporting. *Your free trial starts on Team.</p>
//             </div>

//             <ComingSoon
//                 isOpen={modalIsOpen}
//                 closeModal={closeModal}
//             />
//         </div>
//     );
// }
import React, { useEffect, useState } from 'react';
import "/webapps/tippytap/src/Style/Pricing.css";
import Pringtag from '/webapps/tippytap/src/Assets/Group 90.png';
import BluePringtag from '/webapps/tippytap/src/Assets/Group 90 (1).png';
import Vector from '/webapps/tippytap/src/Assets/Path.png';
import axios from 'axios';
import { useToken } from '../../Components/TokenContext';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';
import ComingSoon from '../ComingSoon';

export default function Pricing() {
    const [activeCards, setActiveCards] = useState([]);
    const [counts, setCounts] = useState([1, 1, 1]);
    const [data, setData] = useState({});
    const { token } = useToken();
    const contactID = token ? atob(token) : null; // Decode the token to get contactID
    const navigate = useNavigate();
    const location = useLocation();
    const [modalIsOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
          if (location.state?.scrollToPricing) {
            scroller.scrollTo('Pricing', {
              smooth: true,
             duration: 500,
          });
      }
        }, [location]);

    const openModal = () => {
         setIsModalOpen(true);
     };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleOrderNowClick = (index) => {
        setActiveCards(prevState =>
            prevState.includes(index) ? prevState.filter(i => i !== index) : [...prevState, index]
        );
    };

    const handleIncrement = (index) => {
        setCounts(counts.map((count, i) => (i === index ? count + 1 : count)));
    };

    const handleDecrement = (index) => {
        setCounts(prevCounts => {
            const newCounts = prevCounts.map((count, i) => {
                if (i === index) {
                    if (count > 1) return count - 1;
                    if (activeCards.includes(index)) {
                        setActiveCards(activeCards.filter(activeIndex => activeIndex !== index));
                    }
                    return 0;
                }
                return count;
            });
            return newCounts;
        });
    };


    useEffect(() => {
        axios
            .get("https://apis.tippytap.ae/get_packages/")
            .then((res) => {
                const fetchedData = res.data.data;
                setData(fetchedData)
                console.log(fetchedData)
                console.log(contactID)

            })
            .catch((err) => console.log(err));
    }, []);

    const handleCheckoutClick = () => {

        if (!contactID) {
            navigate('/signin'); // Redirect to the signin page if the user is not logged in
            return;
        }
        
        const selectedProducts = activeCards.map(index => ({
            product_id: data[index].id,
            quantity: counts[index]
        }));

        const payload = {
            contact_id: contactID, // Replace with actual contact_id
            res_product: selectedProducts
        };

        axios.post('https://apis.tippytap.ae/create_order/', payload)
            .then(response => {
                console.log('Purchase successful:', response.data);
                const { status, order_id } = response.data;

                navigate(`/checkout/${order_id}`); 
            })
            .catch(error => {
                console.error('There was an error with the purchase!', error);
                // alert('Failed to complete the purchase.');
            });
    };



    const firstItemPrice = data[0]?.list_price;
    const SecondItemPrice = data[1]?.list_price;
    const ThirdItemPrice = data[2]?.list_price;
    const firstItemName = data[0]?.name;
    const SecondItemName = data[1]?.name;
    const ThirdItemName = data[2]?.name;
    const features1 = data[0]?.features || [];
    const features2 = data[1]?.features || [];
    const features3 = data[2]?.features || [];
    return (
        <div  >
        <div className='Pricing-wrap'  >
            <div className='pricing-titles' >
                <h6><span> Choose Your</span> Plan</h6>
                <p >TippyTap Business Card Packages Order <br /> (below prices are per card): </p>
                <p>[1-9] Cards: 300 AED</p>
                    <p>[10-19] Cards (-10%): 270 AED</p>
                    <p>[20-50] Cards (-15%): 255 AED</p>
            </div>

            <div className='pricing-cards-grid container'>
                <div className="card20">
                    <div className="header-card-flex">
                        <div className='header-titles'>
                            <h6>TippyTap</h6>
                            <span>{firstItemName}</span>
                        </div>
                        {/* <div className="price-tag-float-right">
                            <img src={Pringtag} className="pricetag-img" alt="pricetag-img" />
                            <span className='price-tag-text'>{firstItemPrice}$</span>
                        </div> */}
                    </div>
                    <hr className="styled-line" />
                    <div className="card-body20">
                        {features1.map((feature, i) => (
                            <div key={i} className="card-features">
                                <img src={Vector} alt="feature-icon" />
                                <span className='card-features-text'>
                                    {feature}
                                </span>
                            </div>
                        ))}
                    </div>
                    {activeCards.includes(0) ? (
                        <div className='counter-container'>
                            <button className='decrement-btn' onClick={() => handleDecrement(0)}>-</button>
                            <span className='counter-value'>{counts[0]}</span>
                            <button className='INcrement-btn' onClick={() => handleIncrement(0)}>+</button>
                        </div>
                    ) : (
                        <div className='order-now-btn-container'>
                            <button className='order-now-btn1' onClick={() => handleOrderNowClick(0)}>
                                Order Now
                            </button>
                               {/* <button className='order-now-btn-BLUE' onClick={() => openModal()}>
                                 Order Now
                             </button> */}
                        </div>
                    )}
                </div>

                <div className="card20">
                    <div className="header-card-flex">
                        <div className='header-titles'>
                            <h6>TippyTap</h6>
                            <span>{SecondItemName}</span>
                        </div>
                        {/* <div className="price-tag-float-right">
                            <img src={BluePringtag} className="pricetag-img" alt="pricetag-img" />
                            <span className='price-tag-text' style={{ color: "white" }}>{SecondItemPrice}$</span>
                        </div> */}
                    </div>
                    <hr className="styled-line" />
                    <div className="card-body20">
                        {features2.map((feature, i) => (
                            <div key={i} className="card-features">
                                <img src={Vector} alt="feature-icon" />
                                <span className='card-features-text'>
                                    {feature}
                                </span>
                            </div>
                        ))}
                    </div>
                    {activeCards.includes(1) ? (
                        <div className='counter-container'>
                            <button className='decrement-btn' onClick={() => handleDecrement(1)}>-</button>
                            <span className='counter-value'>{counts[1]}</span>
                            <button className='INcrement-btn' onClick={() => handleIncrement(1)}>+</button>
                        </div>

                    ) : (
                        <div className='order-now-btn-container'>
                            {/* <button className='order-now-btn-BLUE' onClick={() => handleOrderNowClick(1)}>
                                Order Now
                            </button> */}
                               <button className='order-now-btn-BLUE' onClick={() => openModal()}>
                               Order Now
                            </button>
                        </div>
                    )}
                </div>

                <div className="card20" >
                    <div className="header-card-flex">
                        <div className='header-titles'>
                            <h6>TippyTap</h6>
                            <span>{ThirdItemName}</span>
                        </div>
                        {/* <div className="price-tag-float-right">
                            <img src={Pringtag} className="pricetag-img" alt="pricetag-img" />
                            <span className='price-tag-text'>{ThirdItemPrice}$</span>
                        </div> */}
                    </div>
                    <hr className="styled-line" />
                    <div className="card-body20">
                        {features3.map((feature, i) => (
                            <div key={i} className="card-features">
                                <img src={Vector} alt="feature-icon" />
                                <span className='card-features-text'>
                                    {feature}
                                </span>
                            </div>
                        ))}
                    </div>
                    {activeCards.includes(2) ? (
                        <div className='counter-container'>
                            <button className='decrement-btn' onClick={() => handleDecrement(2)}>-</button>
                            <span className='counter-value'>{counts[2]}</span>
                            <button className='INcrement-btn' onClick={() => handleIncrement(2)}>+</button>
                        </div>
                    ) : (
                        <div className='order-now-btn-container'>
                            {/* <button className='order-now-btn1' onClick={() => handleOrderNowClick(2)}>
                                Order Now
                            </button> */}
                               <button className='order-now-btn-BLUE' onClick={() => openModal()}>
                                 Order Now
                             </button>
                        </div>
                    )}
                </div>
            </div>

            {activeCards.length > 0 && (
                <div className='buy-btn-container'>
                    <button onClick={handleCheckoutClick} className='Buy-now-btn'>
                        Buy Now
                    </button>
                </div>
            )}

            {/* <div className='pricing-titles2'>
                <h6>TippyTap Business Card Packages Order <br /> (below prices are per card):</h6>
                <div className='flex-pricing'>
                    <p>[1-9] Cards: 300 AED</p>
                    <p>[10-19] Cards (-10%): 270 AED</p>
                    <p>[20-50] Cards (-15%): 255 AED</p>
                </div>


            </div> */}
              <ComingSoon
                isOpen={modalIsOpen}
                 closeModal={closeModal}
             />
        </div>
        
        </div>
    );
}
