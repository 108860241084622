import React, { useEffect, useState } from 'react'
import "../Style/Falcon_profile.css";
import Navbar from './Navbar';
import Footer from './Footer';
import falconleft from '/webapps/tippytap/src/Assets/WhatsApp Image 2024-07-17 at 13.33.47_cd778a71 1.png';
import line from '/webapps/tippytap/src/Assets/Line 8.png';
import detail1 from '/webapps/tippytap/src/Assets/Group 1960.png';
import detail2 from '/webapps/tippytap/src/Assets/Group 1982.png';
import detail3 from '/webapps/tippytap/src/Assets/Group 1959 (1).png';
import detail4 from '/webapps/tippytap/src/Assets/Group 1958.png';
import detail5 from '/webapps/tippytap/src/Assets/Group 1961.png';
import detail6 from '/webapps/tippytap/src/Assets/Group 1961 (1).png';
import detail7 from '/webapps/tippytap/src/Assets/Group 1964.png';
import axios from 'axios';
import Bluenav from "./Bluenav";
import text from '/webapps/tippytap/src/Assets/Group 2090.png';
// import html2canvas from 'html2canvas';
// import html2pdf from 'html2pdf.js';
// import jsPDF from 'jspdf';
import { useNavigate, useParams } from 'react-router';
// import falconeagle from '/webapps/tippytap/src/Assets/';
// import pdfMake from 'pdfmake/build/pdfmake';
// import pdfFonts from 'pdfmake/build/vfs_fonts';
 import NewBrownProfile from './Brown_profile_falcon';
// pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function Falcon_profile() {

    const { id } = useParams();
    const [data, setData] = useState({});
    const [img, setimg] = useState({});
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();
    // const handleDownload = async () => {

    //     axios.get(`https://apis.tippytap.ae/download_falcon_img/${id}`)
    //     .then((response) => {
    //       // Create a link element to trigger the download
    //       console.log(response)

    //     })
    //     .catch((error) => {
    //       console.error('Error downloading the image:', error);
    //     });



    //   };



    // const handleDownload = () => {
    //     const element = document.getElementById('pdf-content');

    //     const opt = {
    //         margin: 1,
    //         filename: 'falcon-profile.pdf',
    //         image: { type: 'jpeg', quality: 0.98 },
    //         html2canvas: { scale: 2 },
    //         jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    //     };

    //     html2pdf().from(element).set(opt).save();
    // };


    useEffect(() => {
        axios
            .get(`https://apis.tippytap.ae/get_url_view?value=${id}`)
            .then((res) => {
                const fetchedData = res.data.data[0];
                // console.log(res.data)
                setData(fetchedData)
            })
            .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
        // Check if user is logged in
        const loggedInEmail = sessionStorage.getItem('loggedInEmail');
        const contactID = sessionStorage.getItem('contactID');

        if (loggedInEmail && contactID) {
            setIsLoggedIn(true);
        } else {
            // If not logged in, redirect to signin page
            // But let the user see the page content without nav and blue
            setIsLoggedIn(false);
        }
    }, [navigate]);


    return (
        <div className='wrap-profile-falcon-card' >
            {isLoggedIn ? (
                <>
                    <Bluenav />
                    <Navbar />
                    <div className='container download-btn-container'>


                        {/* <button className='pdf-download-btn' onClick={handleDownload}>Download As PDF</button> */}
                    </div>

                    <div className='container d-flex justify-content-center'>

                        <img className='text-profile-img' src={text} />

                    </div>
                    <div className="container falcon-card " id="pdf-content">
                        <div class="Wrapper mb-3" >


                            <div class="left-card-slice">
                                <img src={data.image} class="img-fluid-falcon-left" alt="..." />
                            </div>
                            <div class="grid-layout-falcon">
                                <img src={line} className='line' />

                                <div className="flex-wrap-right">
                                    <div className="left-right-detail">
                                        <img src={detail2} className='img-details-1' />
                                        <div className="data-of-falcon-5">
                                            <h6>Falcon Name /   أسم الصقر :</h6>
                                            <p>{data.name}</p>
                                        </div>

                                    </div>

                                    <div className="left-right-detail">
                                        <img src={detail1} className='img-details-1' />
                                        <div className="data-of-falcon-5">
                                            <h6>Specie / الفصيلة :</h6>
                                            <p>{data.specie}</p>
                                        </div>

                                    </div>
                                    <div className="left-right-detail">
                                        <img src={detail3} className='img-details-1' />
                                        <div className="data-of-falcon-5">
                                            <h6>Production / الإنتاج :</h6>
                                            <p>{data.production}</p>
                                        </div>

                                    </div>
                                    <div className="left-right-detail">
                                        <img src={detail4} className='img-details-1' />
                                        <div className="data-of-falcon-5">
                                            <h6>Weight (grams) / الوزن :</h6>
                                            <p>{data.weight}</p>
                                        </div>

                                    </div>
                                    <div className="left-right-detail">
                                        <img src={detail5} className='img-details-1' />
                                        <div className="data-of-falcon-5">
                                            <h6>Height (cm) / الطول :</h6>
                                            <p>{data.height}</p>
                                        </div>

                                    </div>
                                    <div className="left-right-detail">
                                        <img src={detail6} className='img-details-1' />
                                        <div className="data-of-falcon-5">
                                            <h6>Width (cm) / العرض :</h6>
                                            <p>{data.width}</p>
                                        </div>

                                    </div>
                                    <div className="left-right-detail">
                                        <img src={detail7
                                        } className='img-details-1' />
                                        <div className="data-of-falcon-5">
                                            <h6>Id / الرقم التعريفي :</h6>
                                            <p>{data.falcon_id}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                    </>

                    ) : (
                        <NewBrownProfile />
                        )}
                    
                </div>
                
            )
}
