import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2';
import "../Style/Contactus.css";
import Navbar from "./Navbar";
import Bluenav from "./Bluenav";
import Footer from './Footer';

const ContactUS = () => {
    const navigate = useNavigate();

    const [userEmail, setUserEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [capVal, setCapVal] = useState(false);

    function onChange(value) {
        console.log("captcha value", value);
        setCapVal(true);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (userEmail && subject && message) {
            emailjs.send('service_vr0l69k', 'template_dkuysqp', { user_email: userEmail, subject, message }, 'aylimfiHbqBiUFAe4')
                .then((response) => {
                    setUserEmail('');
                    setSubject('');
                    setMessage('');
                    Swal.fire({
                        title: 'Thank you',
                        text: 'Email has been successfully sent',
                        icon: 'success',
                    }).then(() => {
                        setTimeout(() => {
                            navigate('/', { replace: true });
                            window.location.reload();
                        }, 7000);
                    });
                })
                .catch((error) => {
                    console.error('Error sending email:', error);
                    Swal.fire({
                        title: 'Error',
                        text: 'Error sending email. Please try again later.',
                        icon: 'error',
                    });
                });
        } else {
            Swal.fire({
                title: 'Error',
                text: 'Please fill in all fields.',
                icon: 'error',
            });
        }
    };

    return (
        <div id="root">
            <Bluenav />
            <Navbar />
            <section>
                <div className="section-header">
                    <div className="container">
                        <h2>Contact Us</h2>
                        <p>For inquiries, technical support, or general assistance, please don't hesitate to get in touch with us.
                            Our dedicated team will contact you back, we appreciate the opportunity to assist you.</p>
                    </div>
                </div>

                <div className="container">
                    <div className="contactrow row">
                        {/* <div className="contact-info">
                            <div className="contact-info-item">
                                <div className="contact-info-icon">
                                    <i style={{ color: "#808080" }} className="fas fa-home"></i>
                                </div>

                                <div className="contact-info-content">
                                    <h4>Address</h4>
                                    <p>MBAM0330 Compass building, Al Shohada Road, <br /> AL Hamra industrial Zone <br />- FZ, RAK, UAE</p>
                                </div>
                            </div>

                            <div className="contact-info-item">
                                <div className="contact-info-icon">
                                    <i style={{ color: "#808080" }} className="fas fa-envelope"></i>
                                </div>

                                <div className="contact-info-content">
                                    <h4>Email</h4>
                                    <p>info@gosmart.ae</p>
                                </div>
                            </div>
                        </div> */}

                        <div className="contact-form">
                            <form action="" id="contact-form" onSubmit={handleSubmit}>
                                <h2>Send Email</h2>
                                <div className="input-box">
                                    <input type="email" required value={userEmail} name="user_email" placeholder='Email' onChange={(e) => setUserEmail(e.target.value)} />
                                </div>

                                <div className="input-box">
                                    <input type="text" required value={subject} name="subject" placeholder='Subject' onChange={(e) => setSubject(e.target.value)} />
                                </div>

                                <div className="input-box">
                                    <textarea required value={message} name="message" placeholder='Body' onChange={(e) => setMessage(e.target.value)}></textarea>
                                </div>

                                <div className="recaptcha-container">
                                    <ReCAPTCHA
                                        sitekey="6LfaIiIqAAAAAOtahu5fA-gofl7H_FIrUFRL13yN" // Your site key here
                                        onChange={onChange}
                                    />
                                </div>

                                <div className="input-box">
                                    <input disabled={!capVal} required type="submit" value="Send" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default ContactUS;
