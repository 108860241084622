import React from "react";
import "/webapps/tippytap/src/Style/Welcomesec.css";
import tippycard from "/webapps/tippytap/src/Assets/Banner img@2x.png"
import purplerightimg from "/webapps/tippytap/src/Assets/Group 1834 (1).png"

export default function Welcomesec() {
  return (
    <div className="Welcomesec">
       <div className="flex-Welcomesec container  ">
        <div className="Right-text ">
          <h6>Fully Customized <br /> <span>Electronic Card</span> With Your Business Identity.</h6>
          <p>
          Our ‘’Tap&Go’’ Business Cards Are Designed To Make Networking Seamless <br /> And Efficient. With A Simple Tap.
          </p>

          {/* <div className="welcome-sec-btn">
            <button>Start For Teams</button>
          </div> */}
        </div>

         <div className="Left-img-welcome-sec">

        <img src ={tippycard}/>

        </div> 
      </div> 

         {/* <div className="flex-Welcomesec container">
        <div className="Right-text">
          <h6>Welcome to tippy tap</h6>
          <h5>
            1st busniess card with attachments” , fully customized electronic
            card with your business identity.
          </h5>
          <h4>
            loaded with your marketing materials & social media links save $10K+
            annually on reprints of catologes , brochures , menus &
            prequalifications , etc ...
          </h4>
          <p>
            our “tap&go” business cards are designed to make networking seamless
            and efficient with a simple tap , your contacts will be imported
            info your smartphones ’ directly . not only that , but with
            marketing material (ex. catalog .etc ...) just atap away.
          </p>

          <div className="welcome-sec-btn">
            <button>Start For Teams</button>
          </div>
        </div>

        <div className="Left-img-welcome-sec">

        <img src ={tippycard}/>

        </div>
      </div>  */}
    </div>
  );
}
