import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import eagle from "/webapps/tippytap/src/Assets/Group 1834 (3).png";
import "/webapps/tippytap/src/Style/Registration.css";
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import abodaabi from "/webapps/tippytap/src/Assets/Group 1972.png";
import axios from 'axios';
import { Link, Navigate } from "react-router-dom";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import Bluenav from "../Bluenav";
import { useToken } from "../TokenContext";
import tippycard from "/webapps/tippytap/src/Assets/Banner img@2x.png"

export default function Tippy_signin() {
  const navigate = useNavigate();
  const { setToken, setContactId } = useToken();
  const [showPassword, setShowPassword] = useState(false);
  const [values, setValues] = useState({ email: "", password: "" });
  const [error, setError] = useState("");
  
  useEffect(() => {
    // Check if the user is already logged in
    const contactID = sessionStorage.getItem('newContactID');
    const loginTime = Number(sessionStorage.getItem('loginTime')); // Ensure it's parsed as a number
    const SESSION_DURATION = 86400000; // 1 day in milliseconds

    if (contactID && loginTime) {
      const currentTime = new Date().getTime();
      if (currentTime - loginTime < SESSION_DURATION) {
        // If the session is still valid, redirect to profile
        navigate(`/profile`);
      }
    }
  }, [navigate]);


  const handleSignin = async (event) => {
    event.preventDefault();
    try {
      const SigninResponse = await axios.post(
        "https://apis.tippytap.ae/verify_Login_tap",
        { email: values.email, password: values.password }
      );
      const { contact_id: contactID, status, message } = SigninResponse.data;
      if (status === 1) {
        const generatedToken = btoa(contactID);

        // Set token and contactID in context
        setToken(generatedToken);
        console.log("Token set:", generatedToken);

        setContactId(contactID);
        console.log(contactID)
        // Store session details

        const loginTime = new Date().getTime();
        sessionStorage.setItem('loggedInEmail', values.email);
        sessionStorage.setItem('newContactID', contactID);
        sessionStorage.setItem('loginTime', loginTime); // Store loginTime

        console.log("Navigating to profile");

        navigate(`/profile`);
      } else {
        setError(message);
      }
    } catch (error) {
      console.error("Error during sign-in:", error);
      setError("An error occurred during sign-in. Please try again.");
    }
  };

  const handleShowPasswordToggle = (field) => {
    setShowPassword((prev) => ({
      ...prev,
      [field]: !prev[field]
    }));
  };

  const contactID = sessionStorage.getItem('newContactID');
  const loginTime = sessionStorage.getItem('loginTime');
  const SESSION_DURATION = 86400000; // 1 day in milliseconds
  const currentTime = new Date().getTime();

  if (contactID && loginTime && currentTime - loginTime < SESSION_DURATION) {
    return <Navigate to="/profile" />;
  }


  return (
    <div id="root">
      <Bluenav />
      <Navbar />
      <div className="flex-register container">
        <div className="left-register-img-sec">
          <div className="d-flex justify-content-center align-item-center eagle-cont2">
            <img src={tippycard} className="Purple-cards-img2" alt="Eagle" />
          </div>
          {/* <div className="d-flex justify-content-center align-item-center  abudhabi">
            <img src={abodaabi} className="eagle" alt="Abudhabi" />
          </div> */}
        </div>

        <form className="form-sign-in-1 mb-2" onSubmit={handleSignin}>
          <div className="form-title3 pt-2">
            <h6>Sign in</h6>
          </div>


          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Email address
            </label>
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              placeholder="Email"
              aria-describedby="emailHelp"
              onChange={(e) => setValues({ ...values, email: e.target.value })}
              required
            />
          </div>

   

          <div className="password-container mb-3">
            <label htmlFor="exampleInputPassword1" className="form-label">
              Password :
            </label>
            <input
              placeholder="Password"
              type={showPassword.password ? "text" : "password"} // Toggle input type
              className="form-control"
              id="exampleInputPassword1"
              onChange={(e) => setValues({ ...values, password: e.target.value })}
              required
            />
            <i
              className={`fas ${showPassword.password ? 'fa-eye-slash' : 'fa-eye'} show-password-icon`} // Toggle icon
              onClick={() => handleShowPasswordToggle('password')} // Toggle password visibility
            ></i>
          </div>
   
          {error && <div className="error-message">{error}</div>}
          <div className="mb-3 form-forgetpass">
            <h6>
              <Link to="/forgetpassword" style={{ textDecoration: "none" }} className="link">
                <span>Forgot Password ?</span>
              </Link>
            </h6>
          </div>
          <div className="register-btn-container mt-3">
            <button type="submit">Sign in</button>
            <small>Did not have an account?
              <Link to={"/Register"} style={{ textDecoration: "none" }}>
                <span> Sign Up now</span>
              </Link>
            </small>
          </div>
        </form>
      </div>
      <Footer />
      {/* Confirmation Modal */}
  
    </div>
  );
}
