// SuccessModal.js
import React, { useRef, useState } from 'react';
import Modal from 'react-modal';
import { Rnd } from 'react-rnd'; // Import Rnd for draggable and resizable QR code
import html2canvas from 'html2canvas'; // Import html2canvas for taking screenshot
import "/webapps/tippytap/src/Style/QRModal.css";
import QRICON from '/webapps/tippytap/src/Assets/Qr-code-sign-on-transparent-background-PNG.png';

const QRModal = ({ isOpen, onRequestClose, frontImage, onSave }) => {
    const [qrPosition, setQrPosition] = useState({ x: 0, y: 0 });
    const [qrSize, setQrSize] = useState({ width: 100, height: 100 }); // Initial size of QR code
    const [isConfirming, setIsConfirming] = useState(false);
    const containerRef = useRef(null);

    const handleQrPositionChange = (e, data) => {
        setQrPosition({ x: data.x, y: data.y });
    };

    const handleSave = () => {
        if (containerRef.current) {
            html2canvas(containerRef.current).then(canvas => {
                canvas.toBlob((blob) => {
                    if (blob) {
                        onSave(blob);
                    }
                });
            });
        }
        onRequestClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="QR Code Modal"
            className="qr-modal" // Add custom class here
            overlayClassName="qr-overlay" // Add custom overlay class here
        >
            <h2>Position The QR Code</h2>
            <button onClick={onRequestClose} className='close-btn2'>x</button>

            <div className="front-image-preview-container" ref={containerRef}>
                <img
                    src={frontImage ? URL.createObjectURL(frontImage) : ''}
                    alt="Front"
                    className="front-image-preview"
                />
                {frontImage && (
                    <Rnd
                        position={qrPosition}
                        size={qrSize}
                        onDragStop={(e, data) => setQrPosition({ x: data.x, y: data.y })}
                        onResizeStop={(e, direction, ref, delta, position) => {
                            setQrSize({
                                width: ref.style.width,
                                height: ref.style.height,
                            });
                            setQrPosition(position);
                        }}
                        bounds="parent"
                    >
                        <div className="qr-code-overlay" style={{ width: '100%', height: '100%' }}>
                            <img src={QRICON} alt="QR Code" className="qr-code-icon" style={{ width: '100%', height: '100%' }} />
                        </div>
                    </Rnd>
                )}
            </div>

            {isConfirming ? (
                <div className="confirmation-container">
                    <p className='mt-2' style={{color:"red",fontSize:"17px"}}>Are you sure you want to save this QR Image?</p>
                    <button className='mb-2 cancel-modal-QR-btn' onClick={handleSave}>Yes, Save</button>
                    <button className=' cancel-modal-QR-btn' onClick={() => setIsConfirming(false)}>Cancel</button>
                </div>
            ) : (
                <button className='mt-2 cancel-modal-QR-btn' onClick={() => setIsConfirming(true)}>Save</button>
            )}
        </Modal>
    );
};

export default QRModal;
