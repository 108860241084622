import React from "react";
import "../Style/Bluenav.css"
export default function Bluenav() {
  return (
    <div className="bluenav">
      <div className="blue-nav-paragraph container">
        <h6>TippyTap “1st Electronic Business Card with attachments”</h6>
      </div>
    </div>
  );
}
