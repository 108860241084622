import React from "react";
import "../Style/Footer.css";
import logo from "/webapps/tippytap/src/Assets/TippyTap Logo noBG.png";
import googleplay from "/webapps/tippytap/src/Assets/Image 8@2x 1.png";
import appstore from "/webapps/tippytap/src/Assets/Image 9@2x 1.png";
import frame from "/webapps/tippytap/src/Assets/Frame 2290.png";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from 'react-scroll';

export default function Footer() {
  return (
    <footer id="footer" className="text-dark pt-4">
      <div className="container">
        <div id="centerrow" className="row">
          <div className="col-12 col-md-6 mb-3 mb-md-0">
            <div>
            <img src={logo} className="tippytap-logo"  />
            <span className="span-for-logo-title">TippyTap</span>
            
            </div>
            <div className="logo-container-paragraph">
              <p className="logo-paragraph">
                Fully Customized
                Electronic Card With Your Business Identity.
                Our ‘’Tap&Go’’ Business Cards Are Designed To Make Networking Seamless
                And Efficient. With A Simple Tap.
              </p>
            </div>

            <div className="class-of-icons">
              <div>
                <img src={googleplay} />
              </div>
              <div>
                <img src={appstore} />
              </div>
            </div>
          </div>

          <div className="col-6 col-md-2 mb-3 mb-md-0">
            {/* <h5>Quick Links</h5> */}
            <ul className="list-unstyled">
              <li>
                <span className="text-white-2">
                  Quick Links
                </span>
              </li>
              <li>
                <Link to={"/"}>
                  <a className="text-white-3">
                    Home
                  </a>
                </Link>

              </li>
              <li>

                <ScrollLink
                  to="Pricing"
                  smooth={true}
                  duration={500}
                  style={{ textDecoration: "none" }}

                >
                  <a href="" className="text-white-3">
                    Products
                  </a>
                </ScrollLink>

              </li>


              <li>
                <Link to={"/adihex"}>
                  <a className="text-white-3">
                    Adihex
                  </a>
                </Link>
              </li>

              <li>
                <Link to={"/Contactus"}>
                  <a className="text-white-3">
                    Contact Us
                  </a>
                </Link>
              </li>
            </ul>
          </div>

          {/* Section 3 */}
          <div className="col-6 col-md-2 mb-3 mb-md-0">
            <ul className="list-unstyled">
              <li>
                <span className="text-white-2" style={{ color: "black" }}>
                  Privacy
                </span>
              </li>
              <li>
                <Link to={"/Privacy"}>
                  <a className="text-white-3">
                    Privacy Policy
                  </a>
                </Link>
              </li>
              <li>
                <a className="text-white-3">
                  Terms and Conditions
                </a>
              </li>
          
       
            </ul>
          </div>

          {/* Section 4 */}
          <div className="col-12 col-md-2 icons-sec">
            <img src={frame} style={{ width: "194px" }} />
          </div>
        </div>
      </div>
    </footer>
  );
}
