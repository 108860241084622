import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal";
import "../Style/AddFalconModal.css";
import greenverify from "/webapps/tippytap/src/Assets/verified.png";
import FalconImage from "./UploadFalconImage";
import AddFalconImage from "./Add_falcon_image";
import { Link, useParams } from 'react-router-dom';

Modal.setAppElement('#root'); // Set the app element for accessibility

export default function AddFalconModal({ isOpen, onClose, contactId, onSuccess }) {
    const initialFalconState = {
        name: '',
        specie: '',
        production: '',
        weight: '',
        height: '',
        width: '',
        falcon_id: '',
    };

    const [falconIdError, setFalconIdError] = useState("");
    const [newFalcon, setNewFalcon] = useState(initialFalconState);
    const [isSuccess, setIsSuccess] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null); // State for the selected file
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setNewFalcon(initialFalconState); // Reset form data when modal is opened
            setFalconIdError(""); // Clear error message
            setIsSuccess(false); // Reset success state
        }
    }, [isOpen]);

    // Handle changes in form input
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'falcon_id') {
            const formattedValue = formatFalconId(value);
            setNewFalcon(prevState => ({
                ...prevState,
                [name]: formattedValue
            }));

            if (!validateFalconId(formattedValue)) {
                setFalconIdError("Falcon ID must be a 16-character ");
            } else {
                setFalconIdError("");
            }
        } else {
            setNewFalcon(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    // Handle adding new falcon
    const handleAddFalcon = (event) => {
        event.preventDefault(); // Prevent default form submission
        if (isSubmitting) return; // Prevent multiple submissions
        setIsSubmitting(true); // Set submitting state to true

        // Check if all fields are filled
        if (!newFalcon.name || !newFalcon.specie || !newFalcon.production ||
            !newFalcon.weight || !newFalcon.height || !newFalcon.width ||
            !newFalcon.falcon_id) {
            setFalconIdError("All Fields Are Required.");
            return; // Prevent form submission if any required field is empty
        }

        // Check if a valid image file has been selected
        if (!selectedFile) {
            setFalconIdError("An image file is required.");
            return; // Prevent form submission if no file is selected
        }

        // Optional: Validate file type and size (e.g., check for image file type)
        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif']; // Allowed image types
        if (!allowedTypes.includes(selectedFile.type)) {
            setFalconIdError("Only JPEG, PNG, and GIF files are allowed.");
            return;
        }

        // Clear any previous error messages
        setFalconIdError("");

        if (!validateFalconId(newFalcon.falcon_id)) {
            setFalconIdError("Falcon ID must be a 16-character ");
            return; // Prevent form submission if falcon_id is invalid
        }

        const payload = {
            data: {
                contact_id: contactId, // Include contact_id inside data
                ...newFalcon // Spread falcon data
            },
            flag: true, // Adding the flag
        };

        axios.post("https://apis.tippytap.ae/create_new_falcon", payload)
            .then(response => {
                console.log('Falcon added successfully:', response.data);
                if (response.data.status === 1) {
                    // Prepare the FormData for the file upload
                    const formData = new FormData();
                    formData.append("file", selectedFile);
                    formData.append("object_id", response.data.id); // Assuming `object_id` is returned in response

                    axios.post("https://apis.tippytap.ae/upload_image_flacons", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                        .then(anotherResponse => {
                            console.log('Another API response:', anotherResponse.data);
                            setIsSubmitting(false); // Reset submitting state

                            // Success handling after both API calls
                            setIsSuccess(true); // Show success message
                            setNewFalcon(initialFalconState); // Reset form data
                            setSelectedFile(null); // Clear selected file
                            setTimeout(() => {
                                setIsSuccess(false); // Hide success message
                                onSuccess(); // Call the success callback to reload data
                                onClose(); // Close the modal
                            }, 2000); // 2 seconds timer for success message
                        })
                        .catch(anotherError => {
                            console.error('Error calling another API:', anotherError);
                        });
                }
            })
            .catch(error => {
                console.error('Error adding falcon:', error);
            });
    };



    const validateFalconId = (falconId) => {
        // Check if the length is 16 characters and meets one of the following criteria:
        // 1. All numbers
        // 2. All letters
        // 3. A mix of both
        const cleanId = falconId.replace(/-/g, ''); // Remove dashes for validation
        const isAllNumbers = /^\d+$/.test(cleanId);
        const isAllLetters = /^[a-zA-Z]+$/.test(cleanId);
        const hasLetter = /[a-zA-Z]/.test(cleanId);
        const hasDigit = /\d/.test(cleanId);

        return falconId.length === 19 && (isAllNumbers || isAllLetters || (hasLetter && hasDigit));
    };

    const formatFalconId = (value) => {
        // Remove existing dashes
        value = value.replace(/-/g, '');

        // Format to 4-digit-4-digit-4-digit-4-digit
        if (value.length > 16) value = value.slice(0, 16);

        const parts = [];
        for (let i = 0; i < value.length; i += 4) {
            parts.push(value.slice(i, i + 4));
        }

        return parts.join('-');
    };

    const formData = new FormData();
    formData.append("name", newFalcon.name);
    formData.append("specie", newFalcon.specie);
    formData.append("production", newFalcon.production);
    formData.append("weight", newFalcon.weight);
    formData.append("height", newFalcon.height);
    formData.append("width", newFalcon.width);
    formData.append("falcon_id", newFalcon.falcon_id);
    formData.append("contact_id", contactId);

    if (selectedFile) {
        formData.append("file", selectedFile);
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Add Falcon"
            className="AddModal"
            overlayClassName="Overlay2"
        >
            {isSuccess ? (
                <div className="success-message">
                    <div className="icon-header-popup-model">
                        <img src={greenverify} alt="Verification" />
                    </div>
                    <p>The falcon was added successfully!</p>
                </div>
            ) : (
                <>
                    <h2>Add New Falcon</h2>
                    <form>
                        <div>
                            <label className="mt-2">Falcon Name / أسم الصقر :</label>
                            <input
                                type="text"
                                name="name"
                                value={newFalcon.name}
                                onChange={handleInputChange}
                                className="form-control mt-2"
                                required
                            />
                        </div>
                        <div>
                            <label className="mt-2">Specie / الفصيلة :</label>
                            <input
                                type="text"
                                name="specie"
                                value={newFalcon.specie}
                                onChange={handleInputChange}
                                className="form-control mt-2"
                                required
                            />
                        </div>
                        <div>
                            <label className="mt-2">Production / الإنتاج :</label>
                            <input
                                type="text"
                                name="production"
                                value={newFalcon.production}
                                onChange={handleInputChange}
                                className="form-control mt-2"
                                required
                            />
                        </div>
                        <div>
                            <label className="mt-2">Weight / الوزن (g) :</label>
                            <input
                                type="number"
                                name="weight"
                                value={newFalcon.weight}
                                onChange={handleInputChange}
                                className="form-control mt-2"
                                required
                            />
                        </div>
                        <div>
                            <label className="mt-2">Height / الطول (cm) :</label>
                            <input
                                type="number"
                                name="height"
                                value={newFalcon.height}
                                onChange={handleInputChange}
                                className="form-control mt-2"
                                required
                            />
                        </div>
                        <div>
                            <label className="mt-2">Width / العرض  (cm) :</label>
                            <input
                                type="number"
                                name="width"
                                value={newFalcon.width}
                                onChange={handleInputChange}
                                className="form-control mt-2"
                                required
                            />
                        </div>
                        <div>
                            <label className="mt-3">Falcon ID / الرقم التعريفي :</label>
                            <input
                                type="text"
                                name="falcon_id"
                                value={newFalcon.falcon_id}
                                onChange={handleInputChange}
                                className="form-control mt-2 "
                                maxLength={19} // Max length considering dashes
                                required
                            />

                        </div>

                        <div class="">
                            <label className="mt-2 mb-2" >Falcon Image</label>
                            <AddFalconImage
                                isEditing={true}
                                image={null}
                                onFileChange={(e) => setSelectedFile(e.target.files[0])}
                            />

                            {falconIdError && (
                                <div className="error-message" style={{ marginTop: '10px' }}>
                                    {falconIdError}
                                </div>
                            )}              </div>
                        <div className="model-btns-sec2">
                            <button type="submit" className="add-falcon" onClick={handleAddFalcon} disabled={isSubmitting}>
                                {isSubmitting ? 'Adding Falcon...' : 'Add Falcon'}
                            </button>                            <button type="button" className="add-falcon" onClick={onClose}>Close</button>
                        </div>
                    </form>
                </>
            )}
        </Modal>
    );
}
