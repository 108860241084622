import React from 'react'
import Navbar from './Navbar'
import Bluenav from './Bluenav'
import "../Style/Privacy.css";
import Footer from './Footer';

export default function Privacy() {
    return (
        <>
            <Bluenav />
            <Navbar />
            <div className='container pravicy-wrapper'>
                <div className='Section-1'>
                    <h6 className='header-privacy'>Privacy Policy</h6>
                    <small className='small-content'>Last updated: 11/9/2022</small>
                    <p className='text-privacy'>In TippyTap we operate TippyTap . This page informs you of our policies regarding the collection, use and disclosure of Personal Information we receive from users of the Site. We use your Personal Information only for providing and improving the Site. By using the Site, you agree to the collection and use of information in accordance with this policy.</p>
                </div>

                <div className='Section-2'>
                    <h6 className='header-privacy'>INFORMATION COLLECTION AND USE</h6>
                    <p className='text-privacy'>While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to your name "Personal Information".</p>
                    <p className='text-privacy'>A-Log Data</p>
                    <p className='text-privacy'>Like many site operators, we collect information that your browser sends whenever you visit our Site "Log Data". This Log Data may include information such as; -your computer's Internet Protocol "IP" address, -browser type and version, -the pages of our Site that you visit, -the time and date of your visit, -the time spent on those pages and other statistics, -location data , -transaction information, -usage data, -device data, -communication data.</p>
                    <p className='text-privacy'>B-In addition</p>
                    <p className='text-privacy'>we may use third party services such as; -Google Analytics that collect, monitor and analyze this data, -Users participating in our referral programs, -marketing service providers or data resellers, -vendors who help us verify users,identity, background information, -and eligibility to work or who screen users in connection with sanctions, anti-money laundering, or know-your customer requirements.</p>
                    <p className='text-privacy'>The Log Data section is for businesses that use analytics or tracking services in websites or apps, like Google Analytics.</p>
                    <p className='text-privacy'>We may use your Personal Information to -contact you with newsletters, -to send marketing and non-marketing communications, -for research and development, -for customer support, -to enhance the safety and secruity of our users and services. -in connection with legal proceedings.</p>
                    <p className='text-privacy'>The Communications section is for businesses that may contact users via email (info@TippyTap) or other methods.</p>
                </div>

                <div className='Section-3'>
                    <h6 className='header-privacy'>DELETION OF THE DATA</h6>
                    <p className='text-privacy'>You can delete all your data anytime from the mobile application in your Profile Settings.</p>
                </div>


                <div className='Section-4'>
                    <h6 className='header-privacy'>COOKIES</h6>
                    <p className='text-privacy'>Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer's hard drive. Like many sites, we use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Site.</p>
                </div>

                <div className='Section-5'>
                    <h6 className='header-privacy'>SECUIRTY</h6>
                    <p className='text-privacy'>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>
                </div>

                <div className='Section-6'>
                    <h6 className='header-privacy'>CHANGES TO PRIVACY POILCY</h6>
                    <p className='text-privacy'>This Privacy Policy is effective as of (12/9/2022) and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.</p>
                    <p className='text-privacy'>We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically.</p>
                    <p className='text-privacy'>Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.</p>
                    <p className='text-privacy'>If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided us, or by placing a prominent notice on our website.</p>


                    <span className='end'>CONTACT US please contact us If you have any questions about this Privacy Policy.
                </span>
                </div>


                
            </div>

            <Footer />

        </>
    )
}
