import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import "/webapps/tippytap/src/Style/Checkout.css";
import Bluenav from "../Bluenav";
import tippycard from "/webapps/tippytap/src/Assets/Group 2251.png";
import blueline from "/webapps/tippytap/src/Assets/Line 21.png";
import trueicon from "/webapps/tippytap/src/Assets/Group 291.png";
import axios from "axios";
import { useToken } from '../TokenContext';

export default function Checkout() {
  const { token } = useToken();
  const contactID = token ? atob(token) : null; // Decode the token to get contactID
  const [cards, setCards] = useState([]);
  const [Total, SetTotal] = useState([]);
  const [loading, setLoading] = useState({}); // To track loading state for each card
  const [OrderID, SetOrderID] = useState([]);
  const [discount, setDiscount] = useState(null);

  const handleCheckout = async () => {
    try {
      const response = await axios.get(`https://apis.tippytap.ae/create_session_stripe_tap/${OrderID}`);
      console.log(response.data)
      const URL = response.data.url;
      const SessionID = response.data.session_id
      console.log(URL )
      console.log(SessionID )
      window.location.href= URL ;
    } catch  {
      console.error('Error creating checkout session:');
    }
  };


 
  const fetchData = async () => {
    try {
      const response = await axios.get(`https://apis.tippytap.ae/view_draft_order/${contactID}`);
      console.log('view_draft_order:', response.data.result);
      const totalprice = response.data.total
      const order_id = response.data.order_id
      const clearing = response.data.result[0]
      const discount = clearing.discount;
      setDiscount(discount)
      SetOrderID(order_id)
      setCards(response.data.result); // Assuming response.data.result is an array of card data
      console.log(order_id)
      SetTotal(totalprice)
    } catch (error) {
      console.error('There was an error with the purchase!', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [contactID]);

  const incrementQuantity = async (productId) => {
    try {
      const response = await axios.get(`https://apis.tippytap.ae/increment_unite/${productId}`);
      console.log('Increment response:', response.data);
      // Fetch the updated card data after incrementing
      fetchData();
    } catch (error) {
      console.error('Error incrementing quantity:', error);
    }
  };
  
  const decrementQuantity = async (productId, currentQuantity) => {
    if (currentQuantity > 1) {
      try {
        const response = await axios.get(`https://apis.tippytap.ae/decrement_unite/${productId}`);
        console.log('Decrement response:', response.data);
        fetchData(); // Fetch the updated card data after decrementing
      } catch (error) {
        console.error('Error decrementing quantity:', error);
      }
    } else {
      console.log('Quantity cannot be less than 1');
    }
  };
  
  

  
  return (
    <>
      <Bluenav />
      <Navbar />
      <div id="root">
      <h6 className="checkout-header container">Checkout</h6>
        <div className="Checkout-container container">
          <div className="flex-checkout-layout">
          <div className="card-order">
          {cards.map(card => (
              <div key={card.id} className="checkoutcard">
                <div className="btn-relative">
                  {/* <button className="close-btn">x</button> */}
                </div>
                <div className="checkout-img-container">
                  <img src={tippycard} className="Ecard-img" alt="Card" />
                </div>
                <div className="tippy-card-details">
                  <h6>{card.name}</h6> {/* Adjust field names based on your data */}
                  <span>{card.code}</span> {/* Adjust field names based on your data */}
                  <span>Price : {card.price_unit + ' AED'}</span> 
                  <span>Total Price : {card.price_subtotal + ' AED'}</span> 
                  <span>Theme Availability : 3</span> {/* Adjust field names based on your data */}
                  <div className="btns-quantity ">
                    {/* <span>${card.price_subtotal - card.price_unit}</span> Adjust field names based on your data */}
                    <button  onClick={() => decrementQuantity(card.id, card.product_uom_qty)} className="decrement-quantity">-</button>
                    <p>{card.product_uom_qty}</p> {/* Adjust field names based on your data */}
                    <button  onClick={() => incrementQuantity(card.id)} className="increment-quantity">+</button>
                    {/* <span>${card.price_subtotal + card.price_unit}</span> Adjust field names based on your data */}
                  </div>
                </div>
              </div>
            ))}
            </div>
            <div className="checkout-process">
              <div className="line-blue">
                <img src={blueline} />
              </div>
              <h6>Order Summary</h6>

              <div className="order-details">
                {/* <div class="detail">
                  <span class="label">subtotal (incl . tax )</span>
                  <span class="value">$123.45</span>
                </div>
                <div class="detail">
                  <span class="label">Shipping & Handling</span>
                  <span class="value">$123.45</span>
                </div> */}
                <div class="detail">
                  <span class="label">Business Card</span>
                  <span class="value">{Total + ' AED'}</span>
                </div>

                <div class="detail">
                  <span class="label">Discount</span>
                  <span class="value">        {discount !== null ? `${discount} %` : '0%'}
</span>
                </div>
                <div class="detail">
                  <span class="label">TOTAL</span>
                  <span class="value">{Total + ' AED'}</span>
                </div>
                <p>Shipping & taxes calculated at checkout</p>
                <div className="doyouagree">
                    <img src={trueicon} className="trueicon-agree" />
                    <span>i agree to Terms and conditions</span>
                </div>
                    <button onClick={handleCheckout} className="checkout-btn">Checkout</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
