import React, { useEffect, useState } from 'react';
import "/webapps/tippytap/src/Style/NewCardDetails.css";
import Modal from "react-modal";
import FillBusinessCardData from './PersonalDataSection';
import axios from 'axios';
import QRModal from './QRModal';
import Draggable from 'react-draggable'; // Import Draggable for QR code positioning
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const fonts = [
    'Arial', 'Verdana', 'Times New Roman', 'Georgia', 'Courier New',
    'Arial Black', 'Comic Sans MS', 'Impact', 'Lucida Console', 'Tahoma',
    'Trebuchet MS', 'Palatino Linotype', 'Garamond', 'Century Gothic', 'Bookman',
    'Comic Sans MS', 'Helvetica', 'Futura', 'Optima', 'Lucida Sans'
];
const NewCardDetails = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const [isSaveSuccessful, setIsSaveSuccessful] = useState(false);
    const [inputs, setInputs] = useState([{
        label: '',
        font: '',
        size: '',
        value: ''
    }]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredFonts, setFilteredFonts] = useState(fonts);
    const [files, setFiles] = useState({
        frontImage: null,
        backImage: null,
        logoImage: null,
        orignalImage: null,

    });
    const [isOpen, setIsOpen] = useState(false);
    const [showNewComponent, setShowNewComponent] = useState(false);
    const [errors, setErrors] = useState({});
    const [uploadModalOpen, setUploadModalOpen] = useState(false); // New state for upload modal
    const [qrPosition, setQrPosition] = useState({ x: 0, y: 0 }); // State for QR code position
    const { order_id } = useParams();
    const location = useLocation();
    const { state } = location;
    const navigate = useNavigate();


    const handleSearch = (e) => {
        const searchValue = e.target.value;
        setSearchTerm(searchValue);
        setFilteredFonts(
            fonts.filter(font => font.toLowerCase().includes(searchValue.toLowerCase()))
        );
    };

        
    // Function to handle adding a new input
    const addNewInput = () => {
        setInputs([...inputs, { label: '', font: '', size: '', value: '' }]);
    };

    // Function to handle removing an input
    const removeInput = (index) => {
        setInputs(inputs.filter((_, i) => i !== index));
    };


    // Function to handle changes in input fields
    const handleInputChange = (index, e) => {
        const { name, value } = e.target;
        const updatedInputs = inputs.map((input, i) =>
            i === index ? { ...input, [name]: value } : input
        );
        setInputs(updatedInputs);
    };

    const openModal = () => {
        setErrorMessage(''); // Clear any previous error messages
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    // Handle modal confirm
    const handleModalConfirm = () => {
        setUploadModalOpen(false);
        // Additional actions if needed
    };


    const handleQRSave = (blob) => {
        setFiles(prevFiles => ({
            ...prevFiles,
            frontImage: blob
        }));
    };

    useEffect(() => {
        // Log the values to check if they are passed correctly
        console.log('Product Quantity:', state?.productUomQty);
        console.log('Parent ID:', 1);
        // console.log('Main Card ID:', state?.MaincardID);
      }, [state]);
      
    

      const handleSave = async () => {
        const newErrors = {};
        setIsSaveSuccessful(false); // Reset success state before the request
        setErrorMessage('');
    
        try {
            // Validate and append images
            const formData = new FormData();
            formData.append('order_id', order_id);
            formData.append('product_id', 1);
            formData.append('quantity', state?.productUomQty);
    
            // Check if required files exist
            if (!files.frontImage) return setErrorMessage('Front QR image is required');
            formData.append('front_qr', files.frontImage);
    
            if (!files.backImage) return setErrorMessage('Back image is required');
            formData.append('back', files.backImage);
    
            if (!files.logoImage) return setErrorMessage('Logo image is required');
            formData.append('logo', files.logoImage);
    
            if (!files.orignalImage) return setErrorMessage('Original image is required');
            formData.append('front', files.orignalImage);
    
            console.log('Quantity:', state?.productUomQty);
            console.log('Inputs:', inputs);
    
            // Step 1: First API call - Upload card images
            const firstApiResponse = await axios.post('https://apis.tippytap.ae/set_card_images/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
    
            console.log('First API Response:', firstApiResponse.data);
            if (firstApiResponse.data.status !== 1) {
                return setErrorMessage(`Server Error: ${firstApiResponse.data.error}`);
            }
    
            console.log('Status is 1, proceeding to second API...');
    
            // Prepare design labels for the second API call
            const designLabels = inputs.map(input => ({
                label: input.label,
                value: input.value,
                font_size: input.size,
                font_family: input.font
            }));
            console.log('Design Labels:', designLabels);
    
            // Step 2: Second API call - Set card design info
            const secondApiResponse = await axios.post('https://apis.tippytap.ae/set_cards_design_info/', {
                main_card_id: firstApiResponse.data.main_card_id,
                design_labels: designLabels
            });
    
            console.log('Second API Response Status:', secondApiResponse.status);
            console.log('Second API Response Data:', secondApiResponse.data);
    
            // Step 3: Third API call - Order validation
            const thirdApiResponse = await axios.get(`https://apis.tippytap.ae/order_validation/${order_id}`);
    
            console.log('Third API Response Status:', thirdApiResponse.status);
            console.log('Third API Response Data:', thirdApiResponse.data);
    
            if (thirdApiResponse.data.status_code === 1) {
                // Navigate to the next page upon success
                setIsSaveSuccessful(true); // Set success state
                closeModal(); // Close the modal
                navigate(`/profile/businesscard/carddata/${order_id}`);
            } else {
                setErrorMessage('Order validation failed.');
            }
        } catch (error) {
            // Handle errors and set appropriate error messages
            console.error('Error saving card data:', error.response ? error.response.data : error.message);
            setErrorMessage(`Error saving card data: ${error.message}`);
        }
    };
    
    
    const handleFileChange = (e) => {
        const { id, files } = e.target;

        // Update the file state
        setFiles(prevFiles => ({
            ...prevFiles,
            [id]: files[0]
        }));

        // Open the upload modal only if the uploaded file is for the front image
        if (id === 'frontImage') {
            setUploadModalOpen(true);
        }
    };
    if (showNewComponent) {
        return <FillBusinessCardData />;
    }

    return (
        <div className="Business-content">
            <>
                <div className="BussinessCard-container-float">
                    <h6>Card Design</h6>

                    <div className="order-btn-container">
                        <button className="order-now-btn" onClick={openModal}>Save</button>
                    </div>
                </div>
                <div className='BussinessCard-container'>
                    <div className='upload-images-container'>
                        <div className='upload-img-1'>
                            <span>Upload A Front Image</span>
                            <div className="mb-3">
                                <input className="form-control" type="file" id="orignalImage" onChange={handleFileChange} />
                            </div>
                        </div>
                        <div className='upload-img-1'>
                            <span>Upload A Back Image</span>
                            <div className="mb-3">
                                <input className="form-control" type="file" id="backImage" onChange={handleFileChange} />
                            </div>
                        </div>
                        <div className='upload-img-1'>
                            <span>Upload A Logo Image</span>
                            <div className="mb-3">
                                <input className="form-control" type="file" id="logoImage" onChange={handleFileChange} />
                            </div>
                        </div>

                        <div className='upload-img-1'>
                            <span>Upload Image With QR Code</span>
                            <div className="mb-3">
                                <input className="form-control" type="file" id="frontImage" onChange={handleFileChange} />
                            </div>
                        </div>
                    </div>

                    <div className='Basic-card-Data'>
                        <h6>Please Fill The Basic Card Data</h6>
                        <div className="table-container">
                        <table className="table-form">
                            <thead>
                                <tr>
                                    <th>Label</th>
                                    <th>Value</th>
                                    <th>Font</th>
                                    <th>Size</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {inputs.map((input, index) => (
                                    <tr key={index}>
                                        <td>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="label"
                                                value={input.label}
                                                onChange={(e) => handleInputChange(index, e)}
                                                placeholder='Label'
                                                required
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="value"
                                                value={input.value}
                                                onChange={(e) => handleInputChange(index, e)}
                                                placeholder='Value'
                                                required
                                            />
                                        </td>

                                        <td>
                                            <div className="dropdown">
                                                <button
                                                    className="btn dropdown-btn-001 dropdown-toggle"
                                                    type="button"
                                                    id={`dropdownMenuButton1-${index}`}
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    {input.font || 'Font'}
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby={`dropdownMenuButton1-${index}`}>
                                                    <div className="px-2 py-1">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search fonts..."
                                                            value={searchTerm}
                                                            onChange={handleSearch}
                                                            required
                                                        />
                                                    </div>
                                                    {filteredFonts.map((font, idx) => (
                                                        <li key={idx}>
                                                            <a
                                                                className="dropdown-item"
                                                                
                                                                onClick={() => handleInputChange(index, { target: { name: 'font', value: font } })}
                                                            >
                                                                {font}
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name="size"
                                                value={input.size}
                                                onChange={(e) => handleInputChange(index, e)}
                                                placeholder='Size'
                                                required
                                            />
                                        </td>
                                        <td>
                                            <button className="Remove-new-card-labels" onClick={() => removeInput(index)}>Remove</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        </div>

                        <div>
                            <button className='Add-new-card-btn' onClick={addNewInput}>Add New</button>
                        </div>
                    </div>






                </div>
                <Modal
                    isOpen={isOpen}
                    onRequestClose={closeModal}
                    contentLabel="Confirm Save"
                    className="Modal"
                    overlayClassName="Overlay"
                >
                    <h2>Are you sure you want to Save this Card Design?</h2>

                    {errorMessage && <p style={{ color: "red", fontSize: "12px" }} className="error-message">{errorMessage}</p>}

                    <div className="model-btns-sec">
                        <button className="cancel-modal-btn" onClick={handleSave}>Yes</button>
                        <button className="cancel-modal-btn" onClick={closeModal}>No</button>
                    </div>
                </Modal>

                <QRModal
                    isOpen={uploadModalOpen}
                    onRequestClose={() => setUploadModalOpen(false)}
                    frontImage={files.frontImage}
                    onSave={handleQRSave}

                />
            </>

        </div>
    );
};

export default NewCardDetails;
