import React, { useEffect, useState } from "react";
import axios from "axios";
import "/webapps/tippytap/src/Style/FillDataForm.css";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import adihex from '/webapps/tippytap/src/Assets/e43bc4819fa138de4c2578a7c554e497.png'
import adihex2 from '/webapps/tippytap/src/Assets/Group 2357.png'

export default function FillDataForm({ onSuccess }) {
    const initialFalconState = {
        specie: '',
        production: '',
        weight: '',
        height: '',
        width: '',
        falcon_id: '',
        country_of_origin: '',
        falcon_age: '',
    };

    const navigate = useNavigate();  // Initialize useNavigate
    const [falconData, setFalconData] = useState(initialFalconState);
    const [falconIdError, setFalconIdError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null); // State for the selected file
    const { id } = useParams(); // Extract the falcon ID from the URL
    const [FalconID, setFalconID] = useState(); // State for the selected file

    useEffect(() => {
        axios
            .get(`https://apis.tippytap.ae/get_falcon_by_encrypted/${id}`)
            .then((res) => {
                const falcon = res.data.data[0]['id'];
                setFalconID(falcon)
            })
            .catch((err) => console.log(err));
    }, [id]);


    // Handle changes in form input
    const handleInputChange = (e) => {
        const { name, value } = e.target;
    
        // Update falconData state for all fields without special logic for falcon_id
        setFalconData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    

    
    
    
    const handleUpdateFalcon = (event) => {
        event.preventDefault();
        
        // Clear any previous error
        setFalconIdError("");
    
        // Set submitting state
        // setIsSubmitting(true); // Uncomment if you use isSubmitting
    
        console.log("Step 1: Sending falcon data to API...");
    
        // Step 1: Update Falcon Data (First API)
        axios.post("https://apis.tippytap.ae/Update_record", {
            object_id: FalconID,  // Falcon ID from the GET request
            table_name: "adihex.contacts.falcons",
            data: {
                falcon_id: falconData.falcon_id,
                specie: falconData.specie,
                production: falconData.production,
                weight: falconData.weight,
                height: falconData.height,
                width: falconData.width,
                country_of_origin: falconData.country_of_origin,
                falcon_age: falconData.falcon_age,
            },
        })
        .then(response => {
            console.log('Api 1 RES', response);
            if (response.data.status === 1) {
                console.log("Step 1 Complete: Falcon data successfully updated.");
    
                if (selectedFile) {
                    // Step 2: Upload Falcon Image (Second API)
                    const formData = new FormData();
                    formData.append("file", selectedFile);
                    formData.append("object_id", FalconID);  // Attach the Falcon ID
    
                    console.log("Step 2: Uploading image...");
    
                    axios.post("https://apis.tippytap.ae/upload_image_flacons", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then(() => {
                        console.log("Step 2 Complete: Image successfully uploaded.");
                        window.location.href = `https://tippytap.ae/adihex/falcon_profile/${id}`;
    
                        // Step 3: Perform Additional API Call (Third API) if needed
                        console.log("Step 3: Performing additional API call...");
    
                    })
                    .catch(err => {
                        console.log('Image Upload Error:', err.response?.data || err.message);
                        setIsSubmitting(false);
                    });
                } else {
                    // No image file selected, skip the image upload step
                    console.log("No image file selected. Skipping image upload.");
                    window.location.href = `https://tippytap.ae/adihex/falcon_profile/${id}`;
                    
                    // Step 3: Perform Additional API Call (Third API) if needed
                    console.log("Step 3: Performing additional API call...");
                }
            } else {
                console.log('API response error during Step 1:', response.data.message);
                setIsSubmitting(false);
            }
        })
        .catch(err => {
            console.log('Error during Step 1:', err.response?.data || err.message);
            setIsSubmitting(false);
        });
    };
    
    
    
    

    


    return (
        <div className="page-container ">
            <div className="Fill-data-container container">
            <div className="Logos-grid">
                <div className='logo-adihex-1'><img src={adihex} /></div>
                <div className='logo-adihex-2'><img src={adihex2} /></div>

            </div>
                <h2>Insert Falcon Data</h2>
                <form onSubmit={handleUpdateFalcon}>
                    {/* Input fields for falcon data */}
                    <div className="input-Field-orders">
                        <label>Ring / رقم الحجل </label>
                        <input
                            type="text"
                            name="falcon_id"
                            value={falconData.falcon_id}
                            onChange={handleInputChange}
                            className="form-control mt-2"
                            required
                        />
                    </div>

                    <div className="input-Field-orders">
                        <label>Type / نوع الصقر </label>
                        <input
                            type="text"
                            name="specie"
                            value={falconData.specie}
                            onChange={handleInputChange}
                            className="form-control mt-2"
                            required
                        />
                    </div>
                      <div className="input-Field-orders">
                        <label>Age / عمر الصقر </label>
                        <input
                            type="text"
                            name="falcon_age"
                            value={falconData.falcon_age}
                            onChange={handleInputChange}
                            className="form-control mt-2"
                            required
                        />
                    </div> 
                    <div className="input-Field-orders">
                        <label> Height (inch) / طول الصقر (انش) </label>
                        <input
                            type="number"
                            name="height"
                            value={falconData.height}
                            onChange={handleInputChange}
                            className="form-control mt-2"
                            required
                        />
                    </div>
                    <div className="input-Field-orders">
                        <label>Width (inch) / عرض الصقر (انش) </label>
                        <input
                            type="number"
                            name="width"
                            value={falconData.width}
                            onChange={handleInputChange}
                            className="form-control mt-2"
                            required
                        />
                    </div>
                    <div className="input-Field-orders">
                        <label>Weight (g) / وزن الصقر (غرام) </label>
                        <input
                            type="number"
                            name="weight"
                            value={falconData.weight}
                            onChange={handleInputChange}
                            className="form-control mt-2"
                            required
                        />
                    </div>
                    <div className="input-Field-orders">
                        <label>Farm /  المزرعة </label>
                        <input
                            type="text"
                            name="production"
                            value={falconData.production}
                            onChange={handleInputChange}
                            className="form-control mt-2"
                            required
                        />
                    </div>
                    <div className="input-Field-orders">
                        <label>بلد المنشأ / Country of Origin </label>
                        <input
                            type="text"
                            name="country_of_origin"
                            value={falconData.country_of_origin}
                            onChange={handleInputChange}
                            className="form-control mt-2"
                            required
                        />
                    </div> 

                    <div className="input-Field-orders">
                        <label>Falcon Image</label>
                        <input
                            type="file"
                            onChange={(e) => setSelectedFile(e.target.files[0])}
                            className="form-control mt-2"
                            // required
                        />
                    </div> 

                    {falconIdError && (
                        <div className="error-message">{falconIdError}</div>
                    )}
                    <button type="submit" className="Update-falcon-btn mt-3" disabled={isSubmitting}>
                        {isSubmitting ? "Updating Falcon..." : "Submit"}
                    </button>
                </form>
            </div>
        </div>
    );
}
