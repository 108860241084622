import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "../Style/ForgetPassword.css";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Bluenav from "./Bluenav";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";

export default function ForgetPassowrd() {
    const navigate = useNavigate(); // Replace useHistory with useNavigate
    const [values, setValues] = useState({
        email: "",
    });
    const [error, setError] = useState("");
    const [passMess, SetpassMess] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        let timer;
        if (isOpen) {
          timer = setTimeout(() => {
            closeModal(); // Close the modal and navigate to /signin after 3 seconds
          }, 3000); // Time in milliseconds (e.g., 3000ms = 3 seconds)
        }
        return () => clearTimeout(timer); // Cleanup timer on component unmount
      }, [isOpen, navigate]);


  const closeModal = () => {
    setIsOpen(false);
    navigate("/adihex/signin"); // Navigate to /signin when the modal closes
  };

    const HandleForget = async (event) => {
        event.preventDefault(); // Prevent default form submission
        setError(""); // Clear the previous error message

        try {
            const SigninResponse = await axios.get(`https://apis.tippytap.ae/forget_password/${values.email}`);
            console.log(SigninResponse.data);
            
            // Assuming the API sends a success message or status in case of successful request
            if (SigninResponse.data.status === 1) {
                // Handle successful response if needed
                setIsOpen(true)
                
            } else {
                // Handle non-error response but with a specific message
                setError("Invalid Email");
            }
        } catch (error) {
            // Handle errors during the API request
            console.error("Error sending email:", error);
            setError("An error occurred while sending the email. Please try again.");
        }
    };
    return (
        <div id="root">
        <Bluenav />
        <Navbar />
            <div>
                <form className="container forgetform">
                    <div className="form-title2">
                        <h6>Forget Password</h6>
                    </div>
                    <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                            Email :
                        </label>
                        <input
                            type="email"
                            class="form-control"
                            placeholder="Email"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            onChange={(e) => setValues({ ...values, email: e.target.value })}
                            required
                        />
                        
                    </div>

                    {error && <div className="error-message">{error}</div>}

                    {passMess && <div className="error-message">{passMess}</div>}

                    <div className="form-btns-sec pt-4">
                        <button className="signin-btn" onClick={HandleForget}>
                            Send Email
                        </button>
                    </div>
                </form>
            </div>
            <Footer />

            <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Registration Confirmation"
        className="Modal"
        overlayClassName="Overlay"
      >
        <h2>"Please check your email for instructions to reset your password.</h2>
        <div className="model-btns-sec">
          {/* Additional buttons or content can be added here */}
        </div>
      </Modal>
        </div>
    );
}
