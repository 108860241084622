import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "../Style/Signin.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useToken } from "./TokenContext";
import Bluenav from "./Bluenav";

export default function Signin() {
  const navigate = useNavigate();
  const { setToken, setContactId } = useToken();
  const [showPassword, setShowPassword] = useState(false);
  const [values, setValues] = useState({ email: "", password: "" });
  const [error, setError] = useState("");

  

  const handleSignin = async (event) => {
    event.preventDefault();
    try {
      const SigninResponse = await axios.post(
        "https://apis.tippytap.ae/verify_Login",
        { email: values.email, password: values.password }
      );
      const { status, message } = SigninResponse.data;

      if (status === 1) {
        // Store session details
        // const loginTime = new Date().getTime();
        // sessionStorage.setItem('loggedInEmail', values.email);
        // sessionStorage.setItem('loginTime', loginTime);
  
        // Navigate to the profile page
        navigate(`/adihex/profile/1`);
      } else {
        setError(message);
      }
    } catch (error) {
      console.error("Error during sign-in:", error);
      setError("An error occurred during sign-in. Please try again.");
    }
  };

  const handleShowPasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div id="root">
      <Bluenav />
      <Navbar />
      <div className="center-tablet">
        <form className="container signinform" onSubmit={handleSignin}>
          <div className="form-title">
            <h6>Sign in</h6>
          </div>
          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">Email :</label>
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              id="exampleInputEmail1"
              onChange={(e) => setValues({ ...values, email: e.target.value })}
              required
            />
          </div>
          <div className="password-container mb-3">
            <label htmlFor="exampleInputPassword1" className="form-label">Password :</label>
            <input
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              className="form-control"
              id="exampleInputPassword1"
              onChange={(e) => setValues({ ...values, password: e.target.value })}
              required
            />
            <i
              className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'} show-password-icon`}
              onClick={handleShowPasswordToggle}
            ></i>
          </div>

          {error && <div className="error-message">{error}</div>}

          <div className="mb-3 form-forgetpass">
            <h6>
              <Link to="/adihex/forgetpassword" style={{ textDecoration: "none" }} className="link">
                <span>Forgot Password ?</span>
              </Link>
            </h6>
          </div>
          <div className="form-btns-sec">
            <button type="submit" className="signin-btn">Sign in</button>
            <Link to="/adihex" style={{ textDecoration: "none" }}>
              <button type="button" className="signup-btn">Sign up</button>
            </Link>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
}
