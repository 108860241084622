// import React, { createContext, useState, useContext, useEffect } from "react";

// const TokenContext = createContext();

// export function useToken() {
//   return useContext(TokenContext);
// }

// export function TokenProvider({ children }) {
//   const [token, setToken] = useState(() => sessionStorage.getItem('token'));
//   const [contactId, setContactId] = useState(() => sessionStorage.getItem('contactId'));

//   useEffect(() => {
//     if (token) {
//       sessionStorage.setItem('token', token);
//     } else {
//       sessionStorage.removeItem('token');
//     }
//   }, [token]);

//   useEffect(() => {
//     if (contactId) {
//       sessionStorage.setItem('contactId', contactId);
//     } else {
//       sessionStorage.removeItem('contactId');
//     }
//   }, [contactId]);

//   return (
//     <TokenContext.Provider value={{ token, setToken, contactId, setContactId }}>
//       {children}
//     </TokenContext.Provider>
//   );
// }

import React, { createContext, useState, useContext, useEffect } from "react";

const TokenContext = createContext();

export function useToken() {
  return useContext(TokenContext);
}

export function TokenProvider({ children }) {
  const [token, setToken] = useState(() => sessionStorage.getItem('token'));
  const [contactId, setContactId] = useState(() => sessionStorage.getItem('contactId'));

  useEffect(() => {
    if (token) {
      sessionStorage.setItem('token', token);
    } else {
      sessionStorage.removeItem('token');
    }
  }, [token]);

  useEffect(() => {
    if (contactId) {
      sessionStorage.setItem('contactId', contactId);
    } else {
      sessionStorage.removeItem('contactId');
    }
  }, [contactId]);

  return (
    <TokenContext.Provider value={{ token, setToken, contactId, setContactId }}>
      {children}
    </TokenContext.Provider>
  );
}

