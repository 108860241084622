import React from "react";
import Navbar from "../../Components/Navbar";
import Bluenav from "../../Components/Bluenav";
import Welcomesec from "./Welcomesec";
import Patner from "./Patner";
import Features from "./Features";
import About from "./About";
import Footer from "../../Components/Footer";
import Pricing from "./Pricing";
import WhatsAppBox from "./WhatsAPP";

export default function Home() {
  return (
    <div>
      <Bluenav />
      <Navbar />
      <Welcomesec />
      <WhatsAppBox />
      <Patner />
      <Features />
      <Pricing />
      <About />
      <Footer/>
    </div>
  );
}
