import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import verify from "/webapps/tippytap/src/Assets/TippyTap Logo noBG.png";
import "/webapps/tippytap/src/Style/Verify.css";
import Modal from "react-modal";
import Bluenav from '../Bluenav';
import Navbar from '../Navbar';
import Footer from '../Footer';
import greenverify from "/webapps/tippytap/src/Assets/verified.png";

export default function Verify() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [contactID, setContactID] = useState(); // State to store ContactID
  const [isVerify, setIsVerify] = useState(null); // State to store verification status

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`https://apis.tippytap.ae/get_by_encrypted_tap/${id}`);
        const userData = response.data.data[0];
        const userId = userData['id'];
        const isVerifyValue = userData['is_verify'];

        setContactID(userId);
        setIsVerify(isVerifyValue);

        console.log('Fetched is_verify value:', isVerifyValue);

        if (isVerifyValue === "true") {
          // Directly use the navigate function here
          navigate("/signin");
          return; // Ensure that the rest of the component does not execute
        }
      } catch (error) {
        console.error('There was an error!', error);
      }
    };

    fetchUserData();
  }, [id, navigate]);

  const fetchData = async (event) => {
    event.preventDefault(); // Prevent form from submitting and reloading the page

    try {
      const response = await axios.get(`https://apis.tippytap.ae/verify_account_tap/${contactID}`);
      setData(response.data);
      console.log(response.data.message);
      setIsOpen(true);
    } catch (err) {
      console.error("Error fetching service options data:", err);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    navigate("/signin"); // Navigate to /signin when the modal closes
  };

  useEffect(() => {
    let timer;
    if (isOpen) {
      timer = setTimeout(() => {
        closeModal(); // Close the modal and navigate to /signin after 3 seconds
      }, 3000); // Time in milliseconds (e.g., 3000ms = 3 seconds)
    }
    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, [isOpen]);

  // Render nothing if isVerify is true or if still fetching
  if (isVerify === true) {
    return null;
  }

  return (
    <div id='root'>
      <Bluenav />
      <Navbar />
      <div className='padding-center-verify container'>
        <div className='verify-img-container'>
          <img src={verify}  className="tippytap-logo" />
        </div>
        <h6>Click Button To Verify</h6>
        <div className="form-btns-sec">
          <button type="submit" className="verify-btn" onClick={fetchData}>
            Verify
          </button>
        </div>
        <Modal
          isOpen={isOpen}
          onRequestClose={closeModal}
          contentLabel="Registration Confirmation"
          className="Modal"
          overlayClassName="Overlay"
        >
          <div className="icon-header-popup-model">
            <img src={greenverify} alt="Verified" />
          </div>
          <h2>Your Email Has Been Successfully Verified.</h2>
          <div className="model-btns-sec">
            {/* Additional buttons or content can be added here */}
          </div>
        </Modal>
      </div>
      <Footer />
    </div>
  );
}
