// import React from 'react';
// import { Navigate } from 'react-router-dom';
// import { useToken } from './TokenContext';

// const ProtectedRoute = ({ element }) => {
//   const { token } = useToken(); // Check if you get the token properly
//   const loggedInEmail = sessionStorage.getItem('loggedInEmail');
//   const loginTime = sessionStorage.getItem('loginTime');
//   const SESSION_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

//   const isAuthenticated = () => {
//     if (loggedInEmail && loginTime) {
//       const currentTime = new Date().getTime();
//       return currentTime - loginTime < SESSION_DURATION;
//     }
//     return false;
//   };

//   return isAuthenticated() ? element : <Navigate to="/adihex/signin" />;
// };

// export default ProtectedRoute;
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useToken } from '../Components/TokenContext';

const ProtectedRoute = ({ element }) => {
  const { token, setToken, setContactId } = useToken(); // Get token and setter functions from context
  const loggedInEmail = sessionStorage.getItem('loggedInEmail');
  const loginTime = sessionStorage.getItem('loginTime');
  const SESSION_DURATION = 86400000; // 1 day in milliseconds
  const isAuthenticated = () => {
    const currentTime = Date.now(); // Simplified time retrieval

    // Check if session is valid
    if (loggedInEmail && loginTime) {
      if (currentTime - parseInt(loginTime, 10) < SESSION_DURATION) {
        return true;
      } else {
        // Session expired, clear token and contact ID
        setToken(null);
        setContactId(null);
        sessionStorage.clear();
      }
    }

    // If the session is invalid, return false
    return false;
  };

  // If authenticated, render the element, otherwise navigate to /signin
  return token && isAuthenticated() ? element : <Navigate to="/signin" />;
};

export default ProtectedRoute;
