import React, { useState, useEffect } from 'react';
import { FaChevronDown, FaChevronUp, FaFilePdf, FaFileWord, FaFileCode, FaFile, FaFileExcel, FaFileCsv, FaFileAlt, FaFileImage } from 'react-icons/fa';
import { CiCirclePlus, CiCircleRemove } from 'react-icons/ci';
import axios from 'axios';
import Modal from "react-modal";

export default function Attachment({ MaincardID }) {
    const [isOpen, setIsOpen] = useState({ Attachment: true });
    const [isAdding, setIsAdding] = useState(false);
    const [newRow, setNewRow] = useState({ label: '', value: null });
    const [rows, setRows] = useState([]);
    const [deleteId, setDeleteId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleCollapse = (section) => {
        setIsOpen(prevState => ({ ...prevState, [section]: !prevState[section] }));
    };

    const handleAddRow = () => {
        if (newRow.label && newRow.value) {
            setRows(prevRows => [...prevRows, newRow]);
            handleSaveNew();
        }
    };

    const handleNewRowChange = (e) => {
        const { name, value, type, files } = e.target;
        setNewRow(prevRow => ({
            ...prevRow,
            [name]: type === 'file' ? files[0] : value
        }));
    };


    const closeModal = () => {
        setIsModalOpen(false);
        setDeleteId(null);
    };


    const handleSaveNew = async () => {
        try {
            const formData = new FormData();

            formData.append('card_id', MaincardID);
            formData.append('label', newRow.label);

            if (newRow.value) {
                formData.append('file', newRow.value);
            } else {
                console.error('No file selected');
                return;
            }

            const response = await axios.post('https://apis.tippytap.ae/upload_card_attachment/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            console.log('Response:', response.data);
            setIsAdding(false);
            setNewRow({ label: '', value: null });
            fetchData(); // Refetch data after adding new row
        } catch (error) {
            console.error('Error saving new data:', error);
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(`https://apis.tippytap.ae/view_single_card/${MaincardID}`);
            const data = response.data.result;
            setRows(data.card_attachment || []); // Update rows with fetched data
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const handleDeleteRow = async () => {
        try {
            const res = await axios.get(`https://apis.tippytap.ae/inactive_item/${deleteId}`); // Use deleteId for the API call
            console.log(res)
            setRows(prevRows => prevRows.filter(row => row.id !== deleteId)); // Filter out the deleted row
            closeModal(); // Close the modal after deletion
        } catch (error) {
            console.error('Error deleting row:', error);
        }
    };
    

    useEffect(() => {
        fetchData();
    }, [MaincardID]);

    const getFileIcon = (fileName) => {
        if (typeof fileName !== 'string' || !fileName) {
            return <FaFile size={20} style={{ color: '#808080' }} />; // Default icon for invalid or empty fileName
        }
        const cleanFileName = fileName.split('?')[0]; // Remove query string
        const fileExtension = cleanFileName.split('.').pop().toLowerCase();

        switch (fileExtension) {
            case 'pdf':
                return <FaFilePdf size={20} style={{ color: '#FF0000' }} />;
            case 'doc':
            case 'docx':
                return <FaFileWord size={20} style={{ color: '#1E90FF' }} />;
            case 'xls':
            case 'xlsx':
                return <FaFileExcel size={20} style={{ color: '#217346' }} />;
            case 'csv':
                return <FaFileCsv size={20} style={{ color: '#FF9900' }} />;
            case 'txt':
                return <FaFileAlt size={20} style={{ color: '#808080' }} />;
            case 'xml':
                return <FaFileCode size={20} style={{ color: '#FFA500' }} />;
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
            case 'bmp':
            case 'svg':
                return <FaFileImage size={20} style={{ color: '#32CD32' }} />;
            default:
                return <FaFile size={20} style={{ color: '#808080' }} />; // Default icon for other file types
        }
    };

    const handleCancelAdd = () => {
        setIsAdding(false);
        setNewRow({ label: '', value: null });
    };

    return (
        <div className='mt-4'>
            <div id="accordion">
                <div className="card">
                    <div
                        className="card-header"
                        id="headingAttachment"
                        onClick={() => toggleCollapse('Attachment')}
                        style={{ backgroundColor: '#F0F0F0', cursor: 'pointer' }}
                    >
                        <h5 className="mb-0 d-flex justify-content-between align-items-center">
                            <button
                                className="title-btn-collapse"
                                type="button"
                                aria-expanded={isOpen.Attachment}
                                aria-controls="collapseAttachment"
                                style={{ background: 'none', border: 'none', color: 'inherit' }}
                            >
                                Attachment
                            </button>
                            <span>
                                {isOpen.Attachment ? <FaChevronUp size={15} /> : <FaChevronDown size={15} />}
                            </span>
                        </h5>
                    </div>

                    <div
                        id="collapseAttachment"
                        className={`collapse ${isOpen.Attachment ? 'show' : ''}`}
                        aria-labelledby="headingAttachment"
                        data-parent="#accordionExample"
                    >
                        <div className="card-body">
                            {rows.length > 0 && (
                                <table className="table Attachment-table">
                                    <thead>
                                        <tr>
                                            <th>Label</th>
                                            <th>File</th>
                                            {/* <th>Action</th>  */}

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows.map((row, index) => (
                                            <tr key={index}>
                                                <td> 
                                                <button
                                                        onClick={() => {
                                                            setDeleteId(row.id);
                                                            setIsModalOpen(true);
                                                        }}
                                                        style={{ background: 'none', border: 'none', color: '#FF0000', fontWeight: "700" , marginRight:"0.5rem" }}
                                                    >
                                                        <CiCircleRemove size={25} style={{ fontWeight: 'bold' }} />
                                                    </button> 
                                                    
                                                    
                                                    
                                                    {row.label}</td>
                                                <td>
                                                    <a href={row.value} download>
                                                        {getFileIcon(row.value)}
                                                    </a>
                                                </td>
                                               
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                            {isAdding ? (
                                <div>
                                    <table className="table Attachment-table">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="label"
                                                        value={newRow.label}
                                                        onChange={handleNewRowChange}
                                                        placeholder="Label"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        name="value"
                                                        onChange={handleNewRowChange}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <button
                                        className="btn btn-success"
                                        onClick={handleAddRow}
                                        style={{ marginTop: '0.2rem' }}
                                    >
                                        Save
                                    </button>

                                    <button
                                        className="btn btn-secondary"
                                        onClick={handleCancelAdd}
                                        style={{ marginTop: '0.2rem' , marginLeft:'0.2rem' } }
                                    >
                                        Cancel
                                    </button>
                                </div>
                            ) : (
                                <button
                                    className="Add-new-table-row"
                                    onClick={() => setIsAdding(true)}
                                    style={{ marginTop: '10px' }}
                                >
                                    <CiCirclePlus style={{ color: '#57BF39', fontSize: '22px', marginRight: '5px' }} />
                                    Add New
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* Confirmation Modal */}
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Confirm Deletion"
                className="Modal"
                overlayClassName="Overlay"
            >
                <h2>Are you sure you want to delete this Attachment?</h2>
                <div className="model-btns-sec">
                    <button className="delete-modal-btn" onClick={handleDeleteRow}>Confirm</button>
                    <button className="cancel-modal-btn" onClick={closeModal}>Cancel</button>
                </div>
            </Modal>
        </div>
    );
}
