import React, { useState, useRef } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import Modal from 'react-modal';
import defaultFalconImage from '/webapps/tippytap/src/Assets/Group 2101.png';
import axios from 'axios';
import { useToken } from './TokenContext';

const AddFalconImage = ({ isEditing, onFileChange, onClose }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [error, setError] = useState(null);
    const [uploading, setUploading] = useState(false); // Track uploading state
    const cropperRef = useRef(null);
    const [data, setData] = useState([]);
    const { token } = useToken();
    const contactID = token ? atob(token) : null; // Decode the token to get contactID



    const fetchFalcons = () => {
        axios
            .get(`https://apis.tippytap.ae/get_by_contact_id/adihex_contacts_falcons/${contactID}`)
            .then((res) => {
                setData(res.data.data);
            })
            .catch((err) => console.error('Error fetching falcon data:', err));
    };

    const openModal = () => setModalIsOpen(true);
    const closeModal = () => {
        setFile(null);
        setModalIsOpen(false);
        setError(null); // Reset error state
        setUploading(false); // Reset uploading state
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setCroppedImage(null); // Clear previous crop
        }
    };

    const handleCrop = () => {
        if (!file) {
            setError("No file selected.");
            return;
        }

        if (cropperRef.current) {
            const cropper = cropperRef.current.cropper;
            if (cropper) {
                const croppedCanvas = cropper.getCroppedCanvas();
                const croppedImageURL = croppedCanvas.toDataURL();

                setUploading(true); // Set uploading state to true

                fetch(croppedImageURL)
                    .then(res => res.blob())
                    .then(blob => {
                        const croppedFile = new File([blob], file.name, { type: "image/png" });
                        onFileChange({ target: { files: [croppedFile] } }); // Pass the file to parent
                        closeModal(); // Close the modal after cropping
                    })
                    .catch(error => {
                        setError("Error saving image: " + error.message);
                    })
                    .finally(() => {
                        setUploading(false); // Reset uploading state
                    });
            } else {
                setError("No cropper instance available.");
            }
        } else {
            setError("Cropper reference is not set.");
        }
    };

    return (
        <>
            <div
                className={`width-form-control ${isEditing ? "editing-img-container-when-press" : ""}`}
            >
                {isEditing ? (
                    <input
                        className="form-control"
                        type="file"
                        onChange={handleFileChange}
                        onClick={openModal}
                    />
                ) : (
                    <img
                        src={data.image || defaultFalconImage} // Fallback to default if data.image is not available
                        className="falcon-image-1-3"
                        alt="Falcon"
                    />
                )}
            </div>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="Modal"
                overlayClassName="Overlay"
            >
                <h2>Upload and Crop Image</h2>
                {error && <p className="error-message">{error}</p>}
                {uploading && (
                    <div className="uploading-message">
                        <div className="spinner"></div>
                        <p>Processing image, please wait...</p>
                    </div>
                )}
                {!uploading && file && (
                    <>
                        <Cropper
                            src={URL.createObjectURL(file)}
                            style={{ height: 400, width: '100%' }}
                            aspectRatio={2 / 3}
                            guides={false}
                            ref={cropperRef}
                        />
                        <div className='d-flex mt-5' style={{ columnGap: "10%" }}>
                            <button className='add-falcon' type="button" onClick={handleCrop}>Crop Image</button>
                            <button className='add-falcon' type="button" onClick={closeModal}>Cancel</button>
                        </div>
                    </>
                )}
            </Modal>
        </>
    );
};

export default AddFalconImage;
