import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import eagle from "/webapps/tippytap/src/Assets/—Pngtree—peregrine falcon swooping grey low_15494761 1.png";
import "../Style/Registration.css";
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import abodaabi from "/webapps/tippytap/src/Assets/Group 1972.png";
import axios from 'axios';
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import Bluenav from "./Bluenav";
import greenverify from "/webapps/tippytap/src/Assets/verified.png";
import { useToken } from "./TokenContext"; // Import useToken hook

export default function Registration() {
  const navigate = useNavigate(); // Replace useHistory with useNavigate
  const [phone, setPhone] = useState("");
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  }); // State for both password fields
  const { setToken, setContactId } = useToken(); // Get setToken and setContactId from context
  const [values, setValues] = useState({
    contact_name: "",
    contact_phone: "",
    contact_email: "",
    contact_password: "",
    country_code: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const handlePhoneChange = (value, data) => {
    setPhone(value);
    setValues({
      ...values,
      contact_phone: value,
      country_code: data.dialCode,
    });
  };

  // const setupSession = (contactID, email, setToken, setContactId, navigate) => {
  //   const generatedToken = btoa(contactID);
  
  //   // Set token and contactID in context
  //   setToken(generatedToken);
  //   setContactId(contactID);
  
  //   // Store session details in sessionStorage
  //   const loginTime = new Date().getTime();
  //   sessionStorage.setItem('loggedInEmail', email);
  //   sessionStorage.setItem('contactID', contactID);
  //   sessionStorage.setItem('loginTime', loginTime);
  
  //   // Display modal and navigate after a short delay
  //   setIsOpen(true);
  //   setTimeout(() => {
  //     navigate("/adihex/profile");
  //   }, 3000); // Adjust delay as needed
  // };
  
  // useEffect(() => {
  //   if (token) {
  //     navigate("/adihex/profile"); // Redirect to a different page if user is logged in
  //   }
  // }, [token, navigate]);

  const handleregister = async (event) => {
    event.preventDefault(); // Prevent default form submission
    setError("")
    if (!values.contact_name || !values.contact_phone || !values.contact_email || !values.contact_password) {
      setError("Please fill in all required fields.");
      return;
    }

    // Check if phone number includes digits after the country code
    const phoneNumberWithoutCountryCode = values.contact_phone.replace(/^\+\d+/, '');
    if (!phoneNumberWithoutCountryCode || phoneNumberWithoutCountryCode.replace(/\D/g, '').length < 8) {
      setError("Please enter a valid phone number.");
      return;
    }

    if (values.contact_password !== confirmPassword) {
      setError("Passwords didn't match. Try again");
      return;
    }

    try {
      const emailCheckResponse = await axios.get(`https://apis.tippytap.ae/check_registered_data/${values.contact_email}`);

      if (emailCheckResponse.data.status === 1) {
        setError("This email is already registered. Please use a different email.");
        return;
      }

      const registerResponse = await axios.post("https://apis.tippytap.ae/create_record", {
        table_name: "adihex.contacts",
        data: values
      });

      if (registerResponse.data.status === 1) { // Assuming status 1 means success
        // const contactID = registerResponse.data.id; // Assuming contact_id is returned in result
        // setupSession(contactID, values.contact_email, setToken, setContactId, navigate);
        setError(""); // Clear any previous errors
        setTimeout(() => {
          navigate("/adihex/profile");
        }, 3000); // Delay of 3 seconds
      } else {
        setError("Registration failed. Please try again."); // Handle other responses or errors
      }
    } catch (error) {
      console.error("Error registering user", error);
      setError("An error occurred during registration. Please try again."); // Handle error during registration
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    navigate("/adihex/signin"); // Navigate to /signin when the modal closes
  };

  useEffect(() => {
    let timer;
    if (isOpen) {
      timer = setTimeout(() => {
        closeModal(); // Close the modal and navigate to /signin after 3 seconds
      }, 3000); // Time in milliseconds (e.g., 3000ms = 3 seconds)
    }
    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, [isOpen, navigate]);

  const handleShowPasswordToggle = (field) => {
    setShowPassword((prev) => ({
      ...prev,
      [field]: !prev[field]
    }));
  };

  return (
    <div id="">
      <Bluenav />
      <Navbar />
      <div className="flex-register container">
        <div className="left-register-img-sec">
          <div className="d-flex justify-content-center align-item-center eagle-cont">
            <img src={eagle} className="eagle" alt="Eagle" />
          </div>
          <div className="d-flex justify-content-center align-item-center abudhabi">
            <img src={abodaabi} className="eagle" alt="Abudhabi" />
          </div>
        </div>

        <form className="form-registration-ADIHEX mb-2" onSubmit={handleregister}>
          <div className="form-title2">
            <h6>Registration Form</h6>
          </div>

          <div className="mb-3">
            <label htmlFor="Name" className="form-label">
              Name :
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              id="Name"
              onChange={(e) => setValues({ ...values, contact_name: e.target.value })}
              required
            />
          </div>

          <div className="mb-3">
            <label className="form-label">
              Phone
            </label>
            <PhoneInput
              country={'ae'}
              value={phone}
              onChange={handlePhoneChange}
              inputStyle={{ width: '100%' }}  // Set width to 100%
            />
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Email address
            </label>
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              placeholder="Email"
              aria-describedby="emailHelp"
              onChange={(e) => setValues({ ...values, contact_email: e.target.value })}
              required
            />
          </div>

          <div className="password-container mb-3">
            <label htmlFor="exampleInputPassword1" className="form-label">
              Password :
            </label>
            <input
              placeholder="Password"
              type={showPassword.password ? "text" : "password"} // Toggle input type
              className="form-control"
              id="exampleInputPassword1"
              onChange={(e) => setValues({ ...values, contact_password: e.target.value })}
              required
            />
            <i
              className={`fas ${showPassword.password ? 'fa-eye-slash' : 'fa-eye'} show-password-icon`} // Toggle icon
              onClick={() => handleShowPasswordToggle('password')} // Toggle password visibility
            ></i>
          </div>
          <div className="password-container mb-3">
            <label htmlFor="exampleInputPassword2" className="form-label">
              Confirm Password :
            </label>
            <input
              placeholder="Confirm Password"
              type={showPassword.confirmPassword ? "text" : "password"} // Toggle input type
              className="form-control"
              id="exampleInputPassword2"
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <i
              className={`fas ${showPassword.confirmPassword ? 'fa-eye-slash' : 'fa-eye'} show-password-icon`} // Toggle icon
              onClick={() => handleShowPasswordToggle('confirmPassword')} // Toggle password visibility
            ></i>
          </div>
          {error && <div className="error-message">{error}</div>}

          <div className="register-btn-container">
            <button type="submit">Register Now</button>
            <small>Already have an account?
              <Link to={"/adihex/signin"} style={{ textDecoration: "none" }}>
                <span> Sign in</span>
              </Link>
            </small>
          </div>
        </form>
      </div>
      <Footer />
      {/* Confirmation Modal */}
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Registration Confirmation"
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="icon-header-popup-model"> 
        <img src={greenverify} />


        </div>
        <h2>Registration successful!</h2>
        <div className="model-btns-sec">
          {/* Additional buttons or content can be added here */}
        </div>
      </Modal>
    </div>
  );
}

