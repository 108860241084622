import React from 'react';
import '/webapps/tippytap/src/Style/WhatsAppBox.css'; // Import the CSS for styling
import whatsapp from '/webapps/tippytap/src/Assets/Group 286@2x.png'

const WhatsAppBox = () => {
  return (
    <div className="whatsapp-box">
      <a href="https://api.whatsapp.com/send/?phone=%2B971554031010&text=TippyTap+Business+Card+Packages+Order+%28below+prices+are+per+card%29%3A%0A%5B1-9%5D+Cards%3A+300+AED%0A%5B10-19%5D+Cards+%28-10%25%29%3A+270+AED+%0A%5B20-50%5D+Cards+%28-15%25%29%3A+255+AED%0AOrder+no.+of+Cards%3A&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer">
        <img src={whatsapp} alt="WhatsApp" />
      </a>
    </div>
  );
};

export default WhatsAppBox;
