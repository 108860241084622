import React, { useState, useEffect } from "react";
import logo from "/webapps/tippytap/src/Assets/TippyTap Logo noBG.png";
import "../Style/Navbar.css";
import icon from "/webapps/tippytap/src/Assets/Icon.png";
import { useToken } from "./TokenContext";
import togglerIcon from "/webapps/tippytap/src/Assets/Frame 1786 (1).png";
import { Link, useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { useNavigate, useParams } from 'react-router-dom';
import ComingSoon from "./ComingSoon";

export default function Navbar() {
  const { token, setToken, contactId, setContactId } = useToken();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal open state

  useEffect(() => {
    const loggedInEmail = sessionStorage.getItem('loggedInEmail');
    const loginTime = sessionStorage.getItem('loginTime');
    const SESSION_DURATION = 86400000; // 1 day in milliseconds

    if (loggedInEmail && loginTime) {
      const currentTime = new Date().getTime();
      if (currentTime - loginTime >= SESSION_DURATION) {
        handleLogout(); // Log out if the session has expired
      }
    }
  }, [token]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 992) { // Adjust breakpoint as needed
        setIsMenuOpen(false); // Close menu on larger screens
      }
    };

    window.addEventListener('resize', handleResize);

    // Cleanup on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLogout = () => {
    setToken(null); // Clear token
    setContactId(null); // Clear contactId
    sessionStorage.removeItem('newLoggedInEmail');
    sessionStorage.removeItem('newContactID');
    sessionStorage.removeItem('newLoginTime');
    sessionStorage.clear(); // Clear all session data
    navigate('/signin')
  };

  function toggleMenu(event) {
    // Prevent menu toggle for ScrollLink elements
    if (event.target.tagName === 'A' && event.target.closest('.scroll-link')) {
      return;
    }
    setIsMenuOpen(!isMenuOpen);
  }

  const openModal = () => {
    setIsModalOpen(true); // Open modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close modal
  };

  const isHomeRoute = location.pathname === '/';
  const isAdihexRoute = location.pathname.startsWith('/adihex');
  const isContactUsRoute = location.pathname === '/Contactus'; // Check if the current route is Contact Us
  const isDashboard = location.pathname.startsWith('/profile'); // Check if the current route is Contact Us
  const isCheckout = location.pathname.startsWith('/checkout');
  // console.log("Token:", token); // Debugging line
  return (
    <div className="navbar-wrapper">
      <nav className="navbar navbar-expand-lg navbar-light container">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <Link to="/" style={{ textDecorationLine: "none" }}>
              <div>
                <img src={logo} className="tippytap-logo" />
                <span className="span-for-logo-title" >TippyTap</span>

              </div>
            </Link>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleMenu}
          >
            <img src={togglerIcon} alt="Toggler Icon" />
          </button>
          <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex">
              <li className="nav-item">
                <Link
                  to={"/"}
                  className={`nav-link ${isHomeRoute ? 'active' : ''}`}
                  aria-current="page"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <ScrollLink
                  to="Pricing"
                  smooth={true}
                  duration={500}
                  className="nav-link scroll-link" // Add a class for easy identification
                  onClick={toggleMenu}
                  style={{ textDecoration: "none" }}
                >
                  Products
                </ScrollLink>
              </li>
              <li className="nav-item">
                <div
                  className={`nav-link ${isAdihexRoute ? 'active' : ''}`}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  Adihex
                </div>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${isContactUsRoute ? 'active' : ''}`}
                  onClick={toggleMenu}
                  style={{ textDecoration: "none" }}
                  to={"/Contactus"}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
            {isHomeRoute && !token && (
                        <Link to='/Register'>

              <div className="nav-item sign-up-btn">
                   <button  className="btn btn-primary singup-nav-btn">
          Sign Up
        </button>
              </div>
              </Link>

            )}
          </div>
          
          {token && (isCheckout || isContactUsRoute || isDashboard || isHomeRoute) && (
            <div
              className="nav-item dropdown profile-section"
              style={{ display: isMenuOpen ? 'none' : 'block' }}
            >
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown2"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src={icon} alt="User Icon" />
              </a>
              <ul className="dropdown-menu dropdown-menu-end profile-menu" aria-labelledby="navbarDropdown2">
                <Link
                  style={{ textDecoration: "none" }}
                  to={"/profile"}
                >
                  <li>
                    <a className="dropdown-item" href="#" >
                      Profile
                    </a>
                  </li>
                </Link>

                <li>
                  <a className="dropdown-item" href="#" onClick={handleLogout}>
                    Log out
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
      </nav>
      <ComingSoon isOpen={isModalOpen} closeModal={closeModal} />

    </div>
  );
}
