import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import "/webapps/tippytap/src/Style/MainLayout.css";
import Bluenav from '../Bluenav';
import Navbar from '../Navbar';
import Footer from '../Footer';
import ProfileContent from './ProfileContent';
import EcardContent from './EcardContent';
import ProtectedRoute from '../ProtectedRoute';
import BusinessCardContent from './BusinessCardContent';
import NewCardDetails from './New-card-details';
import PersonalDataSection from './PersonalDataSection';
import MultiCardContent from './MultiCardContent';
import icon from '/webapps/tippytap/src/Assets/menu (1).png'
const ProfileLayout = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [packages, setPackages] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    axios.get('https://apis.tippytap.ae/get_packages/')
      .then(response => {
        setPackages(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching profile data:', error);
      });
  }, []);

  const handleMenuClick = (route, isDisabled = false) => {
    if (!isDisabled) {
      navigate(route);
      setIsMenuOpen(false); // Close the menu when an item is clicked
    }
  };

  const getActiveClass = (path) => {
    if (path === '/profile') {
      // Exact match for /profile
      return currentPath === '/profile' ? 'active' : '';
    }
    // Prefix match for other paths, e.g., /profile/businesscard
    return currentPath.startsWith(path) ? 'active' : '';
  };



  // Determine if the Business Card menu item should be included
  const hasBusinessCardPackage = packages.some(pkg => pkg.name.toLowerCase().includes('business card'));

  return (
    <div id='root'>
      <Bluenav />
      <Navbar />
      <div className="profile-layout-container">
        <div className="border-img-grey-container">
          <button
            className={`off-canvas-menu-button ${isMenuOpen ? 'hide' : ''}`}
            onClick={toggleMenu}
          >
            <img src={icon} alt="menu" />
          </button>
        </div>
        <div className={`off-canvas-menu ${isMenuOpen ? 'open' : ''}`}>
          <ul className='left-menu-list'>
            <li
              className={`left-menu-item ${getActiveClass('/profile')}`}
              onClick={() => handleMenuClick('/profile')}
            >
              My Profile
            </li>
            {hasBusinessCardPackage && (
              <li
                className={`left-menu-item ${getActiveClass('/businesscard')}`}
                onClick={() => handleMenuClick('/businesscard')}
              >
                Business Card
              </li>
            )}
            {packages
              .filter(pkg => !pkg.name.toLowerCase().includes('business card'))
              .map(pkg => (
                <li
                  key={pkg.id}
                  className={`left-menu-item ${getActiveClass(`/profile/${pkg.name.toLowerCase().replace(' ', '-')}`)}`}
                  onClick={() => handleMenuClick(`/profile/${pkg.name.toLowerCase().replace(' ', '-')}`)}
                >
                  {pkg.name}
                </li>
              ))}

          </ul>
        </div>
        <div className="profile-wrapper-container container">
          <div className="grid-layout-left-bar-content">
            <div className="left-menu-bar">
              <ul className='left-menu-list'>
                <li
                  className={`left-menu-item ${getActiveClass('/profile')}`}
                  onClick={() => handleMenuClick('/profile')}
                >
                  My Profile
                </li>
                {hasBusinessCardPackage && (
                  <li
                    className={`left-menu-item ${getActiveClass('/businesscard')}`}
                    onClick={() => handleMenuClick('/profile/business')}
                  >
                    Business Card
                  </li>
                )}
             {packages
    .filter(pkg => !pkg.name.toLowerCase().includes('business card')) // Exclude 'business card'
    .map(pkg => {
      const lowerName = pkg.name.toLowerCase();
      // Check for exact names to disable
      const isDisabled = pkg.name === 'Multi Cards' || pkg.name === 'E - Card';
      
      return (
        <li
          key={pkg.id}
          className={`left-menu-item ${getActiveClass(`/profile/${lowerName.replace(' ', '-')}`)} ${isDisabled ? 'disabled' : ''}`}
          onClick={() => handleMenuClick(`/profile/${lowerName.replace(' ', '-')}`, isDisabled)}
        >
          {pkg.name}
        </li>
      );
    })}

                  
              </ul>

            </div>
            <div className='right-layout-content'>
              <Routes>
                <Route path="/" element={<ProtectedRoute element={<ProfileContent />} />} />

                <Route path="/businesscard" element={<ProtectedRoute element={<BusinessCardContent />} />} />
                <Route path="/businesscard/carddesign/:order_id" element={<ProtectedRoute element={<NewCardDetails />} />} />
                <Route path="/businesscard/carddata/:order_id" element={<ProtectedRoute element={<PersonalDataSection />} />} />
                <Route path="/multicard" element={<ProtectedRoute element={<MultiCardContent />} />} />
                <Route path="/e-card" element={<ProtectedRoute element={<EcardContent />} />} />

              </Routes>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProfileLayout;
