import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import eagle from "/webapps/tippytap/src/Assets/Group 1834 (3).png";
import "/webapps/tippytap/src/Style/Tippy_register.css";
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import abodaabi from "/webapps/tippytap/src/Assets/Group 1972.png";
import axios from 'axios';
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import Bluenav from "../Bluenav";
import tippycard from "/webapps/tippytap/src/Assets/Banner img@2x.png"
import greenverify from "/webapps/tippytap/src/Assets/verified.png";

export default function Tippy_register() {
  const navigate = useNavigate(); // Replace useHistory with useNavigate
  const [phone, setPhone] = useState("");
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  }); // State for both password fields
  const [values, setValues] = useState({
    name: "",
    phone: "",
    email: "",
    last_name:"",
    password: "",
    confirm_password:"",
    // country_code: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  // const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const handlePhoneChange = (value, data) => {
    setPhone(value);
    setValues({
      ...values,
      phone: value,
      // country_code: data.dialCode,
    });
  };

  const handleregister = async (event) => {
    event.preventDefault(); // Prevent default form submission
    setError("")
    if (!values.name || !values.phone || !values.email || !values.password || !values.confirm_password || !values.last_name) {
      setError("Please fill in all required fields.");
      return;
    }

    // Check if phone number includes digits after the country code
    const phoneNumberWithoutCountryCode = values.phone.replace(/^\+\d+/, '');
    if (!phoneNumberWithoutCountryCode || phoneNumberWithoutCountryCode.replace(/\D/g, '').length < 8) {
      setError("Please enter a valid phone number.");
      return;
    }

    if (values.password !== values.confirm_password) {
      setError("Password don't match. Please Try again");
      return;
    }

    try {
      const emailCheckResponse = await axios.get(`https://apis.tippytap.ae/check_registered_data_tap/${values.email}`);

      if (emailCheckResponse.data.status === 1) {
        setError("This email is already registered. Please use a different email.");
        return;
      }

      const registerResponse = await axios.post("https://apis.tippytap.ae/create_record_tap", {
        table_name: "res.partner",
        data: values
      });

      if (registerResponse.data.status === 1) { // Assuming status 1 means success
        setIsOpen(true); // Open modal on successful registration
        setError(""); // Clear any previous errors
      } else {
        setError("Registration failed. Please try again."); // Handle other responses or errors
      }
    } catch (error) {
      console.error("Error registering user", error);
      setError("Please try again."); // Handle error during registration
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    navigate("/signin"); // Navigate to /signin when the modal closes
  };

  useEffect(() => {
    let timer;
    if (isOpen) {
      timer = setTimeout(() => {
        closeModal(); // Close the modal and navigate to /signin after 3 seconds
      }, 3000); // Time in milliseconds (e.g., 3000ms = 3 seconds)
    }
    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, [isOpen, navigate]);

  const handleShowPasswordToggle = (field) => {
    setShowPassword((prev) => ({
      ...prev,
      [field]: !prev[field]
    }));
  };

  return (
    <div id="root">
      <Bluenav />
      <Navbar />
      <div className="flex-register-main container ">
        <div className="left-register-img-sec">
          <div className="d-flex justify-content-center align-item-center eagle-cont4">
            <img src={tippycard} className="Purple-card-img" alt="Eagle" />
          </div>
          {/* <div className="d-flex justify-content-center align-item-center  abudhabi">
            <img src={abodaabi} className="eagle" alt="Abudhabi" />
          </div> */}
        </div>

        <form className="form-registration-main mb-2" onSubmit={handleregister}>
          <div className="form-title3">
            <h6>Registration Form</h6>
          </div>

          <div className="mb-1">
            <label htmlFor="Name" className="form-label">
              First Name :
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              id="Name"
              onChange={(e) => setValues({ ...values, name: e.target.value })}
              required
            />
          </div>
          <div className="mb-1">
            <label htmlFor="Name" className="form-label">
              Last Name :
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              id="last_name"
              onChange={(e) => setValues({ ...values, last_name: e.target.value })}
              required
            />
          </div>

          <div className="mb-1">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Email address :
            </label>
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              placeholder="Email"
              aria-describedby="emailHelp"
              onChange={(e) => setValues({ ...values, email: e.target.value })}
              required
            />
          </div>

          <div className="mb-1">
            <label className="form-label">
              Phone :
            </label>
            <PhoneInput
              country={'ae'}
              value={phone}
              onChange={handlePhoneChange}
              inputStyle={{ width: '100%' }}  // Set width to 100%
            />
          </div>

          <div className="password-container mb-1">
            <label htmlFor="exampleInputPassword1" className="form-label">
              Password :
            </label>
            <input
              placeholder="Password"
              type={showPassword.password ? "text" : "password"} // Toggle input type
              className="form-control"
              id="exampleInputPassword1"
              onChange={(e) => setValues({ ...values, password: e.target.value })}
              required
            />
            <i
              className={`fas ${showPassword.password ? 'fa-eye-slash' : 'fa-eye'} show-password-icon`} // Toggle icon
              onClick={() => handleShowPasswordToggle('password')} // Toggle password visibility
            ></i>
          </div>
          <div className="password-container mb-1">
            <label htmlFor="exampleInputPassword2" className="form-label">
              Confirm Password :
            </label>
            <input
              placeholder="Confirm Password"
              type={showPassword.confirmPassword ? "text" : "password"} // Toggle input type
              className="form-control"
              id="exampleInputPassword2"
              onChange={(e) => setValues({ ...values, confirm_password: e.target.value })}

              required
            />
            <i
              className={`fas ${showPassword.confirmPassword ? 'fa-eye-slash' : 'fa-eye'} show-password-icon`} // Toggle icon
              onClick={() => handleShowPasswordToggle('confirmPassword')} // Toggle password visibility
            ></i>
          </div>
          {error && <div className="error-message">{error}</div>}

          <div className="register-btn-container mt-3">
            <button type="submit">Register Now</button>
            <small>Already have an account?
              <Link to={"/signin"} style={{ textDecoration: "none" }}>
                <span> Sign in</span>
              </Link>
            </small>
          </div>
        </form>
      </div>
      <Footer />
      {/* Confirmation Modal */}
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Registration Confirmation"
        className="Modal"
        overlayClassName="Overlay"
      >
          <div className="icon-header-popup-model">
            <img src={greenverify} alt="Verified" />
          </div>
        <h2>A verification email has been sent to your address</h2>
        <div className="model-btns-sec">
          {/* Additional buttons or content can be added here */}
        </div>
      </Modal>
    </div>
  );
}
