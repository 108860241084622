import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { FaEdit } from 'react-icons/fa'; // Importing the edit icon
import camera from '/webapps/tippytap/src/Assets/camera (4) 1.png';
import '/webapps/tippytap/src/Style/UploadProfileImage.css'; // Import the CSS file

export default function UploadProfileImage({ MaincardID }) {
    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [cartImage, setCartImage] = useState(null); // State to store the cart_image

    const fetchData = async () => {
        try {
            const response = await axios.get(`https://apis.tippytap.ae/view_single_card/${MaincardID}`);
            const data = response.data.result;
            console.log(data);
            // Set the cart_image if available
            setCartImage(data.image_path || null);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [MaincardID]);

    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            await handleFileUpload(file); // Automatically upload the file after selecting it
        }
    };

    const handleFileUpload = async (file) => {
        if (!file) return;

        const formData = new FormData();
        formData.append('card_id', MaincardID);
        formData.append('file', file);

        try {
            const response = await axios.post('https://apis.tippytap.ae/upload_card_profile/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                console.log('File uploaded successfully');
                // Re-fetch the data to update the cartImage state
                const newImageUrl = URL.createObjectURL(file);
                setCartImage(newImageUrl); // Set new image instantly
                fetchData();
            } else {
                console.error('File upload failed');
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    return (
        <div className="upload-profile-container">
            <h4 className="Card-header-img2">Profile Image</h4>
            <div className="image-container">
                <div className='Camera-img-container'>
                    <img
                        src={cartImage ? cartImage : camera}
                        alt="Upload"
                        onClick={handleImageClick}
                        className={`camera-img ${cartImage ? 'has-border' : ''}`}
                    />
                    {/* Conditionally render the FaEdit icon */}
                    {cartImage && (
                        <FaEdit
                            onClick={handleImageClick}
                            className="edit-icon"
                        />
                    )}
                </div>

                <div className="mb-3">
                    <input
                        className="form-control"
                        type="file"
                        id="formFile"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                </div>
            </div>
        </div>
    );
}
