import React, { useEffect, useState, useRef } from 'react';
import "/webapps/tippytap/src/Style/Profile-Contect.css";
import img from '/webapps/tippytap/src/Assets/Ellipse 4.png';
import pin from "/webapps/tippytap/src/Assets/pencil (1) 1.png";
import axios from 'axios';
import { useToken } from '../TokenContext';
import { FaEdit } from 'react-icons/fa'; // Importing the edit icon
import defaultImage from '/webapps/tippytap/src/Assets/camera (4) 1.png';
import { useParams } from 'react-router';

const ProfileContent = () => {
  // Initialize state for profile details and edit mode
  const { token } = useToken();
  const [profile, setProfile] = useState({
    name: "",
    last_name: "",
    email: "",
    phone: "",
    bio: "",
    street: "",
    image: " ",
    country_name: "",
    function: "",
    city: "",
    zip: "",
    function: "",
  });
  
  const [countries] = useState([
    'Afghanistan', 'Albania', 'Algeria', 'American Samoa', 'Andorra', 
    'Angola', 'Anguilla', 'Antarctica', 'Antigua and Barbuda', 'Argentina', 
    'Armenia', 'Aruba', 'Australia', 'Austria', 'Azerbaijan', 'Bahamas', 
    'Bahrain', 'Bangladesh', 'Barbados', 'Belarus', 'Belgium', 'Belize', 
    'Benin', 'Bermuda', 'Bhutan', 'Bolivia', 'Bonaire, Sint Eustatius and Saba', 
    'Bosnia and Herzegovina', 'Botswana', 'Bouvet Island', 'Brazil', 
    'British Indian Ocean Territory', 'Brunei Darussalam', 'Bulgaria', 
    'Burkina Faso', 'Burundi', 'Cambodia', 'Cameroon', 'Canada', 'Cape Verde', 
    'Cayman Islands', 'Central African Republic', 'Chad', 'Chile', 'China', 
    'Christmas Island', 'Cocos (Keeling) Islands', 'Colombia', 'Comoros', 
    'Congo', 'Cook Islands', 'Costa Rica', 'Croatia', 'Cuba', 'Curaçao', 
    'Cyprus', 'Czech Republic', 'Côte d\'Ivoire', 'Democratic Republic of the Congo', 
    'Denmark', 'Djibouti', 'Dominica', 'Dominican Republic', 'Ecuador', 
    'Egypt', 'El Salvador', 'Equatorial Guinea', 'Eritrea', 'Estonia', 
    'Eswatini', 'Ethiopia', 'Falkland Islands', 'Faroe Islands', 'Fiji', 
    'Finland', 'France', 'French Guiana', 'French Polynesia', 'French Southern Territories', 
    'Gabon', 'Gambia', 'Georgia', 'Germany', 'Ghana', 'Gibraltar', 'Greece', 
    'Greenland', 'Grenada', 'Guadeloupe', 'Guam', 'Guatemala', 'Guernsey', 
    'Guinea', 'Guinea-Bissau', 'Guyana', 'Haiti', 'Heard Island and McDonald Islands', 
    'Holy See (Vatican City State)', 'Honduras', 'Hong Kong', 'Hungary', 
    'Iceland', 'India', 'Indonesia', 'Iran', 'Iraq', 'Ireland', 'Isle of Man', 
    'Israel', 'Italy', 'Jamaica', 'Japan', 'Jersey', 'Jordan', 'Kazakhstan', 
    'Kenya', 'Kiribati', 'Kosovo', 'Kuwait', 'Kyrgyzstan', 'Laos', 'Latvia', 
    'Lebanon', 'Lesotho', 'Liberia', 'Libya', 'Liechtenstein', 'Lithuania', 
    'Luxembourg', 'Macau', 'Madagascar', 'Malawi', 'Malaysia', 'Maldives', 
    'Mali', 'Malta', 'Marshall Islands', 'Martinique', 'Mauritania', 'Mauritius', 
    'Mayotte', 'Mexico', 'Micronesia', 'Moldova', 'Monaco', 'Mongolia', 
    'Montenegro', 'Montserrat', 'Morocco', 'Mozambique', 'Myanmar', 'Namibia', 
    'Nauru', 'Nepal', 'Netherlands', 'New Caledonia', 'New Zealand', 
    'Nicaragua', 'Niger', 'Nigeria', 'Niue', 'Norfolk Island', 'North Korea', 
    'North Macedonia', 'Northern Mariana Islands', 'Norway', 'Oman', 'Pakistan', 
    'Palau', 'Panama', 'Papua New Guinea', 'Paraguay', 'Peru', 'Philippines', 
    'Pitcairn Islands', 'Poland', 'Portugal', 'Puerto Rico', 'Qatar', 
    'Romania', 'Russian Federation', 'Rwanda', 'Réunion', 'Saint Barthélemy', 
    'Saint Helena, Ascension and Tristan da Cunha', 'Saint Kitts and Nevis', 
    'Saint Lucia', 'Saint Martin (French part)', 'Saint Pierre and Miquelon', 
    'Saint Vincent and the Grenadines', 'Samoa', 'San Marino', 'Saudi Arabia', 
    'Senegal', 'Serbia', 'Seychelles', 'Sierra Leone', 'Singapore', 'Sint Maarten (Dutch part)', 
    'Slovakia', 'Slovenia', 'Solomon Islands', 'Somalia', 'South Africa', 
    'South Georgia and the South Sandwich Islands', 'South Korea', 'South Sudan', 
    'Spain', 'Sri Lanka', 'State of Palestine', 'Sudan', 'Suriname', 'Svalbard and Jan Mayen', 
    'Sweden', 'Switzerland', 'Syria', 'São Tomé and Príncipe', 'Taiwan', 
    'Tajikistan', 'Tanzania', 'Thailand', 'Timor-Leste', 'Togo', 'Tokelau', 
    'Tonga', 'Trinidad and Tobago', 'Tunisia', 'Turkey', 'Turkmenistan', 
    'Turks and Caicos Islands', 'Tuvalu', 'USA Minor Outlying Islands', 
    'Uganda', 'Ukraine', 'United Arab Emirates', 'United Kingdom', 'United States', 
    'Uruguay', 'Uzbekistan', 'Vanuatu', 'Venezuela', 'Vietnam', 
    'Virgin Islands (British)', 'Virgin Islands (USA)', 'Wallis and Futuna', 
    'Western Sahara', 'Yemen', 'Zambia', 'Zimbabwe', 'Åland Islands'
  ]);

  const [initialProfile, setInitialProfile] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const { setToken, setContactId } = useToken();
  const contactID = token ? atob(token) : null; // Decode the token to get contactID
  const fileInputRef = useRef(null);

  useEffect(() => {
    fetchProfile();
  }, [contactID]);

    const { id } = useParams();


  const fetchProfile = () => {
    axios.get(`https://apis.tippytap.ae/get_profile/${contactID}`)
      .then(response => {
        const fetchedProfile = response.data.data[0];
        setProfile(fetchedProfile);
        setInitialProfile(fetchedProfile);
      })
      .catch(error => {
        console.error('Error fetching profile data:', error);
      });
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
    if (isEditing) {
      handleSaveClick(); // Save changes if switching from editing mode
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile(prevProfile => ({
      ...prevProfile,
      [name]: value
    }));
  };

  const handleSaveClick = () => {
    // Compare current profile with initial profile and send only changed data
    const updatedFields = Object.keys(profile).reduce((changes, key) => {
      if (profile[key] !== initialProfile[key]) {
        changes[key] = profile[key];
      }
      return changes;
    }, {});

    // Only send the updated fields to the API
    if (Object.keys(updatedFields).length > 0) {
      const requestData = {
        object_id: contactID, // Ensure this ID is correct and matches the record you want to update
        table_name: "res.partner", // Ensure this is the correct table name
        data: updatedFields // Send only the updated fields
      };

      axios.post('https://apis.tippytap.ae/Update_record_tap', requestData)
        .then(response => {
          console.log('Profile updated:', response.data);
          setInitialProfile(profile); // Update initial profile with the latest data
          setIsEditing(false); // Exit editing mode upon success
        })
        .catch(error => {
          console.error('Error updating profile:', error);
        });
    }
  };
  const handleImageClick = () => {
    fileInputRef.current.click();
  };


  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('contact_id', contactID); // Pass the contact ID if needed

      try {
        // Replace with your API endpoint for image upload
        const response = await axios.post('https://apis.tippytap.ae/upload_image_contact_tap', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}` // Include token if required
          }
        });

        console.log(response)
        // Assuming the response contains the updated image URL
        const imageUrl = response.data.imageUrl;
        setProfile(prevProfile => ({
          ...prevProfile,
          image: imageUrl
        }));
        fetchProfile();

      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };


  return (
    <div className='profile-content'>
      <div className='editable-profile-container'>
        <div className="BussinessCard-container-float">
          <h6>My Profile</h6>
          <div className="edit-btn-container">
          <button 
  className={`edit-btn-2 ${isEditing ? 'save-btn' : ''}`} 
  onClick={handleEditClick}
>
  {!isEditing && <img src={pin} className="mx-1" alt="Edit" />}
  {isEditing ? 'Save' : 'Edit'}
</button>

          </div>  </div>
        <div className='img-and-name'>
          <div className='image-container'>
            <div className='Camera-img-container'>
              <img
                src={profile.contact_image || defaultImage}
                alt="Profile"
                onClick={handleImageClick}
                className="camera-img"
              />
              {profile.contact_image && (
                <FaEdit
                  onClick={handleImageClick}
                  className="edit-icon"
                />
              )}
            </div>
            <input
              className="form-control"
              type="file"
              id="formFile"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
          </div>
          <div className='name name-myprofile'>

            <h6>{profile.name} {profile.last_name}</h6>

            <span>
              {isEditing ? (
                <input
                  type="text"
                  name="function"
                  value={profile.street}
                  onChange={handleInputChange}
                  className={`form-control mt-3 ${isEditing ? 'editable-input' : ''}`}
                  placeholder='Title'

                />
              ) : (
                profile.function
              )}
            </span>
            {/* <span>
              {isEditing ? (
                <input
                  type="text"
                  name="street"
                  value={profile.street}
                  onChange={handleInputChange}
                  className={`form-control mt-3 ${isEditing ? 'editable-input' : ''}`}
                  placeholder='Address'

                />
              ) : (
                profile.street
              )}
            </span> */}
          </div>
        </div>

        <div className='editable-personal-container'>
          <h6>Personal Information</h6>
          <div className='Rows-data'>
            <div className='single-personal-data-layout'>
              <span>First Name</span>
              {isEditing ? (
                <input
                  type="text"
                  name="name"
                  value={profile.name}
                  onChange={handleInputChange}
                  className={`form-control mt-1 ${isEditing ? 'editable-input' : ''}`}
                  placeholder='First Name'

                />
              ) : (
                <p>{profile.name}</p>
              )}
            </div>
            <div className='single-personal-data-layout'>
              <span>Last Name</span>
              {isEditing ? (
                <input
                  type="text"
                  name="last_name"
                  value={profile.last_name}
                  onChange={handleInputChange}
                  className={`form-control mt-1 mb-1 ${isEditing ? 'editable-input' : ''}`}
                  placeholder='Last Name'

                />
              ) : (
                <p>{profile.last_name}</p>
              )}
            </div>
            <div className='single-personal-data-layout'>
              <span>Email Address</span>
              {isEditing ? (
                <input
                  type="email"
                  name="email"
                  value={profile.email}
                  onChange={handleInputChange}
                  className={`form-control mt-1 mb-1 ${isEditing ? 'editable-input' : ''}`}
                  placeholder='Email'

                />
              ) : (
                <p>{profile.email}</p>
              )}
            </div>
            <div className='single-personal-data-layout'>
              <span>Phone</span>
              {isEditing ? (
                <input
                  type="text"
                  name="phone"
                  value={profile.phone}
                  onChange={handleInputChange}
                  className={`form-control mt-1 mb-1 ${isEditing ? 'editable-input' : ''}`}
                  placeholder='Phone'
                />
              ) : (
                <p>{profile.phone}</p>
              )}
            </div>


          </div>
          <div className='single-personal-data-layout'>
            <span>Bio</span>
            {isEditing ? (
              <textarea
                type="text"
                name="bio"
                value={profile.bio}
                onChange={handleInputChange}
                className={`form-control mt-1 mb-1 ${isEditing ? 'editable-input' : ''}`}
                placeholder='Bio'
                style={{ width: "100%" }}
              />
            ) : (
              <p>{profile.bio}</p>
            )}
          </div>
        </div>

        <div className='editable-address-container'>
          <h6>Address</h6>
          <div className='Rows-data'>
            <div className='single-address-data-layout'>
              <span>Country</span>
              {isEditing ? (
        <select
          name="country_name"
          value={profile.country_name}
          onChange={handleInputChange}
          className={`form-control mt-1 mb-1 ${isEditing ? 'editable-input' : ''}`}
        >
          <option value="">Select a country</option>
          {countries.map((country, index) => (
            <option key={index} value={country}>
              {country}
            </option>
          ))}
        </select>
      ) : (
        <p>{profile.country_name}</p>
      )}
            </div>

            <div className='single-address-data-layout'>
              <span>City</span>
              {isEditing ? (
                <input
                  type="text"
                  name="city"
                  value={profile.city}
                  onChange={handleInputChange}
                  className={`form-control mt-1 mb-1 ${isEditing ? 'editable-input' : ''}`}
                  placeholder='City'
                />
              ) : (
                <p>{profile.city}</p>
              )}
            </div>

            <div className='single-address-data-layout'>
              <span>Postal Code</span>
              {isEditing ? (
                <input
                  type="text"
                  name="zip"
                  value={profile.zip}
                  onChange={handleInputChange}
                  className={`form-control mt-1 mb-1 ${isEditing ? 'editable-input' : ''}`}
                  placeholder='Zip Code'
                />
              ) : (
                <p>{profile.zip}</p>
              )}
            </div>
            {/* <div className='single-address-data-layout'>
              <span>Tax ID</span>
              {isEditing ? (
                <input
                  type="text"
                  name="tax_id"
                  value={profile.tax_id}
                  onChange={handleInputChange}
                  className='form-control'
                />
              ) : (
                <p>{profile.tax_id}</p>
              )}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileContent;
