// import React from "react";
// import "../Style/Features.css";
// import img01 from "/webapps/tippytap/src/Assets/Group 89@2x 1.png";
// import bluetrue from "/webapps/tippytap/src/Assets/Group 1840 (2).png";
// import { useLocation } from 'react-router-dom';
// import { useEffect } from 'react';
// import { scroller } from 'react-scroll';

// export default function Features() {

//   const location = useLocation();

//   useEffect(() => {
//     if (location.state?.scrollToPricing) {
//       scroller.scrollTo('Pricing', {
//         smooth: true,
//         duration: 500,
//       });
//     }
//   }, [location]);
//   return (
//     <div>
//       <div className="flex-features container">
//         <div className="Left-img-features">
//           <img src={img01} />
//         </div>
//         <div className="Right-features-sec">
//           <h6>Key Features:</h6>
//           <p>
//             Make A Lasting Impression And Revolutionize Your Networking
//             Experience With Custom ‘’Tippytap’’ Business Cards.
//           </p>
//           <div className="card-true">
//             <img src={bluetrue} />
//             <h6>
//               Personalized Tippytap Business Card, With ‘’Tap&Go’’ Technology.
//             </h6>
//           </div>
//           <div className="card-true">
//             <img src={bluetrue} />
//             <h6>Instant Information/ Contacts Transfer.</h6>
//           </div>
//           <div className="card-true">
//             <img src={bluetrue} />
//             <h6>Tech-Savvy Impression: Impress Colleagues/ Students.</h6>
//           </div>
//           <div className="card-true">
//             <img src={bluetrue} />
//             <h6>
//               Attach Your Cv, Company Catalog, Uni. Brochure, Prequalifications.
//             </h6>
//           </div>
//           <div className="card-true">
//             <img src={bluetrue} />
//             <h6>Save Aed 100K+ On Reprints.</h6>
//           </div>
//           <div className="card-true">
//             <img src={bluetrue} />
//             <h6>Qr Code Can Be Added To The Card.</h6>
//           </div>
//           <div className="card-true" id="Pricing">
//             <img src={bluetrue} />
//             <h6>Buy Once, Use Forever.</h6>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
import React from "react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { scroller } from 'react-scroll';
import yourVideo from '/webapps/tippytap/src/Assets/WhatsApp Video 2024-08-27 at 12.37.41 PM.mp4'; // Adjust the path accordingly
import phoneImage from '/webapps/tippytap/src/Assets/—Pngtree—black mobile phone_9161004.png'; // Adjust path if needed
import partner1 from "/webapps/tippytap/src/Assets/Image 16@2x.png";
import "/webapps/tippytap/src/Style/Features.css";
import finger1 from "/webapps/tippytap/src/Assets/1@2x.png";
import finger2 from "/webapps/tippytap/src/Assets/2@2x.png";
import finger3 from "/webapps/tippytap/src/Assets/3@2x.png";
import finger4 from "/webapps/tippytap/src/Assets/4@2x.png";
import finger5 from "/webapps/tippytap/src/Assets/5@2x.png";
import { useNavigate } from 'react-router-dom';

export default function Features() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state?.scrollToPricing) {
      scroller.scrollTo('Pricing', {
        smooth: true,
        duration: 500,
      });
    }
  }, [location]);



  const Getstartedbtnhandle = () => {
    navigate('/Register')
  }

  return (



    <div className="Partner-Sec2 container" id="Partner">

      <div className="iphone-container">
        <img src={phoneImage} alt="iPhone Mockup" className="iphone-image" />
        <div className="video-container">
          <video controls autoPlay loop muted>
            <source src={yourVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <div className="right-container">
        <div className="text-key-features">
          <h6> <span>Key</span> Features :</h6>
          <p>Make A Lasting Impression And Revolutionize Your Networking Experience With <br /> Custom "TippyTap Business Cards" </p>
          <div className="Get-started-btn-container">
            <button onClick={Getstartedbtnhandle}>Get Started</button>
          </div>
        </div>
        <div className="swiper-container">
          <Swiper
            spaceBetween={3}
            slidesPerView={100}
            modules={[Pagination, Navigation, Autoplay, A11y]}
            pagination={{
              clickable: true,
              el: '.swiper-pagination', // Make sure this selector is correct if using a custom element
            }}
            navigation
            autoplay={{
              delay: 1500, // Delay between slides in milliseconds
              disableOnInteraction: false, // Continue autoplay after user interaction
              className: "swiper-wrapper"
            }}

            breakpoints={{
              // When the viewport width is >= 320px
              320: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              // When the viewport width is >= 480px
              480: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              // When the viewport width is >= 768px
              768: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              // When the viewport width is >= 1024px
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}



          >
            <SwiperSlide className="swiper-first-container">
              <div className="features-item-con">
                <img src={finger1} />
                <p>Reach More Customers</p>
              </div>


            </SwiperSlide>

            <SwiperSlide className="swiper-first-container" >
              <div className="features-item-con">
                <img src={finger2} />
                <p>Distribute Your Marketing Material</p>
              </div>
            </SwiperSlide >
            {/* <SwiperSlide>Slide 3</SwiperSlide> */}
            <SwiperSlide className="swiper-first-container">
              <div className="features-item-con">
                <img src={finger3} />
                <p>Sustainability In Mind</p>

              </div>


            </SwiperSlide>
            <SwiperSlide className="swiper-first-container" >
              <div className="features-item-con">
                <img src={finger4} />
                <p>Personalization And Customization</p>

              </div>


            </SwiperSlide>
            <SwiperSlide className="swiper-first-container">
              <div className="features-item-con" >
                <img src={finger5} />
                <p > Automation</p>

              </div>


            </SwiperSlide>
            {/* Add more SwiperSlides as needed */}
            <div className="swiper-button-prev" ></div>
            <div className="swiper-button-next"  ></div>
            <div className="swiper-button-next"  ></div>
            <div className="swiper-button-next" id='Pricing'  ></div>

          </Swiper>
        </div>
      </div>



      {/* <div className="container">
      <img src="path/to/your/image.jpg" alt="Left Image" className="image" />
      <div className="right-container">
        <div className="text">Top Right Text</div>
        <div className="swiper-container">
        <Swiper
        modules={[Pagination, A11y, Autoplay]}
        spaceBetween={50}
        slidesPerView={6}
        autoplay={{
          delay: 1500, // Delay between slides in milliseconds
          disableOnInteraction: false, // Continue autoplay after user interaction
        }}
        breakpoints={{
          // When the viewport width is >= 320px
          320: {
            slidesPerView: 2.5,
            spaceBetween: 10,
          },
          // When the viewport width is >= 480px
          480: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          // When the viewport width is >= 768px
          768: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
          // When the viewport width is >= 1024px
          1024: {
            slidesPerView: 6,
            spaceBetween: 50,
          },
        }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log("slide change")}
      >
            <SwiperSlide>Slide 1</SwiperSlide>
            <SwiperSlide>Slide 2</SwiperSlide>
            <SwiperSlide>Slide 3</SwiperSlide>
            {/* Add more SwiperSlides as needed */}
      {/* </Swiper> */}
      {/* </div> */}
      {/* </div> */}
      {/* </div>  */}
    </div>
  )
}