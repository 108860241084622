import React from 'react';

const MultiCardContent = () => (
  <div className='right-profile-content'>
    <h6>Multi Card</h6>
    {/* Add multi card content here */}
  </div>
);

export default MultiCardContent;
