import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Payment = () => {
  const { order_id } = useParams();
  const { session_id } = useParams();

  const navigate = useNavigate();
//   const [apiId, setApiId] = useState(null);

  
   useEffect(() => {

     axios.get(`https://apis.tippytap.ae/pay_order/${order_id}/${session_id}`)
     .then((res) => {       
      console.log(res)

    })
      .catch((err) => console.log(err));
  const timer = setTimeout(() => {
      navigate('/signin'); // Pass the ID to the landing page
    }, 1); // Redirect after 1 millisecond
   // Redirect after 3 seconds
      return () => clearTimeout(timer);
    }, []);


  return (
    <div>

    {/* <h6></h6>  */}
    
       </div>
  );
}

export default Payment;
